.MagicLinkTimer {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-right: 15px;
  span {
    font-weight: bold;
    color: #2563eb;
    margin-right: 8px;
  }
  .box-style {
    max-height: 27px;
    max-width: 30px;
    background: #1f1f1f;
    color: white;
    padding: 2px 6px;
    border-radius: 6px;
    font-weight: bold;
    font-size: 16px;
  }
  .timer-text-ml {
    display: flex;
    flex-direction: column;
    font-size: 9px;
  }
}
