@import "variable.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");

$font-size-14: 0.875rem;
$font-size-13: 0.8125rem;
$font-size-12: 0.75rem;
$font-size-11: 0.65rem;
// $font-family: 'Lato', sans-serif;
$font-family: "Source Sans Pro", sans-serif;
$font-family-2: "Montserrat", sans-serif;
$font-size-18: 1.125rem;
$font-size-20: 1.75rem;
$font-size-h1: 1.424rem;
$font-size-h2: 1.266rem;
$font-size-h3: 1.125rem;
$font-size-h4: 1rem;
$font-size-h5: 0.889rem;
$font-size-h6: 0.79rem;
$tbr_color: rgb(7, 64, 122);

@font-face {
  font-family: "Calibri";
  src: url(fonts/CalibriLight.ttf);
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: "Calibri";
  src: url(fonts/calibri.ttf);
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Calibri";
  src: url(fonts/CalibriBold.TTF);
  font-style: normal;
  font-weight: 600;
}

.font-Calibri {
  //font-family: "Calibri", "sans-serif";
  overflow: hidden;
  //padding-top: 30px;
  svg {
    //transform: translateY(-50px);
  }
}

body {
  font-family: $font-family;
  background-color: $clr_bodycolor;
  font-size: 14px;
  @media (min-width: 768px) {
    font-size: 14px;
  }
  @media (min-width: 1600px) {
    font-size: 16px;
  }
}

h1 {
  font-size: $font-size-h1;
  color: $clr_grey;
  font-family: $font-family-2;
}

h2 {
  font-size: $font-size-h2;
  color: $clr_grey;
  font-family: $font-family-2;
}

h3 {
  font-size: $font-size-h3;
  color: $clr_grey;
}

h4 {
  font-size: $font-size-h4;
  color: $clr_grey;
}

h5 {
  font-size: $font-size-h5;
  color: $clr_grey;
}

h6 {
  font-size: $font-size-h6;
  color: $clr_grey;
}

.font-family-source-sans {
  font-family: $font-family;
}

.btn-custom {
  padding: 0.3rem 0.75rem;
  height: 31px;
  line-height: 0.5;
  font-size: $font-size-h6;
}

.btn-primary {
  border-color: $clr_lightblue2;
  background: $clr_lightblue2;

  &:hover,
  &:active {
    background-color: $clr_darkblue5;
    border-color: $clr_darkblue5;
  }

  &:disabled,
  &.disabled {
    border-color: $clr_lightblue2;
    background: $clr_lightblue2;
  }

  &:disabled,
  &.disabled {
    opacity: 0.65;
  }

  &.btn-no-background {
    border-color: transparent;
    background: transparent;
    color: $clr_lightblue2;

    &:hover,
    &:active,
    &:not(:disabled):not(.disabled):active {
      background-color: transparent;
      border-color: transparent;
      color: $clr_lightblue2;
    }

    &:disabled,
    &.disabled {
      border-color: transparent;
      background: transparent;
    }
  }
}

.btn-success {
  &.btn-no-background {
    border-color: transparent;
    background: transparent;
    color: $clr_green3;

    &:hover,
    &:active,
    &:not(:disabled):not(.disabled):active {
      background-color: transparent;
      border-color: transparent;
      color: $clr_green3;
    }

    &:disabled,
    &.disabled {
      border-color: transparent;
      background: transparent;
    }
  }
}

.btn-danger {
  background: $clr_red3;
  border-color: $clr_red3;

  &:hover {
    background: $clr_red4;
    border-color: $clr_red4;
  }

  &:focus,
  &:not(:disabled):not(.disabled):active {
    background: $clr_red4;
    border-color: $clr_red4;
    box-shadow: 0 0 0 0.2rem rgba(255, 49, 43, 0.5);
  }

  &.btn-no-background {
    border-color: transparent;
    background: transparent;
    color: $clr_red5;

    &:hover,
    &:active,
    &:not(:disabled):not(.disabled):active {
      background-color: transparent;
      border-color: transparent;
      color: $clr_red5;
    }

    &:disabled,
    &.disabled {
      border-color: transparent;
      background: transparent;
    }
  }
}

.btn {
  &.btn-no-background {
    font-size: 14px;
  }
}

.icon-text-btn.title-btn.btn-no-background {
  font-size: 14px;
}

.btn-outline-primary,
.btn-secondary.btn-outline-primary {
  border-color: $clr_lightblue2;
  background-color: $clr_white;
  color: $clr_lightblue2;

  &:hover,
  &:active {
    color: $clr_white;
    background-color: $clr_lightblue2;
    border-color: $clr_lightblue2;
  }
}
.saml-configuration-btn-no-hover {
  &:hover,
  &:active {
    background-color: $clr_white !important;
    color: $clr_lightblue2 !important;
    border-color: $clr_lightblue2 !important;
  }
}

.dark-blue-btn {
  border-color: $clr_darkblue5;
  background: $clr_darkblue5;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
  cursor: no-drop;
}

.dropdown-btn {
  height: 33px;
  border: 1px solid $clr_lightblue5;
  background-color: $clr_alice_blue2;
  border-radius: 5px;
  color: $clr_lightblue2;
  font-size: 14px;

  .arrow-drown-down {
    color: $clr_lightblue2;
  }

  &:hover {
    color: $clr_lightblue2;

    .arrow-drown-down {
      color: $clr_lightblue2;
    }
  }

  &:focus,
  &.focus {
    box-shadow: none;
  }
}

.text-primary {
  color: $clr_lightblue !important;
}

p {
  font-size: $font-size-12;
  color: $clr_grey;
  text-decoration: none;
}

a {
  font-size: $font-size-12;
  color: $clr_grey;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: $clr_darkblue;
  }
}

span {
  th {
    font-size: $font-size-12;
    color: $clr_grey;
    text-decoration: none;
  }
}

td {
  font-size: $font-size-12;
  color: $clr_black2;
  text-decoration: none;
}

.red {
  color: red;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.w80 {
  width: 80px;
}

.w-sm-auto {
  @media (min-width: 576px) {
    width: auto !important;
  }
}

.w-md-50 {
  @media (min-width: 768px) {
    width: 50%;
  }
}

.font-weight-semibold {
  font-weight: 600;
}

// input.form-control {
//   width: 100%;
//   height: 35px;
//   border: 1px solid #ced4da;
//   line-height: 35px;
// }
textarea {
  border: 1px solid #ced4da;
  border-radius: 2px;
  outline: none;
  padding: 0.375rem 0.75rem;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #dc3545 !important;
}

/* The emerging W3C standard
   that is currently Firefox-only */
* {
  scrollbar-width: thin;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

// *::-webkit-scrollbar-track {
//   // background: orange;
// }
*::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 20px;
  border: 1px solid #ccc;
}

.text-ligntblue2 {
  color: $clr_lightblue2 !important;
}

.text-black2 {
  color: $clr_black2 !important;
}

.text-green {
  color: $clr_green2 !important;
}

.text-grey {
  color: $clr_lightgrey2 !important;
}

.text-ligntgrey {
  color: $clr_lightgrey7 !important;
}

.text-red {
  color: $clr_red !important;
}

.text-red-clr {
  color: $clr_error !important;
}

.text-orange {
  color: $clr_orange !important;
}

.text-blue {
  color: $clr_blue !important;
}

.text-blue-clr {
  color: $clr_lightblue4;
}

.text-darkblue {
  color: $clr_darkblue4 !important;
}

.text-darkblue2 {
  color: $clr_darkblue6 !important;
}

.font-weight-semibold {
  font-weight: 600;
}

.white-space-nowrap {
  white-space: nowrap;
}

.mb-10px {
  margin-bottom: 10px;
}

.mb-16px {
  margin-bottom: 16px;
}

.mb-18px {
  margin-bottom: 18px;
}

.mb-20px {
  margin-bottom: 20px;
}

.mb-2px {
  margin-bottom: 2px;
}

.mb-6px {
  margin-bottom: 6px;
}

.ml-10px {
  margin-left: 10px;
}

.ml-2px {
  margin-left: 2px;
}

.mt-2px {
  margin-top: 2px !important;
}

.mr-2px {
  margin-right: 2px !important;
}

.mr-5px {
  margin-right: 5px !important;
}

.mt-3px {
  margin-top: 3px !important;
}

.mt-n-2px {
  margin-top: -2px !important;
}

.mt-11px {
  margin-top: 11px !important;
}

.mr-6px {
  margin-right: 6px !important;
}

.mr-7px {
  margin-right: 7px !important;
}

.fs-12 {
  font-size: 12px;
}

@for $i from 10 to 30 {
  .icon-fs-#{$i} {
    font-size: #{$i}px !important;
  }
}

.border {
  border: 1px solid $clr_border !important;
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-45 {
  transform: rotate(45deg);
}

.btn-md {
  font-size: $font-size-h6;
  padding: 6.5px 12px;
}

.no-drop {
  cursor: no-drop !important;
}

.custom_disabled {
  opacity: 0.5;
  pointer-events: none;
}

.modal-btn.btn-md {
  max-height: 30px;
  min-height: 30px;
  line-height: 30px;
  min-width: 70px;
}

.custom-checkbox-new {
  -webkit-appearance: none;
  min-width: 14px;
  width: 14px;
  height: 14px;
  border: 1px solid $clr_border;
  vertical-align: sub;
  outline: none;
  border-radius: 3px;
  position: relative;
  cursor: pointer;

  &:checked {
    background-color: $clr_blue;
    border-color: $clr_blue;

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmZmZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPmljb25fYnlfUG9zaGx5YWtvdjEwPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjZmZmZmZmIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNi4wMDAwMDAsIDI2LjAwMDAwMCkiPjxwYXRoIGQ9Ik0xNy45OTk5ODc4LDMyLjQgTDEwLjk5OTk4NzgsMjUuNCBDMTAuMjI2Nzg5MSwyNC42MjY4MDE0IDguOTczMTg2NDQsMjQuNjI2ODAxNCA4LjE5OTk4Nzc5LDI1LjQgTDguMTk5OTg3NzksMjUuNCBDNy40MjY3ODkxNCwyNi4xNzMxOTg2IDcuNDI2Nzg5MTQsMjcuNDI2ODAxNCA4LjE5OTk4Nzc5LDI4LjIgTDE2LjU4NTc3NDIsMzYuNTg1Nzg2NCBDMTcuMzY2ODIyOCwzNy4zNjY4MzUgMTguNjMzMTUyOCwzNy4zNjY4MzUgMTkuNDE0MjAxNCwzNi41ODU3ODY0IEw0MC41OTk5ODc4LDE1LjQgQzQxLjM3MzE4NjQsMTQuNjI2ODAxNCA0MS4zNzMxODY0LDEzLjM3MzE5ODYgNDAuNTk5OTg3OCwxMi42IEw0MC41OTk5ODc4LDEyLjYgQzM5LjgyNjc4OTEsMTEuODI2ODAxNCAzOC41NzMxODY0LDExLjgyNjgwMTQgMzcuNzk5OTg3OCwxMi42IEwxNy45OTk5ODc4LDMyLjQgWiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==);
      background-size: 28px;
      background-repeat: no-repeat;
      background-position: center;
    }

    ~ .form-check-label {
      color: $clr_blue;
    }
  }

  &:disabled,
  &[disabled],
  &:disabled ~ .form-check-label,
  &[disabled] ~ .form-check-label {
    opacity: 0.5;
    cursor: default !important;
  }
}

.view-custom-checkbox-new {
  -webkit-appearance: none;
  min-width: 14px;
  width: 14px;
  height: 14px;
  border: 1px solid $clr_border;
  vertical-align: sub;
  outline: none;
  border-radius: 3px;
  position: relative;
  cursor: pointer;

  &:checked {
    background-color: #b7ffe0;
    border-color: transparent;

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmZmZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPmljb25fYnlfUG9zaGx5YWtvdjEwPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjZmZmZmZmIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNi4wMDAwMDAsIDI2LjAwMDAwMCkiPjxwYXRoIGQ9Ik0xNy45OTk5ODc4LDMyLjQgTDEwLjk5OTk4NzgsMjUuNCBDMTAuMjI2Nzg5MSwyNC42MjY4MDE0IDguOTczMTg2NDQsMjQuNjI2ODAxNCA4LjE5OTk4Nzc5LDI1LjQgTDguMTk5OTg3NzksMjUuNCBDNy40MjY3ODkxNCwyNi4xNzMxOTg2IDcuNDI2Nzg5MTQsMjcuNDI2ODAxNCA4LjE5OTk4Nzc5LDI4LjIgTDE2LjU4NTc3NDIsMzYuNTg1Nzg2NCBDMTcuMzY2ODIyOCwzNy4zNjY4MzUgMTguNjMzMTUyOCwzNy4zNjY4MzUgMTkuNDE0MjAxNCwzNi41ODU3ODY0IEw0MC41OTk5ODc4LDE1LjQgQzQxLjM3MzE4NjQsMTQuNjI2ODAxNCA0MS4zNzMxODY0LDEzLjM3MzE5ODYgNDAuNTk5OTg3OCwxMi42IEw0MC41OTk5ODc4LDEyLjYgQzM5LjgyNjc4OTEsMTEuODI2ODAxNCAzOC41NzMxODY0LDExLjgyNjgwMTQgMzcuNzk5OTg3OCwxMi42IEwxNy45OTk5ODc4LDMyLjQgWiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==);
      background-size: 28px;
      background-repeat: no-repeat;
      background-position: center;
    }

    ~ .form-check-label {
      color: $clr_blue;
    }
  }

  &:disabled,
  &[disabled],
  &:disabled ~ .form-check-label,
  &[disabled] ~ .form-check-label {
    opacity: 0.5;
    cursor: default !important;
  }
}

.row {
  &.gutter-8 {
    margin-left: -8px;
    margin-right: -8px;

    > .col-1,
    > .col-2,
    > .col-3,
    > .col-4,
    > .col-5,
    > .col-6,
    > .col-7,
    > .col-8,
    > .col-9,
    > .col-10,
    > .col-11,
    > .col-12,
    > .col,
    > .col-auto,
    > .col-sm-1,
    > .col-sm-2,
    > .col-sm-3,
    > .col-sm-4,
    > .col-sm-5,
    > .col-sm-6,
    > .col-sm-7,
    > .col-sm-8,
    > .col-sm-9,
    > .col-sm-10,
    > .col-sm-11,
    > .col-sm-12,
    > .col-sm,
    > .col-sm-auto,
    > .col-md-1,
    > .col-md-2,
    > .col-md-3,
    > .col-md-4,
    > .col-md-5,
    > .col-md-6,
    > .col-md-7,
    > .col-md-8,
    > .col-md-9,
    > .col-md-10,
    > .col-md-11,
    > .col-md-12,
    > .col-md,
    > .col-md-auto,
    > .col-lg-1,
    > .col-lg-2,
    > .col-lg-3,
    > .col-lg-4,
    > .col-lg-5,
    > .col-lg-6,
    > .col-lg-7,
    > .col-lg-8,
    > .col-lg-9,
    > .col-lg-10,
    > .col-lg-11,
    > .col-lg-12,
    > .col-lg,
    > .col-lg-auto,
    > .col-xl-1,
    > .col-xl-2,
    > .col-xl-3,
    > .col-xl-4,
    > .col-xl-5,
    > .col-xl-6,
    > .col-xl-7,
    > .col-xl-8,
    > .col-xl-9,
    > .col-xl-10,
    > .col-xl-11,
    > .col-xl-12,
    > .col-xl,
    > .col-xl-auto {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}

.responsive-table {
  overflow-x: auto;
}

.gradient-blue-button {
  background: transparent linear-gradient(90deg, #0b2540 0%, #0154aa 100%) 0% 0%
    no-repeat padding-box;
  // box-shadow: 0px 0px 12px #0b254014;
  border: 1px solid $clr_lightgrey_new;
  border-radius: 5px;
  font-size: $font-size-h5;
  color: $clr_white;
  padding: 0.375rem 1rem;

  &:hover {
    color: $clr_white;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.box-shadow-none {
  box-shadow: none !important;
}

table {
  &.normal-table {
    // box-shadow: 0px 0px 12px #0b254024;
    border: 1px solid $clr_lightgrey_new;
    border-radius: 3.5px;

    thead tr {
      th {
        background-color: $clr_lightblue3;
        color: $clr_white;
        padding: 12px;
        font-size: $font-size-h5;
      }

      &:last-child {
        th {
          &:first-child {
            border-top-left-radius: 3.5px;
            padding-left: 24px;
          }

          &:last-child {
            border-top-right-radius: 3.5px;
            padding-right: 24px;
          }
        }
      }
    }

    .table-icon-text {
      .material-icon {
        font-size: 19px;
      }
    }

    tbody tr {
      &:not(:last-child) {
        border-bottom: 1px solid $clr_border;
      }

      td {
        background-color: $clr_white;
        color: $clr_black2;
        padding: 9px;
        font-size: 13px;

        .nav-link {
          font-size: 14px;
        }

        &:first-child {
          padding-left: 24px;
        }

        &:last-child {
          padding-right: 24px;
        }
      }

      &:last-child {
        td {
          &:first-child {
            border-bottom-left-radius: 3.5px;
          }

          &:last-child {
            border-bottom-right-radius: 3.5px;
          }
        }
      }
    }
  }

  &.normal-table-2 {
    box-shadow: none;

    thead tr {
      th {
        color: $clr_white;
        padding: 7px;
        font-size: $font-size-h6;
        font-weight: bold;
      }

      &:first-child {
        background-color: $clr_lightblue3;
        position: sticky;
        top: 0;
        z-index: 1;
      }

      &:last-child {
        th {
          &:first-child {
            border-bottom-left-radius: 3.5px;
            padding-left: 9px;
          }

          &:last-child {
            border-bottom-right-radius: 3.5px;
            padding-right: 9px;
          }
        }
      }
    }

    tbody tr {
      &:not(:last-child) {
        border-bottom: 1px solid $clr_border;
      }

      td {
        background-color: $clr_white;
        color: $clr_black2;
        padding: 9px;
        font-size: $font-size-h6;

        &:first-child {
          padding-left: 9px;
        }

        &:last-child {
          padding-right: 9px;
        }
      }

      &:last-child {
        td {
          &:first-child {
            border-bottom-left-radius: 3.5px;
          }

          &:last-child {
            border-bottom-right-radius: 3.5px;
          }
        }
      }
    }
  }

  &.login-details-table {
    // margin-top: 8px;
    tbody tr {
      td:first-child {
        white-space: nowrap;
      }

      td {
        .date {
          color: $clr_lightgrey;
        }

        .time {
          color: $clr_black2;
        }
      }
    }
  }
}

.logintablescroll {
  overflow: auto;

  .infinite-scroll-component {
    max-height: 370px;
    overflow: unset !important;
  }
}

.tbr-client-list {
  .card {
    &.custom-card,
    &.services-card {
      margin-bottom: 18px;
    }
  }
}

.card {
  background: #ffffff 0% 0% no-repeat padding-box;
  // box-shadow: 0px 0px 12px #0b254024;
  border: 1px solid $clr_lightgrey_new;
  border-radius: 3.5px;

  &.custom-card {
    .card-body {
      padding: 12px;
    }
  }

  &.services-card {
    .card-body {
      padding: 10px;
    }

    .custom-collapser-wrapper:last-child {
      .list-custom-collapser {
        margin-bottom: 0px !important;
      }

      .collapse-panel[aria-expanded="true"] .collapse-panel-content {
        margin-bottom: 6px;
      }
    }
  }

  &.login-details-card {
    .stat {
      font-size: 22px;
    }
  }
}

.btn-items-card-wrapper {
  .btn-items-card {
    &.closed-card {
      max-height: 61px;
      overflow: hidden;

      .show-more-btn {
        .close-icon {
          display: none;
        }

        .btn-link {
          display: inline-flex;
        }
      }
    }

    &.opened-card {
      .show-more-btn {
        .close-icon {
          display: inline-flex;
        }

        .btn-link {
          display: none;
        }
      }
    }

    &.custom-card {
      .card-body {
        padding: 6px 16px;
      }
    }

    .show-more-btn {
      .btn-link {
        // margin-top: 4px;
        white-space: nowrap;
        text-decoration: underline;
        color: $clr_lightblue2;
        padding-right: 0px;
        font-size: 14px;
        margin-top: 3px;
        padding-left: 10px;
      }

      .close-icon {
        margin-top: 8px;
        margin-left: 20px;
        color: $clr_lightblue2;
        cursor: pointer;
      }
    }

    .title {
      padding: 12px 28px 16px 0px;
      margin-right: 20px;
      border-right: 1px solid $clr_border;
      align-self: flex-start;
    }

    .btn-items {
      margin-left: -6.5px;
      margin-right: -6.5px;

      li {
        padding-left: 6.5px;
        padding-right: 6.5px;

        .content {
          padding: 7px 15px;
          border-radius: 3px;
          background-color: $clr_lightgrey3;
          color: $clr_lightgrey2;
          font-size: 14px;
          cursor: pointer;
          margin-top: 7px;
          margin-bottom: 7px;

          &:hover {
            color: $clr_green3;
            background-color: $clr_lightgreen;
          }

          .icon {
            font-size: 18px;
            margin-bottom: 1px;
          }
        }

        &.active {
          .content {
            color: $clr_green3;
            background-color: $clr_lightgreen;
          }
        }
      }
    }

    &.nav-tabs-card {
      .title {
        padding: 12px 26px 12px 0px;
        margin-right: 22px;
      }

      .custom-nav-tabs {
        .nav-item {
          margin-top: 6px;
          margin-bottom: 6px;
        }
      }

      .reports-nav-tabs {
        border: 0;

        .nav-item .nav-link {
          border-color: #dee2e6;
          border-radius: 0.25rem;
          margin-right: 8px;
          min-width: 92px;
          text-align: center;
          opacity: 0.8;

          &:hover {
            opacity: 1;
            border-color: $clr_lightblue3;
          }
        }

        .nav-item .active {
          opacity: 1;
          font-weight: 600;
          color: $clr_white;
          background-color: $clr_lightblue3;
          border-color: $clr_lightblue3;

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}

.tabs-card-wrapper {
  margin-bottom: 16px;
}

.segments-card-wrapper {
  // padding-top: 14px;
  // position: sticky;
  top: 109px;
  z-index: 105;
  background-color: $clr_bodycolor;

  .btn-items-card {
    .btn-items {
      li .content {
        white-space: nowrap;
      }
    }
  }
}

.list-custom-collapser {
  .collapse {
    border: 0;
    font-size: $font-size-h6;

    .collapse-trigger {
      background-color: $clr_solitude;
      border-radius: 3px;
      padding: 2px 0px 2px 12px;
      border: 0;

      h5 {
        color: $clr_black2;
      }

      .material-icon {
        color: $clr_blue;
        font-size: 31px;
        transition: all 0.3s cubic-bezier(0.215, 0.61, 0.35);
      }

      &[aria-expanded="true"] {
        .material-icon {
          transform: rotate(180deg);
        }
      }
    }

    .collapse-trigger div:nth-child(1)::before {
      content: none;
    }

    .collapse-panel {
      border: 0;
      padding: 0;
    }

    .collapse-panel-content {
      .item {
        padding: 8px 6px 8px 12px;
        border-bottom: 1px solid $clr_border;

        .name {
          color: $clr_lightblue2;
        }

        .count {
          color: $clr_lightgrey;
        }
      }
    }
  }
}

.custom-collapser {
  .collapse {
    border: 0;
    font-size: $font-size-h6;
  }

  .collapse-trigger {
    &::before {
      content: none;
    }

    .title {
      text-transform: capitalize;
    }

    background-color: $clr_solitude;
    border-radius: 3px;
    padding: 2px 0px 2px 8px;
    border: 0;

    .title {
      color: $clr_black2;
    }

    .material-icon {
      color: $clr_blue;
      font-size: 31px;
    }

    &[aria-expanded="true"] {
      .material-icon.rotate180 {
        transform: rotate(180deg);
      }
    }
  }

  .collapse-trigger div:nth-child(1)::before {
    content: none;
  }

  .collapse-panel {
    border: 0;
    padding: 0;
  }

  &.inner-sidebar-collapser {
    .Collapsible {
      position: relative;
    }

    .custom-collapser-item {
      .trigger-block {
        padding: 10px 6px 10px 8px;
        border-radius: 3px;
        border: 0;
      }

      .Collapsible__trigger {
        // position: absolute;
        // right: 6px;
        // top: 8px;
        // cursor: pointer;
        .material-icon {
          color: $clr_lightblue2;
          font-size: 21px;
          transition: all 0.3s cubic-bezier(0.215, 0.61, 0.35);
        }

        &.is-open {
          .material-icon {
            transform: rotate(90deg);
          }

          + .trigger-block {
            background-color: $clr_alice_blue2;
          }
        }
      }
    }

    .collapse-trigger {
      padding: 8px 6px 8px 8px;

      .material-icon {
        color: $clr_lightblue2;
        font-size: 21px;
        transition: all 0.3s cubic-bezier(0.215, 0.61, 0.35);
      }

      &[aria-expanded="true"] {
        .material-icon {
          transform: rotate(90deg);
        }
      }

      &::before {
        content: none;
      }
    }

    .trigger-block {
      .title {
        margin-left: 10px;
        color: $clr_lightgrey2;
        margin-right: 20px;
        margin-left: 26px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
      }

      input:checked {
        + .title {
          color: $clr_blue;
        }
      }
    }

    .collapse-panel {
      &:not(:last-child) {
        .collapse-panel-content {
          .item {
            border-bottom: 0.5px solid $clr_tableborder;
          }
        }
      }

      .collapse-panel-content {
        .item {
          margin: 0px 7px;
          padding: 10px;

          label {
            margin-left: 11px;
            color: $clr_lightgrey2;
            font-size: 13px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            // margin-right: -12px;
          }

          input:checked {
            + label {
              color: $clr_green3;
              cursor: pointer;
            }
          }
        }
      }
    }

    .custom-collapser-item {
      .trigger-block {
        padding: 10px 6px 10px 8px;
        border-radius: 3px;
        border: 0;
      }

      .Collapsible__trigger {
        .collapse-icon {
          position: absolute;
          top: 8px;
          left: 8px;
          right: auto;
          z-index: 2;
          width: 21px;
          cursor: pointer;
        }

        .material-icon {
          color: $clr_lightblue2;
          font-size: 21px;
          transition: all 0.3s cubic-bezier(0.215, 0.61, 0.35);
        }

        &.is-open {
          .material-icon {
            transform: rotate(90deg);
          }

          + .trigger-block {
            background-color: $clr_alice_blue2;
          }
        }
      }
    }

    .custom-collapser-2-item {
      .Collapsible__trigger {
        .collapse-icon {
          left: 8px;
          right: auto;
          z-index: 2;
          width: 21px;
        }

        ~ .add-remove-item {
          input:checked + .title {
            color: $clr_green3;
          }
        }

        &.is-open {
          ~ .add-remove-item {
            .title {
              color: $clr_blue;
            }
          }
        }
      }

      .collapse-panel {
        .collapse-panel-content {
          .item {
            padding: 8px 0px;

            label {
              padding-right: 26px;
              margin-left: 4px;

              &.add-remove-label {
                padding-right: 4px;
              }
            }

            input:checked + label {
              color: $clr_green3;
            }

            .blue-icon {
              color: $clr_lightblue;
            }

            .red-icon {
              color: $clr_red4;
            }

            &.selected {
              label {
                color: $clr_green3;
              }
            }
          }

          .add-remove-item {
            // margin-left: 38px;
            label {
              position: relative;
            }
          }
        }
      }

      input[type="checkbox"] {
        display: none;
      }

      .add-remove-item {
        position: relative;

        label {
          position: relative;
          z-index: 2;
          cursor: pointer;
          margin-left: 26px;
          padding-right: 26px;
          margin-right: 0px;
          width: 100%;
        }

        .add-remove-icon {
          &:after {
            display: block;
            position: absolute;
            top: 7px;
            // -ms-transform: translateY(-50%);
            // transform: translateY(-50%);
            right: 5px;
            opacity: 1;
            transition: height 0.5s ease-in-out;
            font-size: 18px;
            font-family: "Material Icons Outlined";
            cursor: pointer;
          }
        }

        input[type="checkbox"]:not(:checked) ~ .add-remove-icon:after {
          content: "\e145";
          color: $clr_blue;
        }

        input[type="checkbox"]:checked ~ .add-remove-icon:after {
          content: "\e14c";
          color: $clr_red4;
        }

        &.trigger-block {
          padding: 8px 6px 8px 8px;

          .add-remove-icon {
            &:after {
              right: 14px;
            }
          }
        }

        &.add-remove-edit-item.trigger-block {
          .title {
            padding-right: 2px;
          }

          .remove-icon {
            color: $clr_red4;
          }
        }
      }

      .Collapsible__contentInner {
        .add-remove-item {
          input[type="checkbox"]:not(:checked) ~ .add-remove-icon:after {
            content: "\e03b";
          }

          input[type="checkbox"]:checked ~ .add-remove-icon:after {
            content: "\eb80";
          }
        }
      }
    }

    &.with-no-checkbox {
      .custom-collapser-item {
        input[type="checkbox"] {
          display: none;
        }
      }

      .trigger-block {
        .title {
          color: $clr_blue;
          // margin-left: 4px;
        }
      }

      .collapse-panel {
        &:not(:last-child) {
          .collapse-panel-content {
            .item {
              border-bottom: 0.5px solid $clr_tableborder;
            }
          }
        }

        .collapse-panel-content {
          .item {
            margin: 0px 7px 0px 11px;
            padding: 8px 0px;

            label {
              margin-left: 0px;
              color: $clr_blue;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.custom-collapser2 {
  .collapse,
  .Collapsible {
    // box-shadow: 0px 0px 12px #0b254024;
    border: 1px solid $clr_lightgrey_new;
  }

  .Collapsible__trigger {
    &.is-open {
      .collapse-icon-wrapper .add {
        display: none;
      }

      .collapse-icon-wrapper .remove {
        display: flex;
      }
    }

    &.is-closed {
      .collapse-icon-wrapper .add {
        display: flex;
      }

      .collapse-icon-wrapper .remove {
        display: none;
      }
    }
  }

  .collapse-trigger {
    background-color: $clr_white;
    padding: 16px;

    .title {
      font-family: $font-family;
    }

    .collapse-icon-wrapper {
      padding: 3px;
      border-radius: 50%;
      background-color: $clr_lightgrey4;
      color: $clr_lightgrey2;

      .icon {
        font-size: 20px;
      }
    }

    &[aria-expanded="true"] {
      .collapse-icon-wrapper .add {
        display: none;
      }

      .collapse-icon-wrapper .remove {
        display: flex;
      }
    }

    &[aria-expanded="false"] {
      .collapse-icon-wrapper .add {
        display: flex;
      }

      .collapse-icon-wrapper .remove {
        display: none;
      }
    }
  }

  .collapse-panel {
    padding: 0px 16px;

    &[aria-expanded="true"] {
      padding: 0px 16px 16px;
    }
  }
}

.custom-collapser2-2 {
  .collapse {
    box-shadow: none;
    // border-top: 1px solid $clr_lightgrey8;
    border-bottom: 1px solid $clr_lightgrey8;
    // margin-top: -1px;
  }

  .collapse-trigger {
    // padding: 20px 0px;
    .collapse-icon-wrapper {
      background-color: transparent;
      border: 1px solid $clr_lightgrey2;
      padding: 2px;

      .icon {
        color: $clr_lightgrey2;
        font-size: 18px;
      }
    }
  }

  .collapse-panel {
    padding: 0px;
    font-size: 14px;
    color: $clr_black2;
  }

  // .collapse-panel[aria-expanded="true"] {
  //   padding: 0px 0px 25px 0px;
  // }
}

.plus-minus-collapser {
  .collapse {
    border: 1px solid $clr_solitude;
  }

  .collapse-trigger {
    padding: 5px 12px;
    border-radius: 3.5px;

    .material-icon {
      font-size: 16px;
      display: inline-flex;
      background-color: $clr_darkblue5;
      color: $clr_white;
      padding: 2px;
      border-radius: 50%;

      &::before {
        font: 16px "Material Icons Round";
        background-color: $clr_darkblue5;
        color: $clr_white;
        border-radius: 50%;
      }
    }

    &[aria-expanded="true"] {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      .material-icon {
        &::before {
          content: "\e15b";
        }
      }
    }

    &[aria-expanded="false"] {
      .material-icon {
        &::before {
          content: "\e145";
        }
      }
    }
  }

  .collapse-panel {
    padding-left: 12px;
    padding-right: 12px;

    &[aria-expanded="true"] {
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom-left-radius: 3.5px;
      border-bottom-right-radius: 3.5px;
    }
  }
}

.details-page-collapser {
  &.custom-collapser2 {
    .Collapsible {
      // box-shadow: 0 0 25px rgba(11, 37, 64, 0.14);
      border: 1px solid $clr_lightgrey_new;

      .Collapsible__trigger {
        &.is-open {
          .collapse-trigger {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
          }
        }
      }

      .Collapsible__contentOuter {
        .Collapsible__contentInner {
          padding: 16px;
          background-color: #fff;
          // border-radius: 3px;
          border-bottom-left-radius: 3px;
          border-bottom-right-radius: 3px;
        }
      }

      .Collapsible__trigger {
        &.is-open {
          .collapse-trigger {
            padding-bottom: 16px;
          }
        }
      }
    }

    // .collapse-trigger {
    //   position: sticky;
    //   top: 198px;
    //   z-index: 103;
    // }
  }

  .collapse-trigger .title {
    margin-right: 20px;
  }

  &:nth-child(even) {
    .collapse-trigger .title {
      background-color: $clr_lightgrey6;
      color: $clr_cornflower_blue;
    }
  }

  &:nth-child(odd) {
    .collapse-trigger .title {
      background-color: $clr_lilywhite;
      color: $clr_lightgreen2;
    }
  }

  .collapse-trigger {
    .title {
      padding: 5px 16px;
      border-radius: 4px;
    }

    .icon-wrapper {
      border-radius: 9px;
      padding: 5px;

      .icon {
        font-size: 22px;

        &::before {
          font: 22px "Material Icons";
        }
      }
    }
  }

  .sub-category-content {
    padding: 0px 10px;

    .text-block {
      img {
        margin-bottom: 12px;
      }
    }
  }

  .companyDetailsTable {
    width: 100%;

    th {
      color: $clr_black2;
      font-weight: bold;
    }
  }
}

.no-segments-card-wrapper {
  .details-page-collapser {
    &.custom-collapser2 {
      &:first-child {
        padding-top: 15px;
      }

      .collapse-trigger {
        top: 124px;

        &::before {
          content: "";
          height: 15px;
          display: block;
          position: absolute;
          top: -15px;
          left: 0;
          transform: none !important;
          width: 100%;
          border: 0;
          z-index: 103;
          background-color: #f1f4f8;
        }
      }
    }
  }
}

.category-wrapper {
  margin-bottom: 15px;
  border-top: none;
}

.custom-nav-tabs {
  &.nav-tabs {
    border-bottom: 0;

    .nav-link {
      margin-bottom: 0;
      border: 0;
      font-weight: 600;
      color: $clr_lightgrey2;
      background-color: $clr_lightgrey3;
      border-radius: 3px;
      padding: 7px 20px;
      font-size: 14px;
    }

    .nav-item {
      margin-bottom: 0px;
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 11px;
      }

      a {
        &:hover {
          color: $clr_lightgrey2;
        }
      }
    }

    .nav-link.active,
    .nav-item.show .nav-link {
      background-color: $clr_lightgreen;
      color: $clr_green3;

      &:hover {
        color: $clr_green3;
      }
    }
  }
}

.responsive-table {
  overflow-x: auto;
}

.custom-table-block {
  .responsive-table {
    // box-shadow: 0px 0px 12px #0b254024;
    border-radius: 3.5px;
    width: 100%;
  }

  .btn-md {
    padding: 0.3rem 0.75rem;
  }

  .scroll-table-wrapper {
    // max-height: 620px;
    max-height: 450px;
    overflow: auto;
    // overflow-y: hidden;
  }

  // tbody {
  //   display: block;
  //   max-height: 620px;
  //   overflow-y: auto;
  // }

  // thead,
  // tbody tr {
  //   display: table;
  //   width: 100%;
  //   table-layout: fixed;
  // }

  &.custom-table-block-2 {
    table {
      td {
        font-size: 14px;
      }
    }
  }

  .table-md {
    thead th {
      font-size: 13px;
      border-bottom-width: 0px;
      padding: 0.55rem;
    }

    tbody td {
      padding: 0.55rem;
      font-size: 13px;
      vertical-align: middle;
    }
  }

  table {
    // box-shadow: 0px 0px 12px #0b254024;
    border-radius: 3.5px;
    color: $clr-black;
    background-color: $clr_white;
    width: 100%;

    tr {
      th:first-child,
      td:first-child {
        padding-left: 20px;
      }

      th:last-child,
      td:last-child {
        padding-right: 20px;
      }

      &:first-child {
        th {
          vertical-align: top;
          background-color: $clr_lightblue3;
          position: sticky;
          top: 0;
          z-index: 2;

          &:first-child {
            border-top-left-radius: 3.5px;
          }

          &:last-child {
            border-top-right-radius: 3.5px;
          }
        }
      }

      th {
        background-color: $clr_lightblue3;
        color: $clr_white;
        font-weight: normal;
        font-size: 14.22px;
        padding: 3px 10px;
      }

      td {
        font-size: 13px;
        padding: 0 10px;
        border-bottom: 1px solid $clr_lightgrey_new;
        height: 40px;
        color: $clr_black;
        background-color: $clr_white;

        .link,
        .nav-link,
        .nav-link label,
        .link label {
          color: $clr_lightblue2;
          cursor: pointer;
          font-size: 14px;
        }
      }

      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }

  &.nowrap-table-block {
    table tr td {
      white-space: nowrap;
    }
  }

  &.th-nowrap {
    table tr th {
      white-space: nowrap;
    }
  }

  &.sort-table-block {
    &.center-th-align {
      .sort-table-th {
        align-items: center;

        .sort-icon {
          margin-top: 0px;
        }
      }
    }

    th.text-center .th-content {
      justify-content: center;
      text-align: left;
    }

    table tr th {
      .sort-icon {
        margin-top: 4px;
      }

      .th-content {
        display: flex;
        align-items: center;
        line-height: 40px;
      }

      &.default,
      &.asc,
      &.desc {
        cursor: pointer;
      }

      // &.default .th-content:after {
      //   font: 12px FontAwesome;
      //   content: "\f0dc";
      //   margin-top: 5px;
      //   margin-left: 5px;
      // }
      // &.asc .th-content:after {
      //   font: 12px FontAwesome;
      //   content: "\f0de";
      //   vertical-align: bottom;
      //   margin-top: 5px;
      //   margin-left: 5px;
      // }
      // &.desc .th-content:after {
      //   font: 12px FontAwesome;
      //   content: "\f0dd";
      //   vertical-align: top;
      //   margin-top: 5px;
      //   margin-left: 5px;
      // }
      &.default .th-content:after,
      &.asc .th-content:after,
      &.desc .th-content:after {
        font: 18px "Material Icons";
        margin-top: 5px;
        margin-left: 5px;
        line-height: 7px;
      }

      &.default {
        .th-content:after {
          content: "\e5c7\A\e5c5";
          line-height: 7px;
          white-space: pre;
        }
      }

      &.desc {
        .th-content:after {
          content: "\e5c5";
          line-height: 16px;
          vertical-align: bottom;
        }
      }

      &.asc {
        .th-content:after {
          content: "\e5c7";
        }
      }
    }
  }

  &.detail-panel-table-block {
    table tr {
      td {
        &:first-child {
          padding: 6px 2px 6px 12px;
        }

        padding: 6px 12px;

        .MuiIconButton-root {
          padding: 3px;
        }

        .expand-collapse-icon {
          font-size: 27px;
          display: flex;
          cursor: pointer;
          color: $clr_lightgrey;
        }
      }

      &.row-expand {
        td {
          .expand-collapse-icon {
            color: $clr_blue;
          }

          background-color: $clr_alice_blue;
        }
      }
    }

    &.first-col-fixed-block {
      tr th:nth-child(2),
      tr td:nth-child(2) {
        position: sticky;
        left: 41px;
      }

      tr th:nth-child(2) {
        z-index: 8;
      }

      tr td:nth-child(2) {
        z-index: 7;
        background-color: $clr_white;
      }

      tr.row-expand td:nth-child(1),
      tr.row-expand td:nth-child(2) {
        background-color: $clr_alice_blue;
      }
    }
  }

  &.first-col-fixed-block {
    tr th:nth-child(1),
    tr td:nth-child(1) {
      position: sticky;
      left: 0;
    }

    tr th:nth-child(1) {
      z-index: 8;
    }

    tr td:nth-child(1) {
      z-index: 7;
      // background-color: $clr_white;
      background-color: $clr_white;
    }

    .nav-link {
      padding: 8px 0 !important;
      min-height: 36px;
      background: #fff;
      margin: 0 auto;
    }

    .nav-text {
      padding: 0 !important;
      min-height: 36px;
      background: #fff;
      margin: 0 auto;
    }

    &.company-table {
      tr td:nth-child(1) {
        padding-left: 20px;
      }
    }
  }

  &.first-checkbox-second-col-fixed-block {
    tr th:nth-child(2),
    tr td:nth-child(2) {
      position: sticky;
      left: 46px;
    }

    tr th:nth-child(2) {
      z-index: 8;
    }

    tr td:nth-child(2) {
      z-index: 7;
      background-color: $clr_white;
    }
  }

  &.last-iconcol-fixed-block,
  &.last-col-fixed-block {
    tr th:last-child,
    tr td:last-child {
      position: sticky;
      right: 0;
    }

    tr th:last-child {
      z-index: 8;
    }

    tr td:last-child {
      z-index: 7;
      background-color: transparent;
      padding: 0;
      min-width: 124px !important;
    }

    .dynamicTableActionButton {
      background: #fff;
      min-height: 40px;
      padding: 8px 20px;
      margin: 0 auto;

      &:first-child {
        width: 100%;
      }

      &:first-child:nth-last-child(2) {
        width: 50%;
      }

      &:first-child:nth-last-child(3) {
        width: 33.33%;
      }
    }
  }

  // &.client-list-table-block{
  //   .name{
  //     min-width: 100px;
  //   }
  //   .subscription_duration{
  //     min-width: 100px;
  //   }
  //   .status{
  //     min-width: 100px;
  //   }
  //   .reports_purchased{
  //     min-width: 100px;
  //   }
  //   .total_users_registered{
  //     min-width: 100px;
  //   }
  //   .confirmed_users{
  //     min-width: 100px;
  //   }
  //   .pending_users{
  //     min-width: 100px;
  //   }
  //   .potential_guests{
  //     min-width: 100px;
  //   }
  // }
  &.colored-th-table-block {
    table tr td .link,
    table tr td .nav-link,
    table tr td .nav-link label,
    table tr td .link label {
      color: inherit;
    }
  }
}

table {
  tr {
    td {
      .icon {
        display: inline-flex;
        font-size: 14px;
        vertical-align: middle;
        cursor: pointer;
      }
    }
  }
}

.user-upload-table-block {
  .scroll-table-wrapper {
    max-height: calc(100vh - 320px);
    min-height: 300px;
  }
}

.client-users-table-block {
  .table-with-checkboxs {
    tr th:nth-child(2) {
      z-index: 8;
    }

    tr td:nth-child(2),
    tr th:nth-child(2) {
      position: sticky;
      left: 45px;
    }

    tr td:nth-child(2) {
      background-color: #fff;
    }
  }
}

.last-2icons-last-2cols-fixed-block {
  table {
    tr {
      th:last-child,
      td:last-child,
      th:nth-last-child(2),
      td:nth-last-child(2) {
        position: sticky;
      }

      td:last-child,
      td:nth-last-child(2) {
        z-index: 2;
        background-color: transparent;
        padding: 0;
      }

      th:last-child,
      th:nth-last-child(2) {
        z-index: 3;
      }

      th:last-child,
      td:last-child {
        right: 0;
        min-width: 114px;
        padding-left: 6px;
        padding-right: 12px;
      }

      td:last-child {
        z-index: 2;
        background-color: transparent;
        padding: 0;
      }

      th:nth-last-child(2),
      td:nth-last-child(2) {
        right: 114px;
      }

      .custom-control {
        padding: 9px 20px !important;
        min-height: 40px;
        background: #fff;
        margin: 0 auto;
      }
    }
  }
}

.last-3cols-fixed-block {
  @extend .last-2icons-last-2cols-fixed-block;

  table {
    tr {
      th:nth-last-child(2),
      td:nth-last-child(2) {
        right: 138px;
        min-width: 84px !important;
      }

      th:nth-last-child(3),
      td:nth-last-child(3) {
        position: sticky;
        z-index: 2;
        right: 226px;
      }

      th:nth-last-child(3) {
        z-index: 3;
      }
    }
  }
}

.custom-pagination-new {
  &.pagination-block {
    .item-count-block,
    .pagination {
      margin-top: 20px;
    }

    .pagination {
      flex-wrap: wrap;

      .page-item {
        margin: 4.5px;
        border: 1px solid $clr_lightgrey_new;
        // box-shadow: 0px 0px 12px #0b254014;
        border-radius: 5px;
        cursor: pointer;

        &.disabled {
          cursor: not-allowed;
        }

        &.active {
          .page-link {
            background-color: $clr_lightblue4;
            color: $clr_white;
          }
        }
      }

      .page-link {
        font-size: 14px;
        border: 0;
        border-radius: 5px;
        min-width: 38px;
        text-align: center;
        color: $clr_black;
      }

      .page-link[aria-label="Previous page"],
      .page-link[aria-label="Next page"] {
        padding: 6.5px 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        .material-icons-outlined {
          font-size: 20px;
        }
      }
    }

    &.right-pagination {
      .pagination {
        margin-left: auto;
      }
    }

    &.center-pagination {
      @media (min-width: 1200px) {
        position: relative;
      }

      .item-count-block {
        @media (min-width: 1200px) {
          position: absolute;
        }
      }

      .pagination {
        margin-left: auto;
        @media (min-width: 1200px) {
          margin-right: auto;
        }
      }
    }
  }
}

.custom-select-new {
  position: relative;
  display: block;
  margin: 0 auto;
  border-radius: 3.5px;
  background-color: $clr_white;
  // box-shadow: 0px 0px 12px #0b254014;
  border: 1px solid $clr_lightgrey_new;
  z-index: 10;

  select {
    border: none;
    outline: none;
    background: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    margin: 0;
    display: block;
    width: 100%;
    padding: 6px 45px 6px 12px;
    font-size: 14px;
    color: $clr_black;
  }

  &:after {
    position: absolute;
    right: 0;
    top: 0;
    width: 32px;
    height: calc(100% - 12px);
    margin: 6px 0px;
    line-height: 20px;
    font-family: "Material Icons";
    content: "\e5cf";
    text-align: center;
    color: $clr_black;
    font-size: 24px;
    border-left: 1px solid $clr_border;
    z-index: -1;
  }

  &.metric-rule-dropdown {
    select {
      height: 30px;
      line-height: 20px;
      font-size: 16px;
    }
  }
}

.custom-select-new-2 {
  select {
    border: 0.5px solid $clr_lightgrey_new;
    border-radius: 5px;
  }

  &:after {
    height: 100%;
    margin: 0px;
    line-height: 35px;
    color: $clr_lightblue2;
  }
}

.custom-select-dropdown {
  select {
    // border: 0.5px solid $clr_lightgrey_new;
    border-radius: 5px;
  }

  &:after {
    height: 100%;
    margin: 0px;
    line-height: 35px;
    color: $clr_lightblue2;
    border-left: 0;
  }

  &.custom-select-dropdown-sm {
    &:after {
      width: 24px;
      line-height: 30px !important;
    }

    select {
      padding: 4px 20px 4px 6px !important;
    }
  }

  &.box-shadow-none {
    box-shadow: none;
  }
}

.custom-combobox-list {
  font-size: $font-size-h6;

  &.min-width {
    .rw-widget-picker:not(.rw-filter-input) {
      width: 184px;
    }
  }

  &.is-invalid {
    .rw-widget {
      .rw-widget-container:not(.rw-filter-input) {
        border-color: $clr_red4 !important;
      }
    }

    .rw-widget-container.rw-state-focus,
    .rw-state-focus > .rw-widget-container,
    .rw-widget-container.rw-state-focus:hover,
    .rw-state-focus > .rw-widget-container:hover {
      // box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
      box-shadow: none !important;
    }

    .rw-i-caret-down:before {
      color: $clr_red4 !important;
    }
  }

  .rw-widget-picker {
    height: 35px;
  }

  .rw-widget-input,
  .rw-filter-input {
    box-shadow: none;
    border: 1px solid #ced4da !important;
  }

  .rw-widget-container.rw-state-focus,
  .rw-state-focus > .rw-widget-container,
  .rw-widget-container.rw-state-focus:hover,
  .rw-state-focus > .rw-widget-container:hover {
    box-shadow: none !important;
    border-color: rgba(0, 123, 255, 0.25);
  }

  .rw-btn-select {
    margin-right: 4px;
    opacity: 1;
  }

  .rw-i {
    display: flex;
  }

  .rw-i-caret-down:before {
    font: 24px "Material Icons";
    content: "\e5cf";
    color: $clr_lightblue2;
    transition: all 0.2s ease;
  }

  // .rw-open{
  //   .rw-i-caret-down:before{
  //     transform: rotate(180deg);
  //   }
  // }
  .rw-list-option.rw-state-selected,
  .rw-list-option.rw-state-selected:hover {
    background-color: $clr_lightblue2;
    border-color: $clr_lightblue2;
    color: $clr_white;
  }

  &.with-noborder {
    .rw-widget-input,
    .rw-filter-input {
      border: 0;
      box-shadow: none;
    }
  }

  &.with-shadow-sm {
    .rw-widget-input,
    .rw-filter-input {
      // box-shadow: 0px 0px 12px #0b254014;
      box-shadow: none;
      border: 1px solid $clr_lightgrey_new;
    }
  }

  &.box-shadow-none {
    .rw-widget-input,
    .rw-filter-input {
      box-shadow: none;
    }
  }
}

.custom-multiselect {
  font-size: $font-size-h6;

  &.is-invalid {
    .rw-widget {
      .rw-widget-container:not(.rw-filter-input) {
        border-color: $clr_red4 !important;
      }
    }

    .rw-widget-container.rw-state-focus,
    .rw-state-focus > .rw-widget-container,
    .rw-widget-container.rw-state-focus:hover,
    .rw-state-focus > .rw-widget-container:hover {
      box-shadow: none;
    }

    .rw-select:before {
      color: $clr_red4 !important;
    }
  }

  .rw-multiselect .rw-input-reset {
    height: 100%;
  }

  .rw-widget-picker {
    height: 35px;

    > div {
      vertical-align: middle;
    }

    .rw-multiselect-taglist {
      height: 100%;
    }
  }

  .rw-widget-input,
  .rw-filter-input {
    // box-shadow: 0px 5px 12px #0b254026;
    box-shadow: none !important;
    border: 1px solid #ced4da !important;
  }

  .rw-widget-container.rw-state-focus,
  .rw-state-focus > .rw-widget-container,
  .rw-widget-container.rw-state-focus:hover,
  .rw-state-focus > .rw-widget-container:hover {
    box-shadow: none;
    border-color: rgba(0, 123, 255, 0.25);
  }

  .rw-btn-select {
    margin-right: 4px;
  }

  .rw-select {
    button {
      display: none;
    }

    &:before {
      font: 24px "Material Icons";
      content: "\e5cf";
      color: $clr_lightblue2;
      transition: all 0.2s ease;
      line-height: 24px;
      vertical-align: middle;
    }
  }
}

.custom-datepicker {
  .rw-widget {
    height: 35px;
  }

  .rw-widget-picker {
    height: 35px;

    > div,
    .rw-select {
      vertical-align: middle;
    }

    .rw-multiselect-taglist {
      height: 100%;
    }
  }

  .rw-i-calendar {
    background: url(../Images/date-picker-icon.svg) no-repeat;
    width: 17px;
    height: 18px;
    background-size: 17px;
    vertical-align: middle;
    border-width: thin;
  }

  .rw-i-calendar:before {
    content: none;
  }

  .rw-widget-container.rw-state-focus,
  .rw-state-focus > .rw-widget-container,
  .rw-widget-container.rw-state-focus:hover,
  .rw-state-focus > .rw-widget-container:hover {
    border-color: #80bdff;
    outline: 0;
    box-shadow: none;
  }

  &.is-invalid {
    .rw-widget .rw-widget-container,
    .rw-select-bordered {
      border-color: $clr_red4 !important;
    }

    .rw-i-calendar {
      background: url(../Images/date-picker-icon-red.svg) no-repeat;
      background-size: 17px;
    }
  }
}

.custom-dropdown-checkbox-list {
  font-size: $font-size-h6;

  .filter-dropdown {
    .dd__selectControl {
      border: 0.5px solid $clr_lightgrey_new;
      // box-shadow: 0px 5px 12px #0b254026;
      color: $clr_lightgrey;
      max-height: 35px;
      height: 35px;
      font-size: $font-size-h6;
      padding: 0px 30px 0px 7.5px !important;

      .list-items {
        .option-label-wrapper {
          .option-label {
            background-color: $clr_lightblue2;
            border-radius: 2px;
            padding: 2px 6px;
            color: $clr_white !important;
            margin-right: 6px;
          }
        }
      }

      div {
        font-size: $font-size-h6;
        white-space: nowrap;
        // text-overflow: ellipsis;
        overflow: hidden;
      }

      .icon {
        display: flex;
        right: 6px;
        position: absolute;
      }

      .icon-down:before {
        font: 24px "Material Icons";
        content: "\e5cf";
        color: $clr_lightblue2;
      }

      .icon-up:before {
        font: 24px "Material Icons";
        content: "\e5ce";
        color: $clr_lightblue2;
      }

      .placeholder {
        margin-left: 7px;
      }
    }

    .filter-options {
      z-index: 110;
      border: 0;
      box-shadow: 0px 5px 12px #0b254026;

      > div {
        padding: 8px 12px;

        &:not(:last-child) {
          border-bottom: 0.5px solid $clr_tableborder;
        }

        > .filter-options-div {
          display: flex;
          align-items: center;
          font-size: 12px;

          input[type="checkbox"] {
            -webkit-appearance: none;
            min-width: 14px;
            width: 14px;
            height: 14px;
            border: 1px solid $clr_lightgrey2;
            vertical-align: sub;
            outline: none;
            border-radius: 3px;
            position: relative;

            &:checked {
              background-color: $clr_blue;
              border-color: $clr_blue;

              &::after {
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmZmZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPmljb25fYnlfUG9zaGx5YWtvdjEwPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjZmZmZmZmIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNi4wMDAwMDAsIDI2LjAwMDAwMCkiPjxwYXRoIGQ9Ik0xNy45OTk5ODc4LDMyLjQgTDEwLjk5OTk4NzgsMjUuNCBDMTAuMjI2Nzg5MSwyNC42MjY4MDE0IDguOTczMTg2NDQsMjQuNjI2ODAxNCA4LjE5OTk4Nzc5LDI1LjQgTDguMTk5OTg3NzksMjUuNCBDNy40MjY3ODkxNCwyNi4xNzMxOTg2IDcuNDI2Nzg5MTQsMjcuNDI2ODAxNCA4LjE5OTk4Nzc5LDI4LjIgTDE2LjU4NTc3NDIsMzYuNTg1Nzg2NCBDMTcuMzY2ODIyOCwzNy4zNjY4MzUgMTguNjMzMTUyOCwzNy4zNjY4MzUgMTkuNDE0MjAxNCwzNi41ODU3ODY0IEw0MC41OTk5ODc4LDE1LjQgQzQxLjM3MzE4NjQsMTQuNjI2ODAxNCA0MS4zNzMxODY0LDEzLjM3MzE5ODYgNDAuNTk5OTg3OCwxMi42IEw0MC41OTk5ODc4LDEyLjYgQzM5LjgyNjc4OTEsMTEuODI2ODAxNCAzOC41NzMxODY0LDExLjgyNjgwMTQgMzcuNzk5OTg3OCwxMi42IEwxNy45OTk5ODc4LDMyLjQgWiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==);
                background-size: 28px;
                background-repeat: no-repeat;
                background-position: center;
              }
            }
          }

          label {
            margin-bottom: 0px;
            margin-left: 12px;
            color: $clr_lightgrey2;
          }

          input[type="checkbox"]:checked + label {
            color: $clr_blue;
          }
        }
      }
    }
  }

  &.year-list {
    .list-items {
      @media (min-width: 768px) and (max-width: 991px) {
        .option-label-wrapper {
          &:nth-child(n + 8) {
            display: none;
          }

          &:nth-child(7) {
            &::after {
              content: "...";
              font-size: 15px;
              line-height: 10px;
              color: $clr_black;
            }
          }
        }
      }
      @media (min-width: 992px) and (max-width: 1299px) {
        .option-label-wrapper {
          &:nth-child(n + 3) {
            display: none;
          }

          &:nth-child(2) {
            &::after {
              content: "...";
              font-size: 15px;
              line-height: 10px;
              color: $clr_black;
            }
          }
        }
      }
      @media (min-width: 1300px) {
        .option-label-wrapper {
          &:nth-child(n + 4) {
            display: none;
          }

          &:nth-child(3) {
            &::after {
              content: "...";
              font-size: 15px;
              line-height: 10px;
              color: $clr_black;
            }
          }
        }
      }
    }
  }

  &.quater-list {
    .list-items {
      @media (min-width: 992px) and (max-width: 1299px) {
        .option-label-wrapper {
          &:nth-child(n + 4) {
            display: none;
          }

          &:nth-child(3) {
            &::after {
              content: "...";
              font-size: 15px;
              line-height: 10px;
              color: $clr_black;
            }
          }
        }
      }
    }
  }
}

.custom-dropdown-list {
  font-size: $font-size-h6;

  .filter-dropdown {
    .dd__selectControl {
      border: 0.5px solid $clr_lightgrey_new;
      // box-shadow: 0px 5px 12px #0b254026;
      color: $clr_lightgrey;
      max-height: 35px;
      height: 35px;
      font-size: $font-size-h6;
      padding: 0px 30px 0px 7.5px !important;

      .list-items {
        .option-label-wrapper {
          .option-label {
            background-color: $clr_lightblue2;
            border-radius: 2px;
            padding: 2px 6px;
            color: $clr_white !important;
            margin-right: 6px;
          }
        }
      }

      div {
        font-size: $font-size-h6;
        white-space: nowrap;
        // text-overflow: ellipsis;
        overflow: hidden;
        margin-left: 7px;
        color: $clr_lightgrey !important;
      }

      .fa {
        display: flex;
        right: 6px;
        position: absolute;
      }

      .fa-angle-down:before {
        font: 24px "Material Icons";
        content: "\e5cf";
        color: $clr_lightblue2;
      }

      .fa-angle-up:before {
        font: 24px "Material Icons";
        content: "\e5ce";
        color: $clr_lightblue2;
      }

      .placeholder {
        margin-left: 7px;
      }
    }

    .dd__list.filter-options.dd__openTobottom {
      z-index: 110;
    }

    .filter-options {
      z-index: 110;
      border: 0;
      box-shadow: 0px 5px 12px #0b254026;

      > div {
        &:not(:last-child) {
          border-bottom: 0.5px solid $clr_tableborder;
        }

        > .filter-options-div {
          display: flex;
          align-items: center;
          font-size: 12px;

          input[type="checkbox"] {
            -webkit-appearance: none;
            min-width: 14px;
            width: 14px;
            height: 14px;
            border: 1px solid $clr_lightgrey2;
            vertical-align: sub;
            outline: none;
            border-radius: 3px;
            position: relative;

            &:checked {
              background-color: $clr_blue;
              border-color: $clr_blue;

              &::after {
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmZmZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPmljb25fYnlfUG9zaGx5YWtvdjEwPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjZmZmZmZmIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNi4wMDAwMDAsIDI2LjAwMDAwMCkiPjxwYXRoIGQ9Ik0xNy45OTk5ODc4LDMyLjQgTDEwLjk5OTk4NzgsMjUuNCBDMTAuMjI2Nzg5MSwyNC42MjY4MDE0IDguOTczMTg2NDQsMjQuNjI2ODAxNCA4LjE5OTk4Nzc5LDI1LjQgTDguMTk5OTg3NzksMjUuNCBDNy40MjY3ODkxNCwyNi4xNzMxOTg2IDcuNDI2Nzg5MTQsMjcuNDI2ODAxNCA4LjE5OTk4Nzc5LDI4LjIgTDE2LjU4NTc3NDIsMzYuNTg1Nzg2NCBDMTcuMzY2ODIyOCwzNy4zNjY4MzUgMTguNjMzMTUyOCwzNy4zNjY4MzUgMTkuNDE0MjAxNCwzNi41ODU3ODY0IEw0MC41OTk5ODc4LDE1LjQgQzQxLjM3MzE4NjQsMTQuNjI2ODAxNCA0MS4zNzMxODY0LDEzLjM3MzE5ODYgNDAuNTk5OTg3OCwxMi42IEw0MC41OTk5ODc4LDEyLjYgQzM5LjgyNjc4OTEsMTEuODI2ODAxNCAzOC41NzMxODY0LDExLjgyNjgwMTQgMzcuNzk5OTg3OCwxMi42IEwxNy45OTk5ODc4LDMyLjQgWiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==);
                background-size: 28px;
                background-repeat: no-repeat;
                background-position: center;
              }
            }
          }

          label {
            margin-bottom: 0px;
            margin-left: 12px;
            color: $clr_lightgrey2;
          }

          input[type="checkbox"]:checked + label {
            color: $clr_blue;
          }
        }
      }

      .dd__option {
        padding: 8px 12px;
      }
    }
  }
}

.custom-date-time-picker {
  .rw-widget {
    .rw-widget-container {
      min-height: 35px;
      border-radius: 5px;
      // box-shadow: 0px 0px 12px #0b254014;
      border: 1px solid $clr_lightgrey_new;
      background-color: white;
    }

    &.rw-state-disabled {
      .rw-widget-container {
        background-color: #eee;
        border-color: #ccc;
      }
    }

    .rw-widget-input,
    .rw-filter-input {
      box-shadow: none;
    }

    .rw-i-calendar:before,
    .rw-i-clock-o:before {
      font-family: "Material Icons Outlined";
      font-size: 17px;
      color: $clr_lightblue2;
    }

    .rw-i-calendar:before {
      content: "\e916";
    }

    .rw-i-clock-o:before {
      content: "\e8b5";
      margin-right: 4px;
    }
  }
}

.opt-select {
  min-width: 154px;
  margin-right: 12px;
  margin-left: 0;
}

.client-list-h1 {
  margin-bottom: 26px;
}

.stats-group {
  margin-left: -9px;
  margin-right: -9px;
  @media (min-width: 768px) {
    margin-left: -14px;
    margin-right: -14px;
  }
  @media (min-width: 1200px) {
    margin-left: -9px;
    margin-right: -9px;
  }
  @media (min-width: 1500px) {
    margin-left: -14px;
    margin-right: -14px;
  }

  .stats-block {
    padding: 10px 9px;
    @media (min-width: 768px) {
      padding: 10px 14px;
    }
    @media (min-width: 1200px) {
      padding: 10px 9px;
    }
    @media (min-width: 1500px) {
      padding: 12px 14px;
    }

    &:not(:last-child) {
      border-right: 1px solid $clr_border;
    }

    h5 {
      margin-bottom: 2px;
    }

    label {
      margin-bottom: 0px;
      color: #606b77;
      font-size: 12px;
    }
  }
}

.min-width-266 {
  min-width: 266px;
}

.min-width-220 {
  min-width: 220px;
}

.min-width-200 {
  min-width: 200px;
}

.search-box-shadow-2 {
  .search-form {
    input {
      // box-shadow: 0px 5px 12px #0b254026;
      &:focus {
        // box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        border-color: rgba(0, 123, 255, 0.25);
      }
    }
  }
}

.search-form {
  position: relative;

  input {
    background: #ffffff 0% 0% no-repeat padding-box;
    // box-shadow: 0px 0px 12px #0b254014;
    border-radius: 5px;
    border: 1px solid $clr_lightgrey_new;
    padding: 6px 48px 6px 14px;
    height: 35px;
    width: 100%;
    font-size: $font-size-h6;
  }

  input.form-control {
    width: 100%;
  }

  button {
    position: absolute;
    right: 0px;
    top: 4px;
    display: flex;
    align-items: center;
    padding: 2px 10px;
    border-left: 1px solid $clr_border;
    border-radius: 0px;

    .material-icons-outlined {
      font-size: 20px;
      color: $clr_lightblue2;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.w-100-search {
  .search-form {
    max-width: 100%;
  }
}

.border-search {
  .search-form {
    input {
      border: 1px solid #ced4da;
    }
  }
}

.box-shadow-none-search {
  .search-form {
    input {
      box-shadow: none;
    }
  }
}

input.custom-datepicker {
  background: #ffffff 0% 0% no-repeat padding-box;
  // box-shadow: 0px 0px 12px #0b254014;
  border: 1px solid $clr_lightgrey_new;
  border-radius: 5px;
  padding: 2px 5px 4px 10px;
  outline: none;

  &.form-control {
    font-size: 14px;
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url(../Images/date-picker-icon.svg) no-repeat;
  width: 16px;
  height: 16px;
  background-size: 15px;
  background-position-y: 4px;
  border-width: thin;
}

.w80 {
  width: 80px;
}

.top-downloads-logins-block {
  flex-wrap: wrap;
  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }
  // table td {
  //   // white-space: nowrap;
  // }
  .responsive-table,
  .current-ait-block-wrapper {
    margin-top: 12px;
  }

  .current-ait-block-wrapper {
    background-color: $clr_lightblue4;
    color: $clr_white;
    border-radius: 3.5px;

    .current-ait-block {
      padding: 15px;
    }

    .ait-duration {
      font-size: 28px;
    }
  }
}

.reports-right-block {
  .client-details-card {
    margin-top: 26px;
  }
}

.client-list-filter-block {
  .search-form {
    margin-bottom: 18px;
  }

  .search-form input.form-control {
    font-size: 14px;
  }
}

// .user-list-table {
//   // margin-top: 20px;
// }
.user-detail-table {
  margin-top: 14px;
}

.sections-card-wrapper {
  background-color: $clr_white;
  // box-shadow: 0px 0px 12px #0b254024;
  border: 1px solid $clr_lightgrey_new;
  border-radius: 4px;

  .sections-card {
    padding: 18px 16px 9px;
    font-size: 13px;

    &.profile-card {
      padding: 20px 26px;
    }

    &.border-card {
      border-right: 1px solid $clr_border;
      border-bottom: 1px solid $clr_border;
    }

    label {
      font-size: 12px;
      color: $clr_lightgrey;
      margin-bottom: 0;
    }

    .reset-link {
      text-decoration: underline;
      color: $clr_lightblue2;
    }
  }

  .sections-card-row-1 {
    .sections-card {
      flex-grow: 1;
    }

    .profile-card {
      .material-icons {
        color: $clr_lightgrey2;
        font-size: 26px;
      }
    }
  }

  .sections-card-row-2 {
    .sections-card {
      padding: 14px 10.5px;
      @media (min-width: 768px) {
        padding: 14px 20.5px;
      }
    }

    .profile-stat-card {
      font-size: 14px;

      .profile-stat:not(:last-child) {
        margin-right: 40px;
      }
    }
  }

  &.user-details-card {
    margin-top: 12px;
  }
}

/* .nav_sidebar ul li:hover label {
     color: $clr_lightblue;
}
 */
/*.nav_sidebar{
        width: 15%;
    }
    */
/*.nav_sidebar{
        width: 25%;
        left: -100%;
        z-index: 9;
         @include transition(all 0.4s ease-in-out);
    }
     .open_nav .nav_sidebar{
        left: 0;
    }
    */

/* Checkbox style */
/* Comapanies Css */
/* Placeholder color chane */
/* Placeholder color chane */
/*
 */
/* Dashboard Css */
/*
.quaters_list::-webkit-scrollbar {
     width: 8px;
     background:$clr_white;
     border-right:solid 1px #edeff5;
}
 .quaters_list::-webkit-scrollbar-track {
     -webkit-box-shadow: inset 0 0 6px #f0f2f5;
  -moz-box-shadow: inset 0 0 6px #f0f2f5;
     -ms-box-shadow: inset 0 0 6px #f0f2f5;
     border-radius: 5px;
}
 .quaters_list::-webkit-scrollbar-thumb {
     border-radius: 15px;
     height: 50px;
     background:#f0f2f5;
}
 */
/* .dashboard_content:nth-child(4n+0):before {
     content: '';
     width: calc( 100% - 60px);
     height: 1px;
    background: red;
    position: absolute;
    top: 0;
    right: 0;
}
 */
/* Companies Details */
/*Graph*/
/*quarterly_final Box*/
/*.company_grph aside{
        flex: 0 0 100%;
         max-width:100%;
         margin-bottom: 30px;
    }
    */
/*TBR Position*/
/*Graph Animation*/
// @-webkit-keyframes "am-moving-dashes" {
// 	100% {
// 		stroke-dashoffset: -31px;
// 	}
// }
// @keyframes "am-moving-dashes" {
// 	100% {
// 		stroke-dashoffset: -31px;
// 	}
// }
// @-webkit-keyframes "am-draw" {
// 	0% {
// 		stroke-dashoffset: 500%;
// 	}
// 	100% {
// 		stroke-dashoffset: 0%;
// 	}
// }
// @keyframes "am-draw" {
// 	0% {
// 		stroke-dashoffset: 500%;
// 	}
// 	100% {
// 		stroke-dashoffset: 0%;
// 	}
// }
/*Login Page*/
/*.login_right form a img + img{
    max-width: 280px
}
*/

.second-loading-indicator:before {
  content: "";
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 0.5;
}

.second-loading-indicator:after {
  content: "";
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1200;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border-top: 8px solid rgba(155, 224, 240, 0.2);
  border-right: 8px solid rgba(155, 224, 240, 0.2);
  border-bottom: 8px solid rgba(155, 224, 240, 0.2);
  border-left: 8px solid $clr_lightblue;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

header,
footer {
  width: 100%;
  position: fixed;
  z-index: 106;
  background: $clr_bodycolor;
  @media (min-width: 991px) {
    // left: 82px;
    // width: calc(100% - 82px);
  }
  @media (min-width: 1366px) {
    // left: 88px;
    // width: calc(100% - 88px);
  }
}

.header-content-wrapper {
  padding: 0px 15px;
  background-color: $clr_white;
}

header {
  top: 0;
  height: 60px;

  .header-content-wrapper {
    border-bottom: 1px solid $clr_border;
  }

  .header-content {
    .site_name {
      font-size: 14px;
      letter-spacing: 0.75px;
      @media (min-width: 556px) {
        font-size: 18px;
      }
      @media (min-width: 768px) {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
      @media (min-width: 992px) {
        font-size: 24px;
      }
      @media (max-width: 767px) {
        align-self: flex-end;
        margin-bottom: 14px;
        font-size: 14px;
      }
    }
  }
}

.sidebar_width {
  width: 76px;
}

footer {
  height: 39px;
  bottom: 0px;

  .footer-content {
    color: $clr_lightgrey;
    border-top: 1px solid $clr_border;
    font-size: 12.64px;
    margin: 0px 15px;
    margin-left: 103px;

    @media (min-width: 992px) {
      .main_content {
        margin-left: 97px;
      }
    }
  }
}

.inner-sidebar {
  width: 100%;
  max-width: 190px;
  background: #fff;
  height: -o-calc(100% - 194px);
  height: -ms-calc(100% - 194px);
  height: calc(100% - 194px);
  // box-shadow: 0px 0px 25px #0b25401a;
  border: 1px solid $clr_lightgrey_new;
  position: fixed;
  top: 140px;
  left: 15px;
  overflow-y: auto;
  padding: 5px 7px;
  border-radius: 3.5px;
  z-index: 103;
  // @media (min-width: 992px) {
  //   left: 97px;
  // }
  @media (min-width: 992px) {
    left: 120px;
  }

  &.inner-sidebar-lg {
    max-width: 230px;
  }

  .expand-collapse-icon {
    color: $clr_grey2;
    cursor: pointer;
  }

  &.add-cat-subcat {
    .expand-collapse-icon {
      position: relative;
      z-index: 3;
    }
  }
}

.inner-sidebar {
  + .inner-sidebar-right-content {
    padding-left: 205px;
    padding-top: 14px;
    transition: all 0.5s ease;
  }

  &.inner-sidebar-lg {
    + .inner-sidebar-right-content {
      padding-left: 245px;
    }
  }

  &.add-cat-subcat {
    .opened-innersidebar-content {
      margin-top: -27px;

      .add_category {
        background-color: unset;
        color: $clr_black2;
        border: none;

        &:focus {
          box-shadow: none;
        }

        .icon {
          margin-right: 6px;
        }
      }
    }
  }
}

.opened-innersidebar {
  .inner-sidebar {
    transition: all 0.5s ease;
    max-width: 190px;

    &.inner-sidebar-lg {
      max-width: 230px;
    }
  }

  .expand-collapse-icon-wrapper {
    .expand-collapse-icon {
      margin-left: auto;
    }
  }

  .opened-innersidebar-content {
    transition: all 0.5s ease;
  }

  .closed-innersidebar-content {
    opacity: 0;
    transform: translateX(-50px);
  }
}

.closed-innersidebar {
  .inner-sidebar {
    max-width: 49px;
    transition: all 0.5s ease;
    overflow-y: hidden;
  }

  .inner-sidebar-right-content,
  .inner-sidebar-lg + .inner-sidebar-right-content {
    padding-left: 65px;
    transition: all 0.5s ease;
  }

  .expand-collapse-icon-wrapper {
    justify-content: center;

    .expand-collapse-icon {
      transform: rotate(180deg);
    }
  }

  .opened-innersidebar-content {
    width: 0px;
    transform: translateX(-176px);
    transition: all 0.5s ease;
    height: 0;
  }

  .closed-innersidebar-content {
    opacity: 1;
    transition: all 0.5s ease;
    transform: translateX(0px);

    .content {
      writing-mode: vertical-lr;
      margin: 6px;
      font-size: 16px;
      transform: rotate(180deg);
    }
  }
}

.inner-content-section {
  .del-undo-icon-wrapper {
    border-radius: 50%;
    background: #fcfdff;
    padding: 6px;
    cursor: pointer;

    .icon {
      color: $clr_error;
    }
  }

  .content-section,
  .content-section-selected {
    position: relative;
    // border: 1px solid $clr_border;
    border: 1px solid #cfd4d7;
    padding: 16px 12px;
    border-radius: 5px;
    margin-top: 8px;
    margin-bottom: 18px;

    .title {
      position: relative;
      background-color: $clr_alice_blue2;
      padding: 6px 9px;
      margin-bottom: 10px;
      //position: absolute;
      //top: 16px;
      //left: 12px;
      //right: 12px;
      //z-index: 9;
    }

    img {
      max-width: 100%;
      height: auto;
    }

    .add-edit-btn {
      border: 1px solid $clr_tableborder;
      border-radius: 4px;
      padding: 4px;
      color: $clr_lightblue2;
      font-size: 14px;
      font-weight: normal;
    }

    .add-content-icon {
      border: 1px solid $clr_tableborder;
      border-radius: 2px;
      padding: 4px;
      color: $clr_lightblue2;

      .icon {
        color: $clr_lightblue2;
      }
    }

    .add-section-btn {
      color: $clr_orange3;
      background-color: $clr_lightorange2;
      border: 1px solid $clr_lightorange3;
      border-radius: 4px;
      padding: 5px 9px 5px 5px;

      .icon {
        color: $clr_orange3;
      }
    }

    table {
      td,
      th {
        color: unset;
        font-size: unset;
        padding: 8px 16px;
        vertical-align: top;
        // &:first-child {
        //   padding-left: 0px;
        // }
        // &:last-child {
        //   padding-right: 0px;
        // }
        p {
          margin-bottom: 0px;
        }
      }
    }

    p {
      color: $clr_black2;
      font-size: 14px;
    }
  }

  .content-section-selected {
    border: 1px solid #1084c5;
  }
}

.sub-category-section {
  &.news {
    .inner-content-section {
      .content-section {
        border: 1px solid $clr_lightblue5;
        background-color: $clr_alice_blue2;

        .title {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}

.breadcrumb-mb-0 {
  .new-breadcrumb .breadcrumb {
    margin-bottom: 0px;
  }
}

.new-breadcrumb {
  .breadcrumb {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0;
    margin-bottom: 13px;
    list-style: none;
    background-color: transparent;

    li.breadcrumb-item {
      color: $clr_lightgrey2;
      font-size: 16px;

      + .breadcrumb-item:not(:last-child):before {
        color: $clr_lightblue2;
      }

      &::before {
        color: #6c757d;
      }

      &:first-child,
      &:first-child .nav-link {
        color: $clr_lightblue2;
        font-weight: bold;
        font-size: 22.78px;
        font-family: $font-family-2;
      }

      &:nth-child(2) {
        padding-left: 1rem;
      }

      .nav-link {
        display: inline;
        color: $clr_lightblue2;
        padding: 0;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
}

.row {
  &.xl-gutter {
    @media (min-width: 768px) {
      margin-left: -19px;
      margin-right: -19px;
    }
    @media (min-width: 1200px) {
      margin-left: -25.5px;
      margin-right: -25.5px;
    }

    .col-sm-6,
    .col-lg-4,
    .col-xl-3 {
      @media (min-width: 768px) {
        padding-left: 19px;
        padding-right: 19px;
      }
      @media (min-width: 1200px) {
        padding-left: 25.5px;
        padding-right: 25.5px;
      }
    }
  }
}

.fixed-title-section {
  position: sticky;
  // top: 60px;
  top: 0;
  z-index: 106;
  background-color: $clr_white;
  // background-color: $clr_bodycolor;
  padding-top: 16px;
}

.main_content_height {
  height: calc(100vh - 116px);
  overflow-y: auto;
}

.main_content_height_2 {
  min-height: 610px;
}

.icon-btn {
  width: 33px;
  height: 33px;
  border-radius: 5px;
  border: 0;
  outline: none;

  .icon {
    font-size: 17px;
  }

  &:focus {
    outline: none;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.icon-box-shadow {
    box-shadow: 0px 5px 12px #0b254026;
  }
}

.help-icon-box-shadow {
  .icon-btn {
    box-shadow: 0px 5px 12px #0b254026;
  }
}

.icon-btn-primary {
  background-color: $clr_lightblue2;

  .icon {
    color: $clr_white;
  }
}

.icon-btn-white {
  background-color: $clr_white;

  .icon {
    color: $clr_lightblue2;
  }
}

.icon-text-primary-btn,
.icon-text-btn {
  &.title-btn {
    padding: 4px 12px;
    font-size: 0.79rem;
    height: 33px;
    box-shadow: none !important;

    .mt-text {
      margin-top: 2px;
    }
  }

  &.btn-md {
    line-height: 1;
    padding: 7px 10px;
  }
}

nav {
  width: 100%;
  // box-shadow: 0px 1px $font-size-12 0px #ddd;
  .form-inline {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    position: relative;

    .form-control {
      width: 100%;
      padding-right: 50px;
    }

    .btn {
      width: 50px;
      position: absolute;
      top: 0;
      right: 0;
      background: $clr_lightblue;
      border-color: $clr_lightblue;
      color: $clr_white;
    }
  }
}

.tbr-embmelogo-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 16px 0px 23px;
  @media (min-width: 768px) {
    // margin: 32px 0px 44px;
    margin: 16px 0px 22px;
  }

  #tbr-emblem-logo {
    max-width: 38px;
    opacity: 1;
    transition: all 0.5s ease-in-out;
    @media (min-width: 768px) {
      max-width: 48px;
    }
  }
}

.navbar-brand.header-logo {
  @media (max-width: 767px) {
    margin-right: 0px;
  }

  #tbr-logo {
    width: 60px;
    margin: 0px 0px 14px 0px;
  }
}

.open_nav {
  .tbr-embmelogo-wrapper {
    pointer-events: none;
  }

  #tbr-emblem-logo {
    opacity: 0;
  }

  // @media (min-width: 768px) {

  // }
}

.custom-popover {
  .popover {
    border: 0;
    border-radius: 3.5px;
    // box-shadow: 0px 0px 30px #0b254017;
    border: 1px solid $clr_lightgrey_new;
    z-index: 1040;

    .popover-inner {
      max-height: 500px;
      overflow-y: auto;
      border-radius: 3.5px;
      border-top: 12px solid $clr_lightblue2;
      border-bottom: 5px solid $clr_lightblue2;
    }

    .popover-header {
      color: $clr_darkblue6;
      padding-bottom: 4px;
      border: none;
      background-color: #ffffff;
    }

    &.bs-popover-bottom .popover-header::before,
    &.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
      border: none;
    }

    .arrow {
      display: none;
    }

    &.bs-popover-auto[x-placement^="bottom"] {
      margin-right: 10px;
    }

    .popover-item {
      align-items: center;
      padding: 0.5rem 0.75rem;
      cursor: pointer;
      border-radius: 3px;
      color: $clr_lightgrey2;
      margin-bottom: 3px;

      .icon {
        display: inline-flex;
        color: $clr_lightgrey2;
        font-size: 22px;
      }

      &:hover {
        background-color: $clr_solitude;
        color: $clr_blue;

        .icon {
          color: $clr_blue;
        }
      }
    }

    .active {
      background-color: $clr_solitude;
    }
  }
}

.authors-popover {
  .popover {
    .popover-body {
      padding: 0.5rem 1.2rem;

      .authors-list {
        .author {
          padding: 10px 0px;

          &:not(:last-child) {
            border-bottom: 0.5px solid $clr_tableborder;
          }

          a {
            font-size: 14px;
            text-transform: capitalize;
            line-height: 16px;
            color: $clr_lightblue2;
          }
        }
      }
    }
  }
}

.menu_lines {
  display: block;
  cursor: pointer;

  @media only screen and (max-width: 992px) {
    top: 5px;
  }
}

.bar1 {
  width: 30px;
  height: 2px;
  background-color: $clr_lightgrey;
  margin: 5px 0;
  display: block;
  @include transition(all 0.4s ease-in-out);
}

.bar2 {
  width: 30px;
  height: 2px;
  background-color: $clr_lightgrey;
  margin: 5px 0;
  display: block;
  @include transition(all 0.4s ease-in-out);
}

.bar3 {
  width: 30px;
  height: 2px;
  background-color: $clr_lightgrey;
  margin: 5px 0;
  display: block;
  @include transition(all 0.4s ease-in-out);
}

.title-right-block {
  .icon-group {
    .icon-btn {
      margin-left: 14px;
    }

    .icon-btn:first-child {
      margin-left: 17px;
    }
  }
}

.open_nav {
  // .bar1 {
  //   -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  //   transform: rotate(-45deg) translate(-5px, 2px);
  // }
  // .bar2 {
  //   opacity: 0;
  // }
  // .bar3 {
  //   -webkit-transform: rotate(45deg) translate(-5px, -3px);
  //   transform: rotate(45deg) translate(-5px, -3px);
  // }
  //   test
  .nav_sidebar {
    max-width: 250px;
    // max-width: fit-content;
    @media only screen and (max-width: 1199px) {
      // width: 5%;
      left: 0;
    }

    ul {
      li {
        text-align: center;
        white-space: nowrap;

        label {
          padding-left: 0;
          // transform: translateX(-100em);
          // text-indent: -9999px;
        }

        i {
          // left: 0%;
          // right: 0;
          margin: 0 auto;
          opacity: 1;
          @include transition(all 0.5s ease-in-out);
        }
      }
    }

    + .main_content {
      // width: 97%;
      width: 100%;
      // padding-left: 60px;
      @media only screen and (max-width: 767px) {
        padding-left: 15px;
      }
    }

    .child-item {
      i:not(.toggle-menu) {
        left: 25px;
        @media only screen and (max-width: 1199px) {
          left: 0px;
        }
      }
    }
  }

  .nav_sidebar2 {
    // left: 60px;
    @media only screen and (max-width: 1199px) {
      // left: 5%;
    }

    + .main_content {
      // width: 100%;
      // padding-left: 260px;
      @media only screen and (max-width: 767px) {
        // padding-left: 0px;
      }
      // @media only screen and (max-width: 1199px) {
      //   width: 100%;
      //   padding-left: 260px;
      // }
      //   @media only screen and (max-width:767px){
      // 	width: 75%;
      //   }
    }

    @media only screen and (max-width: 1366px) {
      // width: 12%;
    }
    @media only screen and (max-width: 1199px) {
      // width: 20%;
    }
    @media only screen and (max-width: 767px) {
      // left: 5%;
    }
  }

  .project_details {
    .main_content {
      // padding-left: 310px !important;
    }
  }

  // test end
}

@media only screen and (min-width: 1200px) {
  // .bar1 {
  //   -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  //   transform: rotate(-45deg) translate(-5px, 2px);
  // }
  // .bar2 {
  //   opacity: 0;
  // }
  // .bar3 {
  //   -webkit-transform: rotate(45deg) translate(-5px, -3px);
  //   transform: rotate(45deg) translate(-5px, -3px);
  // }
  .open_nav {
    .bar1,
    .bar2,
    .bar3 {
      transform: none;
    }

    .bar2 {
      opacity: 1;
    }
  }
}

.navbar-brand {
  width: 100%;
  max-width: 250px;
  display: flex;
  justify-content: center;
  padding: 0;
}

.navbar-expand-lg {
  padding-left: 10px;

  .logo {
    padding-left: 0px;
  }
}

.login_sec {
  ul {
    li {
      position: relative;

      &.user-details {
        cursor: pointer;
        padding-right: 15px;

        .icon {
          color: $clr_lightgrey;
        }

        .user-details-block {
          font-size: 14px;
          margin: 0px 2px 0px 6px;
        }

        .arrow-drown-down {
          color: $clr_blue;
          font-size: 27px;
        }
      }

      span {
        color: $clr_black2;

        &.preferences {
          padding: 0;
        }

        &.user-role {
          font-size: $font-size-12;
          text-align: right;
          display: block;
          text-transform: capitalize;
          color: $clr_lightgrey2;
          margin-left: 1px;
        }

        &.disable,
        &.disable i {
          color: $clr_grey;
        }
      }

      a {
        padding: 0 5px;

        i {
          color: $clr_lightblue;
          font-size: 22px;
        }
      }

      span {
        i {
          color: $clr_lightblue;
          font-size: 22px;
        }

        cursor: pointer;
      }

      ul {
        display: block;
        width: 300px;
        padding: 15px;
        background: $clr_white;
        box-shadow: 0 0 4px #b5b5b5;
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
        visibility: hidden;
        @include transition(all 0.5s ease-in-out);

        li {
          width: 100%;
          float: left;
          position: relative;
          padding: 0 30px;
          margin-bottom: 15px;

          &:hover {
            a,
            i,
            span {
              color: $clr_darkblue;
            }
          }

          a,
          span {
            color: $clr_lightblue;
            font-size: $font-size-12;
            font-weight: 600;
            display: block;
            position: relative;
            padding-left: 25px;

            i {
              font-size: 18px;
              padding-right: 5px;
              position: absolute;
              left: 0;
              top: 1px;
            }

            span {
              display: block;
              font-size: 11px;
              color: #b0b5bd;
              cursor: pointer;
            }
          }
        }
      }

      &:nth-last-child(2) {
        @media only screen and (min-width: 768px) {
          padding-right: 0;
        }
      }
    }

    li.active {
      ul {
        top: 50px;
        visibility: visible;
        opacity: 1;
        @media only screen and (max-width: 992px) {
          top: 44px;
        }
      }
    }
  }

  .profile_circle {
    color: $clr_white;
    width: 36px;
    height: 36px;
    padding: 5px;
    border-radius: 50%;
    background-color: #07407a;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
}

.login_sec.user-popover {
  ul {
    display: block;
    width: 280px;
    padding: 15px;
    background: #fff;
    box-shadow: 0px 0px 30px #0b254017;
    position: fixed;
    top: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease-in-out;
    z-index: 1040;
    right: 15px;
    border-radius: 3.5px;
    border-top: 12px solid $clr_lightblue2;
    border-bottom: 5px solid $clr_lightblue2;
  }

  li {
    width: 100%;
    float: left;
    position: relative;
    padding: 0 18px;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 10px;
    }

    a,
    span {
      color: #1085c6;
      font-size: 0.75rem;
      font-weight: 600;
      display: block;
      position: relative;
      padding-left: 25px;

      i {
        font-size: 18px;
        padding-right: 5px;
        position: absolute;
        left: 0;
        top: 1px;
      }
    }

    .userProfile p {
      margin-bottom: 5px;
    }

    .user-profile-role {
      font-size: 11px;
      display: block;
      padding: 0;
      color: #0b2540;
      text-transform: capitalize;
      font-weight: normal;
      cursor: text;

      &:hover {
        color: #0b2540;
      }
    }
  }

  li:nth-last-child(2) {
    padding-right: 0;
  }
}

.login_sec.user-popover.active {
  ul {
    top: 50px;
    visibility: visible;
    opacity: 1;
  }
}

.userProfile {
  position: relative;
  padding-left: 50px;

  img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: $clr_grey;
    position: absolute;
    top: 0;
    left: -10px;
  }

  span {
    text-transform: uppercase;
    font-size: 10px;
    margin-bottom: 5px;
    color: #b0b5bd;
    display: block;
  }

  h4 {
    font-size: $font-size-12;
    color: #212529;
    margin-bottom: 5px;
  }

  p {
    font-size: 11px;
    color: #0b2540;
  }
}

.body-overlay {
  height: 100%;
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;
  background: #ffffff87 0% 0% no-repeat padding-box;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
  transition: all 0.5s ease-in-out;
  width: 100%;
  opacity: 1;
}

.nav_sidebar {
  // width: 11%;
  width: 100%;
  z-index: 111;
  // background: $clr_darkblue;
  // padding: 10px 8px;
  box-shadow: 0px 15px 25px #0b254030;
  height: 100%;
  position: fixed;
  top: 60px;
  left: 0;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  background-color: white;
  @include transition(all 0.5s ease-in-out);
  max-width: 82px;
  @media (min-width: 1200px) {
    max-width: 88px;
  }
  @media only screen and (max-width: 991px) {
  }
  @media only screen and (max-width: 991px) {
    top: 0px;
    left: -100%;
  }

  ul {
    li {
      a {
        display: block;
        // padding: 12px 5px;
        position: relative;
        overflow: hidden;
        transition: all 0.5s ease-in-out;
        // &:before {
        //   content: "";
        //   border-left: 6px solid $clr_lightblue;
        //   height: 100%;
        //   position: absolute;
        //   top: 0;
        //   opacity: 0;
        //   left: 0;
        // }
      }
    }
  }

  figure {
    a,
    div {
      display: block;
      position: relative;
      text-align: center;

      p {
        position: absolute;
        bottom: 10px;
        left: 0;
        right: 0;
        margin: 0 auto;
        color: $clr_white;

        span {
          width: 100%;
          display: block;
          font-size: 7px;
          letter-spacing: 1px;
          text-transform: uppercase;
        }
      }
    }

    position: absolute;
    bottom: 50px;
  }

  // #tbr-logo {
  //   margin-bottom: 10px;
  //   height: 50px;
  //   width: 100px;
  //   fill: $clr_white;
  //   margin-left: 10px;
  // }
  svg#tbr-logo {
    path {
      fill: $clr_darkblue;
    }

    rect {
      fill: $clr_green;
    }
  }

  /* &.closedSidebar {
    width: 4%;
    text-align: center;
    // left: -150px;
    .sidebar_nav {
      ul {
        li {
          a {
            padding: 15px 5px;
            label {
              display: none;
            }
            i {
              //   display: block;
              // test
            }
          }
        }
      }
    }
    + .main_content {
      width: 96%;
    }
  }
  */
}

.sidebar_nav_wrapper {
  min-height: 100%;
}

.sidebar_nav {
  width: 100%;
  // overflow-y: auto;
  padding-top: 0;

  .nav-link {
    display: flex;
    cursor: pointer;
  }

  li {
    width: 100%;
    display: block;
    padding-left: 0;

    &.child-item {
      a {
        // background: $clr_darkblue2;
        // padding-left: 15px;
      }
    }

    &.parent-item {
      cursor: pointer;
    }
  }

  // Hiding the checkbox of sidebar
  li.active {
    a:not(.parent-item) {
      // background: $clr_darkblue3;
      // color: $clr_white;
      // &:before {
      //   background: $clr_darkblue3;
      //   opacity: 0;
      // }
      label {
        &:after {
          opacity: 0;
          visibility: visible;
          border-radius: 5px;
          left: 10px;
        }
      }
    }
  }

  input[type="checkbox"] {
    display: none;
  }

  label {
    margin-bottom: 0px;
    cursor: pointer;
  }

  .nav-link,
  .parent-item a {
    position: relative;
    color: $clr_lightgrey2;
    font-size: $font-size-13;
    cursor: pointer;
    margin: 8px 0px;
    @include transition(all 0.5s ease-in-out);
  }

  i:not(.toggle-menu) {
    font-size: 16px;
    color: $clr_white;
    position: absolute;
    top: 12px;
    left: 25px;
    @media only screen and (max-width: 1199px) {
      left: 0;
      right: 0;
    }

    svg {
      width: 20px;
      height: 20px;

      .a,
      .b,
      .c,
      .d {
        stroke: $clr_white;
      }
    }

    // display: none;
  }

  i.toggle-menu {
    float: left;
    line-height: 25px;
    font-size: 10px;
    padding-right: 3px;
    color: white;
    position: absolute;
    top: 12px;
    left: 5px;
    @media only screen and (max-width: 1199px) {
      // display: none;
    }
  }

  .child-item {
    i:not(.toggle-menu) {
      left: 35px;
      @media only screen and (max-width: 1199px) {
        left: 0px;
      }
    }
  }

  [type="checkbox"] {
    &:checked {
      + {
        label {
          &:after {
            opacity: 1;
            visibility: visible;
            border-radius: 5px;
            left: 10px;
          }
        }
      }
    }
  }
}

.sidebar_nav_icons_wrapper {
  padding: 6px 8px;
  background-color: white;
  position: relative;
  z-index: 2;
}

.sidebar_nav_icons {
  width: 66px;
  background: transparent linear-gradient(180deg, #0b2540 0%, #003770 100%) 0%
    0% no-repeat padding-box;
  border-radius: 9px;
  transition: all 0.5s ease-in-out;
  @media (min-width: 1366px) {
    width: 72px;
  }

  .material-icons-outlined {
    font-size: 21px;
  }

  .nav-item {
    border-radius: 4px;
    margin-top: -6px;

    &:hover,
    &.active {
      a.nav-link {
        background-color: $clr_darkblue4;
        border-radius: 4px;

        .material-icons-outlined {
          color: $clr_lightblue4;
        }
      }
    }
  }

  // a.nav-link {
  //   &:hover,
  //   &.active {
  //     background-color: $clr_darkblue4;
  //     border-radius: 4px;
  //     .material-icons-outlined {
  //       color: $clr_lightblue4;
  //     }
  //   }
  // }
  .navbar-nav {
    padding: 0px 0px 18px;
    overflow-y: auto;
    height: 600px;

    &::-webkit-scrollbar-thumb {
      visibility: hidden;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        visibility: visible;
      }
    }

    @media (min-width: 1366px) {
      padding: 0px 0px 18px;
    }
  }

  .nav-link,
  .parent-item a {
    padding: 7px;
  }

  .parent-item a {
    position: relative;
    overflow: visible;

    .toggle-menu {
      position: absolute;
      left: -12px;
    }
  }

  li {
    display: flex;
    justify-content: center;
  }
}

.sidebar_nav_labels_wrapper {
  padding: 6px 0;
  transform: translateX(-160px);
  transition: all 0.5s ease-in-out;
  position: relative;
  z-index: 1;
  // @media (max-width: 991px) {
  //   display: none;
  // }
}

.open_nav {
  .sidebar_nav_labels_wrapper {
    transform: translateX(0px);
    background-color: $clr_white;
  }

  .sidebar_nav_icons {
    box-shadow: 0px 4px 25px #0b25403d;
  }
}

.sidebar_nav_labels {
  // margin-left: 9px;
  width: auto;

  .nav-link,
  .parent-item a {
    padding: 7.75px 15px;
    display: flex;
    margin: 8px;
  }

  .parent-item {
    &.opened {
      label {
        color: $clr_lightblue3;
        font-weight: 600;
      }
    }
  }

  ul li {
    a {
      padding: 0;
    }

    &.nav-item {
      &.active {
        a {
          background: $clr_solitude;
          color: $clr_white;
          border-radius: 4px;

          &:before {
            background: $clr_lightblue2;
            opacity: 0;
          }

          label {
            color: $clr_lightblue3;
            font-weight: 600;

            &:after {
              opacity: 0;
              visibility: visible;
              border-radius: 5px;
              left: 10px;
            }
          }
        }
      }

      &:hover {
        a {
          background: $clr_solitude;
          border-radius: 4px;

          &:before {
            background: $clr_solitude;
            opacity: 1;
          }

          label {
            color: $clr_lightblue3;
            // font-weight: 600;
          }
        }
      }
    }
  }

  .navbar-nav {
    height: 600px;
    overflow-y: auto;

    &::-webkit-scrollbar-thumb {
      visibility: hidden;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        visibility: visible;
      }
    }
  }
}

.parent-nav-collapsible-item {
  position: relative;

  &.opened::before {
    content: "";
    display: block;
    width: 70px;
    height: 100%;
    background-color: $clr_darkblue7;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.br_8 {
  border-radius: 8px;
}

.main_content {
  // width: 100%;
  // background-color: white;
  // margin-top: 75px;
  padding: 60px 0 15px 0;
  border-radius: 8px;
  @include transition(all 0.5s ease-in-out);
  @media (min-width: 992px) {
    padding-left: 88px;
  }
  @media (min-width: 1200px) {
    padding-left: 88px;
  }

  .title-left-content,
  .title-right-block {
    margin-bottom: 25px;
  }

  .title-section {
    margin-bottom: 16px;
  }
}

.fixed-title-main-content.main_content {
  padding-top: 60px;
  // .inner-sidebar-right-content {
  // padding-top: 13px;
  // }
  @media (max-width: 992px) {
    padding-top: 60px;
  }
}

.welcome_title {
  padding-bottom: 30px;
}

.left_sec {
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  padding: 20px 30px;
  border-radius: 10px;
  margin-top: 35px;
  margin-bottom: 25px;

  h4 {
    margin-bottom: $font-size-18;
  }
}

.companies_content {
  margin-bottom: 15px;
  position: relative;
  padding-left: 30px;

  h5 {
    color: $clr_darkgrey;
    margin: 0;
    text-transform: uppercase;

    span {
      width: 20px;
      height: 20px;
      background: #d9dee5;
      color: $clr_white;
      font-size: $font-size-12;
      display: inline-block;
      border-radius: 100%;
      text-align: center;
      line-height: 20px;
      position: absolute;
      left: 0;
    }
  }

  p {
    font-size: $font-size-12;
  }
}

.companies_list {
  ul {
    li {
      width: 100%;
      position: relative;

      a {
        border: solid 1px #ebebeb;
        padding: 7px 10px;
        color: $clr_black;
        display: block;
        background: $clr_white;
        margin-bottom: 15px;
      }
    }

    ul {
      padding-left: 25px;
      position: relative;

      &:before {
        content: "";
        height: 100%;
        border-left: solid 1px #e7e9ed;
        display: block;
        position: absolute;
        top: -15px;
        left: 10px;
      }

      li {
        &:before {
          content: "";
          width: 15px;
          height: auto;
          border-bottom: solid 1px #e7e9ed;
          display: block;
          position: absolute;
          top: 19px;
          left: -15px;
        }
      }
    }
  }
}

.main_content_arrow {
  height: 100%;
  @include flexbox;

  img {
    width: 100px;
    height: 60px;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
  }

  span {
    position: relative;
    width: 100%;
    display: block;
    height: auto;
    align-self: center;

    &:before {
      width: 60px;
      height: 50px;
      background: #f5f5f5;
      position: absolute;
      top: -5px;
      content: "";
      transform: none;
      left: 15px;
      border: solid 2px $clr_white;
    }

    &:after {
      content: "";
      border: solid 30px #f5f5f5;
      border-left: solid 30px transparent;
      border-bottom: solid 30px transparent;
      width: 45px;
      height: 45px;
      display: block;
      transform: rotate(45deg);
      right: 10px;
      position: absolute;
      top: -10px;
    }
  }

  i {
    font-size: 65px;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    opacity: 0;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    text-align: center;
    -webkit-animation: linear infinite alternate;
    -webkit-animation-name: moveleft;
    -webkit-animation-duration: 5s;
  }
}

.left_sec.companies_last {
  margin-top: 0;
}

.watchlist {
  a {
    width: 100%;
    display: block;
    font-weight: 500;
    margin-bottom: 10px;
    padding-left: 20px;

    i {
      padding: 0 5px;
    }
  }
}

.companies_last {
  .companies_list {
    height: 50%;
    margin-top: 0;

    .companies_content {
      padding-left: 0;
    }
  }

  ul {
    text-align: center;

    li {
      a {
        background: $clr_darkblue;
        color: $clr_white;
        border-radius: 6px;
        background: #07407a;
      }
    }
  }
}

.checkbox_style {
  label {
    display: block;
    cursor: pointer;
    margin: 0;
    padding: 0;
  }

  input[type="checkbox"] {
    display: none;
  }

  span {
    &:before {
      content: "";
      width: 18px;
      height: 18px;
      background: $clr_white;
      display: block;
      position: absolute;
      right: 10px;
      top: 10px;
      border-radius: 5px;
      border: solid 1px #ddd;
    }

    &:after {
      content: "\f00d";
      width: 18px;
      height: 18px;
      background: $clr_lightblue;
      display: block;
      position: absolute;
      right: 10px;
      top: 10px;
      font-family: fontawesome;
      border-radius: 100%;
      opacity: 0;
      visibility: hidden;
      @include transition(all 0.5s ease-in-out);
      color: $clr_white;
      line-height: 18px;
    }
  }

  [type="checkbox"] {
    &:checked {
      + {
        span {
          &:after {
            opacity: 1;
            visibility: visible;
            border-radius: 5px;
            right: 10px;
          }
        }
      }
    }
  }
}

.skip_btns {
  margin-bottom: 15px;

  > a {
    padding: 0px 25px;
    max-width: 120px;
    line-height: 32px;
    width: 100%;
    color: $clr_lightblue;
    @include transition(all 0.4s ease-in-out);

    &:hover {
      color: #07407a;
    }
  }

  > a.btn {
    height: 34px;
    background: $clr_lightblue;
    border-color: $clr_lightblue;
    color: $clr_white;

    &:hover {
      background: $clr_white;
      color: $clr_lightblue;
    }
  }
}

.comapnay_tbl {
  h1 {
    font-size: $font-size-18;
    margin-bottom: 20px;
    @media only screen and (max-width: 1199px) {
      font-size: 18px;
      margin-bottom: 10px;
      padding-top: 15px;
    }
  }

  > .row {
    margin-bottom: 20px;
  }

  .form-control {
    width: 75px;
    display: inline-block;
    // height: calc(1.4em + .75rem + 2px);
    // height: calc(1.2em + 0.8rem + 1px);
    font-size: $font-size-12;
  }

  .search-form {
    .btn {
      padding: 2px 10px;
      top: 5px;
      height: auto;
    }
  }

  .icon-text-btn.title-btn {
    padding: 4px 12px;
    font-size: 0.79rem;
    height: 33px;
  }

  .btn {
    padding: 0.3rem 0.75rem;
    height: 30px;
    line-height: 0.5;
    font-size: $font-size-12;
  }

  .nav-tabs {
    width: 100%;
  }

  .tab-content {
    margin-top: 10px;
    width: 100%;
  }

  .nav-link {
    cursor: pointer;
  }

  .companies_search {
    margin-bottom: 10px;
  }
}

.table-responsive {
  padding: 0 15px;
  overflow-x: none !important;
}

.table-responsive2 {
  max-height: 500px;
  overflow-x: auto !important;
  @media only screen and (max-width: 1199px) {
    overflow-y: auto !important;
  }
}

// .nav_sidebar {
//   z-index: 100;
// }
.comapany_table {
  display: inline-table;
  @media only screen and (max-width: 768px) {
    min-width: 1000px;
  }
  @media only screen and (max-width: 1199px) {
    // display: block;
    // width: 1020px;
  }

  thead {
    @media only screen and (max-width: 1199px) {
      // background: $clr_lightblue;
    }

    tr {
      th {
        color: $clr_grey;
        font-size: $font-size-12;
        font-weight: 700;
        padding: 8px 5px;
        text-align: center;
        position: sticky !important;
        left: 0px;
        z-index: 2;
        background-color: $clr_white;
        top: 0px;
        white-space: nowrap;
        @media only screen and (max-width: 1199px) {
          font-size: 12px;
          color: $clr_grey;
          &:not(:nth-child(1)) {
            &:not(:nth-child(2)) {
              &:not(:nth-child(3)) {
                border-right: solid 1px $clr_white;
              }
            }
          }
        }

        &:nth-child(1),
        &:nth-child(2) {
          text-align: left;
        }

        &:nth-child(1) {
          left: 0;
          position: sticky !important;
          z-index: 8;
          top: 0;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          // white-space: nowrap;
          // min-width: 150px;
          // max-width: 150px;
          // width: 150px;
          white-space: normal !important;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          @media only screen and (min-width: 769px) {
            left: 0;
            position: sticky !important;
            z-index: 8;
            top: 0;
          }
        }

        &.default,
        &.asc,
        &.desc {
          cursor: pointer;
        }

        &.default:after {
          font: 12px FontAwesome;
          content: "\2003\f0dc";
        }

        &.asc:after {
          font: 12px FontAwesome;
          content: "\2003\f0de";
          vertical-align: bottom;
        }

        &.desc:after {
          font: 12px FontAwesome;
          content: "\2003\f0dd";
          vertical-align: top;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        // width: 150px;
        font-size: $font-size-12;

        &.w80 {
          min-width: 100px;
          white-space: normal !important;
          word-wrap: break-all;
        }

        &.company_name {
          // min-width: 150px;
          white-space: normal;
          word-wrap: break-all;
          text-transform: capitalize;
        }

        @media only screen and (max-width: 1199px) {
          font-size: 12px;
        }

        i {
          // background: #5cb85c;
          // color: $clr_white;
          font-size: 10px;
          padding: 2px;
          border-radius: 50%;
        }

        padding: 8px 5px;
        text-align: center;
        // &:not(:nth-child(1)) {
        //   &:not(:nth-child(2)) {
        //     &:not(:nth-child(3)) {
        //       border-right: solid 1px #dee2e8;
        //     }
        //   }
        // }
        &:nth-child(1) {
          color: $clr_lightblue;
          text-align: left;
        }

        &:nth-child(2) {
          text-align: left;
        }

        .nav-link {
          color: $clr_lightblue;
          text-align: left;
          padding: 0;
          font-size: $font-size-12;
          cursor: pointer;

          label {
            margin: 0;
          }
        }

        &:nth-child(1) {
          left: 0;
          position: sticky !important;
          z-index: 7;

          label {
            font-weight: 500;
          }
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          white-space: nowrap;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          @media only screen and (min-width: 769px) {
            left: 0;
            position: sticky !important;
            z-index: 7;
            label {
              font-weight: 600;
            }
          }
        }
      }

      &:first-child {
        td {
          &:not(:nth-child(1)) {
            &:not(:nth-child(2)) {
              &:not(:nth-child(3)) {
                &:not(:nth-child(4)) {
                  // border-top: solid 1px #eef0f3;
                }
              }
            }
          }
        }
      }

      &:last-child {
        td {
          &:not(:nth-child(1)) {
            &:not(:nth-child(2)) {
              &:not(:nth-child(3)) {
                &:not(:nth-child(4)) {
                  // border-bottom: solid 1px #eef0f3;
                }
              }
            }
          }
        }
      }

      &:nth-child(odd) {
        td {
          background: #f7f7f7;
        }
      }

      &:nth-child(even) {
        td {
          background: $clr_white;
        }
      }
    }
  }

  thead {
    tr {
      th {
        &.w80 {
          // min-width: 100px;
          white-space: normal;
          word-wrap: break-all;
        }

        &.company_name {
          // min-width: 150px;
          white-space: normal !important;
          word-wrap: break-all;
        }
      }
    }
  }
}

.pagenations {
  .page-link {
    padding: 0.3rem 0.45rem;
    color: #2da5c8;
    font-size: $font-size-12;
    cursor: pointer;
  }
}

.companies_search {
  @media only screen and (max-width: 992px) {
    margin-bottom: 15px;
  }

  input[type="text"] {
    height: 37px;
  }

  .input-group {
    &::-webkit-input-placeholder {
      color: #efefef;
      opacity: 0.75;
    }

    &:-ms-input-placeholder {
      color: #efefef;
      opacity: 0.75;
    }

    &::placeholder {
      color: #efefef;
      opacity: 0.75;
    }

    input::placeholder {
      color: $clr_grey;
      // opacity: 0.35 !important;
    }
  }
}

.watchlist_search {
  width: 100%;
  max-width: 400px;
  @include flexbox;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  @media only screen and (max-width: 992px) {
    justify-content: flex-end;
  }

  .input-group {
    width: auto;
    color: $clr_lightblue;

    input {
      width: 100%;
      max-width: 140px;
    }

    &::-webkit-input-placeholder {
      color: $clr_lightblue;
    }

    &:-ms-input-placeholder {
      color: $clr_lightblue;
    }

    &::placeholder {
      color: $clr_lightblue;
    }

    .btn-default {
      line-height: 13px;
      text-align: left;
    }
  }

  .manage_cog {
    width: auto;
    color: $clr_lightblue;
  }

  .btn-default {
    width: 150px;
    max-height: 30px;
    text-align: left;
  }

  button {
    border-radius: 0;
    color: $clr_white;
    background-color: #6c757d;
    outline: 0;
    border: solid 1px #ddd;
    // box-shadow: 0 0 4px #ddd;
  }

  .btn-active {
    background: $clr_white;
    color: $clr_lightblue;
    font-size: $font-size-12;
  }

  .btn {
    &:hover {
      color: $clr_white;
      //   background: $clr_white;
      //   color: $clr_lightblue;
      //   border-color: #6c757d;
    }
  }
}

.manage_cog {
  h6 {
    a {
      color: $clr_lightblue;
      margin: 0;
      @media only screen and (max-width: 480px) {
        text-align: center;
        display: block;
      }

      i {
        font-size: $font-size-18;
        padding-right: 3px;
      }
    }
  }
}

.sorttable {
  @include flexbox;
  justify-content: flex-end;
  align-items: center;

  a {
    padding-right: 15px;
    font-size: $font-size-18;
    color: $clr_lightblue;
  }

  span {
    padding-right: 15px;
    font-size: $font-size-18;
    color: $clr_lightblue;
    cursor: pointer;
  }
}

.company_sorting {
  position: relative;

  label {
    padding-right: 10px;
    color: $clr_grey;
  }

  select {
    padding: 6px 8px;
  }

  &:after {
    content: "";
    // height: calc(100% - 4px);
    // height: -webkit-calc(100% - 4px);
    // height: -moz-calc(100% - 4px);
    // height: -ms-calc(100% - 4px);
    // height: -o-calc(100% - 4px);

    @include calc(height, "100% - 4px");
    position: absolute;
    top: 0;
    right: 20px;
    border-left: solid 1px #ced4da;
    display: block;
  }
}

.pagination {
  .page-item.active {
    .page-link {
      background: #9ea2a2;
      color: $clr_white;
      border-color: #9ea2a2;
    }
  }
}

.dashboard_main {
  background: #f4f8fd;
}

.financial_data {
  width: 100%;
  display: block;
  padding: 0;
  margin: 0;

  .watchlist_search {
    max-width: 100%;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

.financial_data_left {
  width: 100%;
  display: block;
  padding: $font-size-18;
  box-shadow: 0 0 4px #f7f7f7;
  background: $clr_white;

  .companies_search {
    a {
      display: block;
      width: 100%;
      color: $clr_lightblue;
      font-size: 15px;
      font-weight: 500;
    }
  }
}

.dashboard_items {
  position: relative;
  padding-right: 0;
  @include flexbox;
  justify-content: flex-start;

  article {
    position: relative;
    padding-left: 30px;
    padding-bottom: 20px;
    padding-top: 20px;

    figure {
      + {
        .dashboard_details {
          padding-right: 150px;
        }
      }
    }

    .dashboard_details {
      padding-right: 30px;
    }
  }

  time {
    width: 100%;
    display: block;
    padding: 0;
    margin: 0;
    line-height: normal;
    text-align: center;
    position: relative;
    z-index: 1;
    right: 0;
    left: 30px;
    margin: 0 auto;

    small {
      background: $clr_white;
      padding: 0 20px;
      color: #ccc;
      margin: 0 auto;
      margin-right: 40px;
    }

    &:after {
      content: "";
      width: 100%;
      border-bottom: dashed 1px #cccccc;
      position: absolute;
      top: $font-size-12;
      left: 0;
      right: 0;
      margin: 0 auto;
      z-index: -1;
    }
  }
}

.quaters_list {
  width: 50px;
  flex: 0 0 8%;
  max-width: 8%;
  position: absolute;
  left: 0;
  //   height: calc(100% - 10px);
  @include calc(height, "100% - 10px");
  z-index: 1;
  overflow-y: auto;

  li {
    font-size: $font-size-12;
    color: #ccc;
    text-align: center;

    a {
      font-size: 12px;
      padding-bottom: 8px;
      display: block;
      color: $clr_lightblue;
      font-weight: 500;
    }

    &:last-child {
      border-bottom: solid 2px #c1bfbf;
    }
  }

  .mCSB_inside {
    > .mCSB_container {
      margin-right: 15px;
    }
  }
}

.dashboard_main_content {
  flex: 0 0 92%;
  max-width: 92%;
  overflow: hidden;
  padding-left: 40px;
}

.dashboard_content {
  &:nth-of-type(3n + 0) {
    &:after {
      display: none;
    }
  }

  &:after {
    content: "";
    // width: calc(100% - 30px);
    @include calc(width, "100% - 30px");
    height: 1px;
    background: #ccc;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  em {
    font-size: 10px;
    margin-bottom: 3px;
    display: block;
    color: #ccc;

    span {
      background: green;
      padding: 2px 5px;
      color: $clr_white;
      display: inline-block;
    }

    span.status_insight {
      background: #00aa5d;
    }

    span.status_financial {
      background: #03653e;
    }
  }

  figure {
    width: 90px;
    height: 70px;
    position: absolute;
    top: 40px;
    right: 40px;
    margin: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .three_dots {
    position: absolute;
    right: 0;
    font-size: 12px;
    top: 35px;
  }

  h5 {
    color: $clr_lightblue;
    text-transform: uppercase;
    margin: 0;
    font-size: 15px;
    padding-bottom: 3px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  p {
    text-align: justify;
    padding: 0;
    margin: 0;
    line-height: 17px;
    font-size: $font-size-12;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.webinors_slider {
  margin-bottom: 30px;

  figure {
    position: relative;
    padding-bottom: 42%;
    margin-bottom: 0px;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
    }
  }

  figcaption {
    width: 100%;
    max-width: 320px;
    position: absolute;
    left: 10px;
    top: 20px;
    line-height: 18px;
    color: $clr_white;
    font-size: 16px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: 500;
    @include transition(all 0.4s ease-in-out);
    transform: rotate(-45deg) scaleX(-1);
    -webkit-transform: rotate(-45deg) scaleX(-1);
    -moz-transform: rotate(-45deg) scaleX(-1);
    -ms-transform: rotate(-45deg) scaleX(-1);
    -o-transform: rotate(-45deg) scaleX(-1);
    opacity: 0;
  }

  ul {
    li.active {
      figcaption {
        transform: none;
        opacity: 1;
        @include transition(all 0.4s ease-in-out);
      }
    }
  }

  .carousel-indicators {
    li {
      width: 10px;
      height: 10px;
      border-radius: 100%;
      opacity: 1;
    }

    li.active {
      opacity: 0.5;
    }
  }
}

.mywatch_listbox {
  background: $clr_white;
  width: 100%;
  display: block;
  box-shadow: 0 0 4px #f7f7f7;
  padding: 30px 0;
}

.mylist_edit {
  ul {
    @include flexbox;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 0 25px;

    li {
      color: $clr_grey;
      font-size: 16px;
      font-weight: 600;

      &:first-child {
        flex: 0 0 40%;
        max-width: 40%;
      }

      &:not(:first-child) {
        flex: 0 0 60%;
        max-width: 60%;
        color: #ccc;
        font-size: $font-size-12;
        font-style: italic;
        padding-right: 20px;
        font-weight: normal;
      }

      &:last-child {
        position: absolute;
        right: 20px;
      }

      a {
        color: $clr_lightblue;
        font-weight: 500;
      }
    }
  }
}

.com_seg {
  @include flexbox;
  flex-wrap: wrap;
  justify-content: space-between;

  h6 {
    margin-bottom: 15px;
    font-weight: normal;
  }

  aside {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 30px;

    &:first-child {
      border-right: solid 1px #ddd;
    }
  }

  ul {
    li {
      a {
        font-size: 15px;
        font-weight: 500;
        padding-bottom: 10px;
        display: block;
        color: $clr_lightblue;
      }

      &:hover {
        a {
          color: $clr_darkblue;
        }
      }
    }
  }
}

.mywatch_listbox2 {
  .com_seg {
    aside {
      flex: 0 0 47%;
      max-width: 47%;
      padding: 25px;
      background: $clr_white;

      h6 {
        text-transform: uppercase;
      }

      &:first-child {
        border: 0;
      }
    }

    ul {
      li {
        a {
          font-size: 12px;

          span {
            font-size: 10px;
            color: #ccc;
          }
        }
      }
    }
  }
}

.nav_sidebar2 {
  // width: 10%;
  width: 100%;
  max-width: 240px;
  background: $clr_white;
  //   height: calc(100% - 50px);
  @include calc(height, "100% - 50px");
  position: fixed;
  top: 56px;
  left: 105px;
  overflow-y: auto;
  padding: 0 5px;
  padding-top: 40px;
  padding-bottom: 40px;
  border-right: solid 7px #e6ebf2;
  @include transition(all 0.8s ease-in-out);

  + {
    .main_content {
      width: 100%;
      padding-left: 400px;
      @media only screen and (max-width: 1199px) {
        padding-left: 400px;
      }
      @media only screen and (max-width: 991px) {
        padding-left: 280px;
      }
      @media only screen and (max-width: 767px) {
        width: 100%;
        padding-left: 0;
      }
    }
  }

  @media only screen and (max-width: 1199px) {
    // width: 20%;
    top: 50px;
    left: 92px;
    z-index: 1;
  }
  @media only screen and (max-width: 991px) {
    left: 15px;
  }
  @media only screen and (max-width: 767px) {
    left: -100%;
  }
}

.sidebar_list {
  > ul {
    margin-bottom: 15px;

    > li {
      &:before {
        content: "";
        // width: 5px;
        // height: 30px;
        // position: absolute;
        // left: -5px;
        // top: -10px;
        // background: $clr_grey;
        // opacity: 0;
        // transition: all 0.3s ease-in-out;
      }

      &:hover {
        &:before {
          top: 0px;
          opacity: 1;
        }
      }
    }

    > li.active {
      &:before {
        top: 0px;
        opacity: 1;
      }
    }
  }
}

.company_details {
  .checkbox_list {
    input[type="checkbox"] {
      display: none;

      &:checked {
        + {
          label {
            &:after {
              opacity: 1;
              height: 8px;
              content: "\2212";
              color: red;
            }
          }
        }
      }

      &:not(:checked) {
        + {
          label {
            &:after {
              opacity: 1;
              height: 8px;
              content: "\002B";
              color: #1286c6;
            }
          }
        }
      }
    }
  }
}

.company_details {
  .company_ttl {
    .draft {
      background-color: #007bff;
      border-color: #007bff;
      color: white;
    }

    .submit {
      background-color: #117a8b;
      border-color: #117a8b;
      color: white;
    }

    .re-submit {
      background-color: #007bff;
      border-color: #007bff;
      color: white;
    }

    .approve {
      background-color: #28a745;
      border-color: #28a745;
      color: white;
    }

    .reject {
      background-color: #dc3545;
      border-color: #dc3545;
      color: white;
    }
  }

  .news {
    // .tbr_position {
    //   background-color: #08417b;
    //   padding-top: 15px;
    //   padding-bottom: 15px;
    //   .sub_category_heading {
    //     border-color: white;
    //     .remove-news {
    //       i {
    //         font-size: $font-size-14;
    //       }
    //     }
    //   }
    //   h3 {
    //     color: white;
    //   }
    //   .red-text {
    //     color: white;
    //   }
    //   .desipte_sec h4 {
    //     background: none;
    //     color: white;
    //     text-transform: uppercase;
    //   }
    //   .manage_content_action,
    //   .content_section p {
    //     color: white;
    //   }
    // }
  }
}

.transaction_details {
  .company_ttl {
    .draft {
      background-color: #007bff;
      border-color: #007bff;
      color: white;
    }

    .submit {
      background-color: #117a8b;
      border-color: #117a8b;
      color: white;
    }

    .re-submit {
      background-color: #007bff;
      border-color: #007bff;
      color: white;
    }

    .approve {
      background-color: #28a745;
      border-color: #28a745;
      color: white;
    }

    .reject {
      background-color: #dc3545;
      border-color: #dc3545;
      color: white;
    }

    .publish {
      background-color: #004085;
      border-color: #004085;
      color: white;
    }
  }

  .checkbox_list {
    input[type="checkbox"] {
      display: none;

      &:checked {
        + {
          label {
            &:after {
              opacity: 1;
              height: 8px;
              content: "\2022";
              color: #1286c6;
            }
          }
        }
      }

      &:not(:checked) {
        + {
          label {
            &:after {
              opacity: 1;
              height: 8px;
              content: "\2022";
              color: #1286c6;
            }
          }
        }
      }
    }
  }
}

.checkbox_list {
  label {
    position: relative;
    padding-left: 25px;
    color: #1286c6;
    font-size: 12px;
    text-transform: capitalize;
    display: block;
    cursor: pointer;
    font-weight: 700;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // &:before {
    //   content: "";
    //   width: $font-size-12;
    //   height: $font-size-12;
    //   background: $clr_white;
    //   border: solid 1px #babfc6;
    //   display: block;
    //   position: absolute;
    //   left: 5px;
    //   top: 4px;
    // }
    &:after {
      display: block;
      width: 5px;
      height: 0px;
      // border: solid #babfc6;
      // border-width: 0 1.5px 1.5px 0;
      //   transform: rotate(45deg);
      position: absolute;
      top: 0px;
      left: 9px;
      opacity: 0;
      //   transition: height 0.3s ease-in-out;
      @include transition(height 0.5s ease-in-out);
      font-size: $font-size-14;
    }

    // &.selected {
    //    &:after {
    //     content: "\2212";
    //     opacity: 1;
    //         height: 8px;
    //    }
    // }
    // &.non-selected {
    //    &:after {
    //     content: "\002B";
    //     opacity: 1;
    //         height: 8px;
    //    }
    // }
  }

  li {
    ul {
      padding-left: 15px;
    }
  }

  > li {
    position: relative;
    @include transition(all 0.3s ease-in-out);
  }
}

// .company_grph {
//   margin-bottom: 10px;
// }
// .company_details {
//   > aside {
//     &:not(:last-child) {
//       border-right: solid 7px #e6ebf2;
//     }
//   }
// }
.dummy {
  background: red;
  width: 100%;
  height: 100%;
}

.list_style {
  display: block;
  margin: 0;
  padding: 0;
  list-style-type: decimal;
  padding-left: 20px;
  color: #d9dee5;

  li {
    width: 100%;
    display: block;
    margin-bottom: 10px;
    display: list-item;

    &:last-child {
      margin-bottom: 0px;
    }

    a {
      width: 100%;
      display: block;
      font-size: $font-size-12;
      color: #d9dee5;
    }
  }
}

.watch_follow_anchor {
  a {
    color: $clr_lightblue;
    font-weight: 600;
    font-size: $font-size-12;
  }
}

.author_sec {
  ul {
    li {
      width: 100%;
      position: relative;
      padding-left: 26px;
      line-height: 1;

      .icon {
        position: absolute;
        left: 0px;
        top: 7px;
        padding: 0;
        border-radius: 100%;
        color: $clr_grey;
        font-size: 22px;
        line-height: 22px;
        text-align: center;
      }

      em {
        font-style: normal;
        color: $clr_grey;
        font-size: $font-size-12;
        line-height: normal;
        display: block;
      }

      p {
        width: 100%;
        display: inline;
        // @include flexbox;
        // flex-wrap: wrap;
        // justify-content: space-between;
        color: $clr_lightblue;
        cursor: pointer;

        a {
          font-size: 14px;
          text-transform: capitalize;
          line-height: 16px;
          color: $clr_lightblue2;
        }

        margin: 0;

        &:hover {
          .copy-icon {
            visibility: visible;
          }
        }

        .copy-icon {
          margin-left: 3px;
          font-size: 14px;
          visibility: hidden;
          margin-right: 6px;
          vertical-align: bottom;
        }
      }
    }
  }
}

.add-author {
  margin: auto;
  border: 1px solid #2d94cd;
  padding: 3px;
  font-size: 11px;
}

.add-author-icon {
  color: #2d94cd;
  float: right;
  cursor: pointer;
}

.company_ttl {
  @media only screen and (max-width: 992px) {
    margin-top: 15px;
  }

  h2 {
    // margin-bottom: 15px;
    text-transform: uppercase;
  }

  p {
    font-size: 12px;

    strong {
      font-size: 15px;
    }

    color: #99a0aa;
  }
}

.follow_links {
  @include flexbox;
  flex-wrap: wrap;
  //   justify-content: space-around;
  li {
    text-align: center;
    border: solid 2px $clr_grey;
    margin-right: 10px;
    margin-bottom: 10px;

    span {
      text-transform: uppercase;
      font-size: 11px;
      display: block;
      padding: 3px 11px;
      color: $clr_grey;
      position: relative;

      &:after {
        content: "";
        width: 100%;
        height: 100%;
        background: $clr_grey;
        position: absolute;
        left: 0;
        top: 0;
        transform: skew(10deg, 10deg);
        opacity: 0;
        z-index: -1;
        // @include transition(all 0.5s ease-in-out);
      }
    }

    &:hover {
      span {
        &:after {
          opacity: 1;
          transform: none;
        }

        cursor: pointer;
        color: $clr_white;
      }
    }
  }

  li.active {
    span {
      &:after {
        opacity: 1;
        transform: none;
      }

      color: $clr_white;
    }
  }
}

.company_graph_box {
  width: 100%;
  height: 100%;
  background: none;
  -webkit-box-shadow: 0 0 4px #bbb6b6;
  -moz-box-shadow: 0 0 4px #bbb6b6;
  -ms-box-shadow: 0 0 4px #bbb6b6;
  -o-box-shadow: 0 0 4px #bbb6b6;
  box-shadow: 0 0 4px #bbb6b6;
  text-align: center;

  h6 {
    text-align: left;
    padding: 15px 0 0 15px;
  }
}

.radarChart {
  svg {
    width: auto;
    height: 200px;
  }

  display: inline-block;
  width: 100%;
}

.radar_bx {
  @include flexbox;
  flex-wrap: wrap;

  ul {
    display: block;
    align-self: center;
    width: 100%;
    margin-bottom: 15px;
    @include flexbox;
    justify-content: space-between;

    li {
      width: 100%;
      display: block;
      padding: 0 5px;

      a {
        width: 100%;
        display: block;
        font-size: 11px;
        color: $clr_white;
        padding: 3px 8px;
        margin-bottom: 3px;
        border-radius: 6px;
      }
    }
  }
}

.quarterly_final {
  width: 100%;
  height: 100%;
  display: block;
  -webkit-box-shadow: 0 0 4px #bbb6b6;
  -moz-box-shadow: 0 0 4px #bbb6b6;
  -ms-box-shadow: 0 0 4px #bbb6b6;
  -o-box-shadow: 0 0 4px #bbb6b6;
  box-shadow: 0 0 4px #bbb6b6;
  padding: 15px;

  h4 {
    display: block;
    width: 100%;
    float: left;
    margin-bottom: 15px;

    span {
      font-size: $font-size-12;
    }
  }

  table {
    tr {
      td {
        padding: 0 0 5px;

        &:first-child {
          width: 50%;
        }
      }
    }
  }
}

.tbr_position {
  width: 100%;
  // float: left;
  margin-bottom: 30px;

  .sub_category_heading {
    background-color: $clr_solitude2;
    padding: 7px 10px;
    border-radius: 3px;
    color: $clr_black2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;

    .heading {
      font-size: $font-size-18;
      font-weight: 600;
      color: $clr_black2;
      text-transform: capitalize;
    }

    span {
      // float: right;
      // line-height: 20px;
    }

    .sub_category_heading_title {
      font-size: 1.02rem;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  h3 {
    // text-transform: uppercase;
    // font-size: 16px;
    // span {
    //   font-size: 10px;
    // }
    font-weight: 600;
  }

  h4 {
    width: 100%;
    position: relative;
    margin-bottom: 10px;
    font-size: $font-size-14;
  }

  h5 {
    // text-transform: uppercase;
    font-weight: 600;
    color: $clr_black2;
  }

  h3,
  h5 {
    &.text_icon {
      // @include flexbox;
      align-content: center;
      align-items: center;
      margin-bottom: 15px;

      i {
        svg {
          width: 30px;
          height: 30px;
        }
      }

      p {
        margin: 0;
        font-weight: bolder;
      }

      img {
        width: 30px;
        height: 30px;
      }
    }
  }

  .list_style {
    list-style: none;
    padding-left: 0;

    li {
      background: #d9dee5;
    }
  }

  img.content_image {
    max-width: 100%;
    @media only screen and (max-width: 992px) {
      width: 100% !important;
    }
  }

  .row {
    // font-size: $font-size-12;
    margin-bottom: 20px;
  }
}

.desipte_sec {
  position: relative;

  h4 {
    color: $clr_lightblue2;
    border: none;
    font-weight: 600;
    font-size: $font-size-h4;
  }
}

.add_to_board {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);

  > a {
    width: 30px;
    text-align: center;
    height: 100%;
    font-size: 17px;
    color: $clr_black;
    float: right;
  }

  .open_Onboard {
    i {
      @include transition(all 0.5s ease-in-out);
    }
  }
}

.add_to_board.active {
  .open_Onboard {
    i {
      transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -o-transform: rotate(90deg);
    }
  }

  .add_to_board_box {
    left: 0;
    opacity: 1;
    visibility: visible;
    @include transition(all 0.3s ease-in-out);
    z-index: 1;
  }
}

.add_to_board_box {
  width: 130px;
  background: $clr_white;
  position: absolute;
  top: 100%;
  left: 100%;
  z-index: 1;
  padding: 10px 15px;
  box-shadow: 0 0 4px #ccc;
  opacity: 0;
  @include transition(all 0.3s ease-in-out);
  visibility: hidden;
  display: none;

  > a {
    display: block;
    width: 100%;
    margin-bottom: 2px;
    color: $clr_lightblue;
  }
}

.downloads_file {
  display: block;
  margin-top: 25px;

  p {
    display: block;
    margin: 0;
    padding: 0;
  }

  a {
    i.fa-file-pdf-o {
      color: red;
    }

    i.fa-file-excel-o {
      color: green;
    }

    i.fa-sticky-note {
      color: green;
    }

    margin: 0 5px;
    float: left;
  }
}

.position-graph {
  width: 100%;
  height: 100%;
}

#positionChart {
  height: 300px;
}

#chartdiv {
  width: 100%;
  height: 100%;
}

.amcharts-g2 {
  stroke-dasharray: 3px 3px;
  stroke-linejoin: round;
  stroke-linecap: round;
  -webkit-animation: am-moving-dashes 1s linear infinite;
  animation: am-moving-dashes 1s linear infinite;
}

.amcharts-graph-column-front {
  @include transition(all 0.3s ease-in-out);

  &:hover {
    fill: #496375;
    stroke: #496375;
    @include transition(all 0.3s ease-in-out);
  }
}

.amcharts-g3 {
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-dasharray: 500%;
  stroke-dasharray: 0;
  stroke-dashoffset: 0;
  -webkit-animation: am-draw 40s;
  animation: am-draw 40s;
}

html {
  height: 100%;
}

body {
  height: 100%;
}

.login_page {
  height: 100%;

  main {
    height: 100%;
  }

  > main {
    > .container-fluid {
      height: 100%;

      > .row {
        height: 100%;
      }
    }
  }
}

.login_left {
  width: 100%;
  height: 100%;

  figure {
    height: 100%;
    position: relative;
    padding-bottom: 0;
    margin-bottom: 0;

    img {
      width: 100%;
      height: 100%;
      position: static;
      top: 0;
      left: 0;
      object-fit: cover;
    }

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      background: rgba(255, 255, 255, 0.4);
      left: 0;
    }
  }

  a {
    position: absolute;
    top: 50px;
    left: 50px;
    display: block;
    text-align: center;

    span {
      display: block;
      color: #003366;
      text-transform: uppercase;
      font-size: 10px;
      font-family: $font-family;
      font-weight: 600;
    }
  }
}

.login_right {
  figure {
    height: 100%;
    position: relative;
    padding-bottom: 0;
    margin-bottom: 0;

    img {
      width: 100%;
      height: 100%;
      position: static;
      top: 0;
      left: 0;
      object-fit: cover;
    }
  }

  width: 100%;
  height: 100%;
  background: #0a2e53;
  text-align: center;

  form {
    width: 100%;
    max-width: 360px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);

    .login_text_span {
      color: var(--color-gray-10, #fff);
      text-align: center;
      font-family: "Source Sans Pro";
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 25px;
    }

    .invalid_email_div {
      display: flex;
      flex-direction: column;
      gap: 25px;
      align-items: center;

      .span_1 {
        color: var(--color-gray-10, #fff);
        text-align: center;
        font-family: "Source Sans Pro";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .span_2 {
        color: #cbcbcb;
        text-align: center;
        font-family: "Source Sans Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 114.286% */
        letter-spacing: 0.217px;
      }

      .btn_1 {
        width: 100%;
        height: 40px;
        border-radius: 6px;
        background: #007bff;
        color: var(--color-gray-10, #fff);
        font-family: "Source Sans Pro";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px; /* 100% */
      }

      .btn_1:focus {
        border: transparent !important;
        box-shadow: none !important;
        background-color: rgb(7, 84, 179);
      }

      .btn_2:focus {
        border: transparent !important;
        box-shadow: none !important;
      }

      .btn_2 {
        width: 100%;
        height: 40px;
        border-radius: 6px;
        background: var(--color-gray-10, #fff);
        color: #000;
        font-family: "Source Sans Pro";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px; /* 100% */
      }
    }

    .sso_div {
      display: flex;
      flex-direction: column;
      gap: 20px;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
      }

      span {
        color: var(--color-gray-10, #fff);
        text-align: center;
        font-family: "Source Sans Pro";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      img {
        border-radius: 100px;
        background: var(--color-gray-10, #fff);
        width: 44px;
        height: 44px;
        padding: 5px;
        cursor: pointer;
      }

      img:hover {
        background: #3f6596;
      }
    }

    a {
      &:hover {
        color: $clr_white;
      }

      img {
        width: 100%;
        max-width: 300px;
        display: block;
        margin: 0 auto 20px;
      }
    }

    label {
      display: none;
    }

    .form-group {
      margin-bottom: 50px;

      button {
        width: 100%;
        max-width: 150px;
        margin-bottom: 30px;
      }

      a {
        display: inline-block;
        font-size: 12px;
      }
    }
  }
}

// #tbr-logo {
//   margin-bottom: 10px;
//   height: 66px;
// }
.navbar-brand {
  #tbr-logo {
    width: 80px;
    height: 46px;
    transition: all 0.5s ease-in-out;
    fill: $clr_white;
    // margin: 16px 10px 20px;
    @media only screen and (min-width: 768px) {
      // width: 110px;
      height: 66px;
      // margin: 20px 10px 33px;
      // margin: 6px 10px 8px;
    }
  }
}

svg#tbr-logo {
  path {
    // fill: #003366;
    fill: $clr_darkblue;
  }

  rect {
    fill: $clr_green;
    // -webkit-animation: colorchange 5s infinite;
    // -moz-animation:    colorchange 5s infinite;
    // -o-animation:      colorchange 5s infinite;
    // animation:         colorchange 5s infinite;
  }

  @-webkit-keyframes colorchange {
    0% {
      fill: $clr_darkblue;
    }
    50% {
      fill: $clr_lightblue;
    }
    100% {
      fill: $clr_darkblue;
    }
  }
  @-moz-keyframes colorchange {
    0% {
      fill: $clr_darkblue;
    }
    50% {
      fill: $clr_lightblue;
    }
    100% {
      fill: $clr_darkblue;
    }
  }
  @-o-keyframes colorchange {
    0% {
      fill: $clr_darkblue;
    }
    50% {
      fill: $clr_lightblue;
    }
    100% {
      fill: $clr_darkblue;
    }
  }
  @keyframes colorchange {
    0% {
      fill: $clr_darkblue;
    }
    50% {
      fill: $clr_lightblue;
    }
    100% {
      fill: $clr_darkblue;
    }
  }
}

.login_formlogos {
  margin-bottom: 26px;
  display: block;

  .img-fluid {
    max-width: 80%;
  }
}

// @media only screen and (max-width: 1199px) {
//   h3 {
//     font-size: 16px;
//   }
// }
@media only screen and (max-width: 1199px) {
  //   .nav_sidebar {
  //     width: 20%;
  //     left: -100%;
  //     z-index: 100;
  //     top: 57px;
  //     @include transition(all 0.4s ease-in-out);
  //   }
  //   .open_nav {
  //     .nav_sidebar {
  //       left: 0;
  //     }
  //     .nav_sidebar2 {
  //       left: 20%;
  //       width: 20%;
  //     }
  //   }
  header {
    .navbar-brand {
      padding-left: 25px;
    }

    form {
      .form-control {
        height: 33px;
      }

      .btn-primary-success {
        height: 33px;
        padding: 0;
      }
    }
  }
  .menu_lines {
    display: block;

    span {
      @media only screen and (max-width: 767px) {
        width: 20px;
      }
    }
  }
  .main_content {
    width: 100%;
    // padding-left: 20px;
    // @media only screen and (max-width: 1199px) {
    //   padding-left: 0;
    // }
  }
  //   .nav_sidebar2 {
  //     width: 20%;
  //     left: 0%;
  //     z-index: 1;

  //   }
  .radarChart {
    display: inline-block;
    // width: calc(100% - 90px);
    @include calc(width, "100% - 90px");
  }
  .radar_bx {
    ul {
      width: 100%;
      max-width: 90px;
      display: block;
      align-self: center;
    }
  }
  #chartdiv {
    height: 310px;
  }
  .position-grapgh {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 992px) {
  .login_sec {
    ul {
      flex-direction: row;

      li {
        a {
          padding: 0 4px;
          font-size: 12px;
        }

        span {
          // padding: 0 4px;
          // font-size: 12px;
          color: $clr_grey;

          &.user-role {
            font-size: 11px;
          }
        }
      }
    }
  }
  .main_content {
    width: 100%;
    // padding-left: 0px;
    padding-top: 75px;
  }
  .company_details {
    > aside {
      flex: 0 0 100%;
      // max-width: 100%;
    }
  }
}

@media only screen and (max-width: 767px) {
  nav {
    form.form-inline {
      position: absolute;
      top: 100%;
      right: 0;
      opacity: 0;
      visibility: hidden;
      @include transition(all 0.4s ease-in-out);
    }
  }
  nav.open_search {
    form.form-inline {
      opacity: 1;
      visibility: visible;
      top: 40px;
    }
  }
  .watchlist_search {
    .input-group {
      margin-bottom: 15px;
      width: 100%;
      justify-content: center;
      @media only screen and (max-width: 767px) {
        justify-content: flex-end;
      }
      @media only screen and (max-width: 480px) {
        justify-content: center;
      }
    }

    .manage_cog {
      width: 100%;
      justify-content: center;
    }
  }
  .company_sorting {
    label {
      font-size: $font-size-12;
    }

    .form-control {
      width: 52px;
      padding: 0.375em 0;
    }
  }
  .sorttable {
    a {
      padding-right: 5px;
      font-size: 16px;
    }

    span {
      @media only screen and (max-width: 992px) {
        padding-right: 15px;
      }
    }
  }
  .hide-in-mobille {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  // .nav_sidebar {
  //   width: 45%;
  // }
  // .navbar-brand {
  //   width: 110px;
  // }
  .login_sec {
    ul {
      li {
        a {
          i {
            font-size: 18px;
          }
        }

        ul {
          width: 240px;
          right: -60px;
        }

        span {
          i {
            font-size: 18px;
          }

          cursor: pointer;
        }
      }
    }
  }
  .comapnay_tbl {
    .col-6 {
      width: 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .com_seg {
    aside {
      flex: 0 0 100%;
      max-width: 100%;
      border-right: 0 !important;
    }
  }
  .mywatch_listbox2 {
    .com_seg {
      aside {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 15px;
      }
    }
  }
  .login_right {
    .login_formlogos {
      margin: 0 auto 50px;
    }

    form {
      max-width: 280px;

      .form-group {
        margin-bottom: 30px;
      }
    }
  }
}

// @media only screen and (min-width:1500px) {
// 	.main_content {
// 		.col-sm-offset-right-1 {
// 			// padding-right: 8%;
// 		}
// 	}
// }
@media only screen and (max-width: 576px) {
  .company_segment_row {
    aside.d-sm-none {
      display: none;
    }
  }
  .left_sec {
    margin-top: 0;
    height: auto;
  }
  .financial_data_left {
    h1 {
      font-size: 16px;
    }
  }
  .companies_search {
    margin-bottom: 20px;
  }
  .financial_data {
    .watchlist_search {
      justify-content: center;
      align-items: center;
    }
  }
  .dashboard_content {
    figure {
      width: auto;
      height: auto;
      position: static;
      margin-bottom: 5px;
    }

    .three_dots {
      top: 15px;
    }

    &:after {
      width: calc(100% - 20px);
      @include calc(width, "100% - 20px");
    }
  }
  .dashboard_items {
    article {
      padding-right: 0;
      padding-left: 20px;

      figure {
        + {
          .dashboard_details {
            padding-right: 0;
          }
        }
      }

      .dashboard_details {
        padding-right: 0;
      }
    }

    time {
      left: 20px;
    }
  }
  .quaters_list {
    flex: 0 0 12%;
    max-width: 12%;

    .mCSB_inside {
      > .mCSB_container {
        margin-right: 0;
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .mylist_edit {
    ul {
      li {
        font-size: 12px;

        &:not(:first-child) {
          font-size: 12px;
          flex: 0 0 100%;
          max-width: 100%;
        }

        a {
          font-size: 12px;
        }
      }
    }
  }
  .com_seg {
    ul {
      li {
        a {
          font-size: 12px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .company_details {
    .col-11 {
      flex: 90%;
      max-width: 90%;
    }

    .col-1 {
      flex: 10%;
      max-width: 10%;
    }
  }
  .company_grph {
    aside {
      &:nth-child(2) {
        padding: 0;
      }
    }
  }
}

@media only screen and (max-width: 1399px) {
  .company_grph {
    aside {
      // flex: 0 0 50%;
      // max-width: 50%;
      margin-bottom: 30px;

      &:first-child {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 30px;
      }
    }
  }
  .follow_links {
    width: 100%;
    max-width: 600px;
  }
}

@media only screen and (min-width: 1600px) {
  .radarChart {
    svg {
      height: 130px;
    }

    display: inline-block;
    // width: calc(100% - 70px);
    @include calc(width, "100% - 70px");
  }
  .radar_bx {
    ul {
      width: 100%;
      max-width: 70px;
      display: block;
      align-self: center;
    }
  }
}

#root {
  height: 100%;
}

.App {
  height: 100%;
}

@media only screen and (min-width: 768px) {
  .bar1 {
    width: 22px;
  }
  .bar2 {
    width: 22px;
  }
  .bar3 {
    width: 22px;
  }
  .company_sorting label {
    padding-right: 5px;
    font-size: $font-size-12;
  }
  .company_sorting {
    .form-control {
      height: 30px;
    }
  }
  .comapnay_tbl h1 {
    padding-top: 15px;
  }
  .companies_search {
    .form-control {
      font-size: $font-size-12;
    }

    input[type="text"] {
      height: 30px;
    }

    .input-group {
      &::-webkit-input-placeholder {
        color: #efefef;
      }

      &:-ms-input-placeholder {
        color: #efefef;
      }

      &::placeholder {
        color: #efefef;
        font-size: 12px;
      }
    }

    .btn-primary {
      height: 30px;
      line-height: 0.5;
    }
  }
}

@media only screen and (max-width: 767px) {
  // .nav_sidebar {
  //   top: 57px;
  // }
}

.disabled .page-link {
  color: grey;
  pointer-events: none;
}

.page-item.disabled {
  opacity: 0.55;
}

.page-item.disabled:hover {
  cursor: not-allowed;
}

// .dataTable th {
//   cursor: pointer;
// }

.dataTable thead {
  position: relative;
  z-index: 99;
}

.dataTable .no-records {
  text-align: center !important;
  color: $clr_grey !important;
}

.loading-indicator:before {
  content: "";
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 0.5;
}

.loading-indicator:after {
  content: "";
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1200;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border-top: 8px solid rgba(155, 224, 240, 0.2);
  border-right: 8px solid rgba(155, 224, 240, 0.2);
  border-bottom: 8px solid rgba(155, 224, 240, 0.2);
  border-left: 8px solid $clr_lightblue;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

.hidden-download {
  display: none;
}

input::placeholder {
  color: $clr_grey;
  // opacity: 0.35 !important;
}

.disable {
  color: $clr_grey !important;
  cursor: not-allowed !important;
}

.company_desc {
  color: #212529;
}

.companyDetailsTable {
  width: 100%;

  th,
  td {
    border: 1px solid #ccc;
    padding: 3px;
  }

  th {
    text-transform: uppercase;
  }
}

.stock_ticker {
  font-size: $font-size-14;
}

.company_name .nav-link label {
  cursor: pointer;
}

.green {
  color: green;
}

.growth {
  font-weight: 600;
}

.growth_indicator {
  margin-left: 3px;
}

.taxonomy-page-block {
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px);
}

.taxonomy_page {
  height: calc(100% - 50px);

  > .d-flex {
    height: 100%;
  }

  table {
    margin: 0;
    min-width: auto !important;

    &.dx-g-bs4-table-sticky {
      z-index: 6;

      .oi-arrow-thick-bottom:before {
        font-family: "Material Icons";
        content: "\e5cf";
        font-size: 20px;
      }

      .oi-arrow-thick-top:before {
        font-family: "Material Icons";
        content: "\e5ce";
        font-size: 20px;
      }
    }

    colgroup {
      col:nth-child(1) {
        width: 40% !important;
      }

      col:nth-child(2) {
        width: 60% !important;
      }
    }
  }

  .table-responsive {
    background: #ffffff 0% 0% no-repeat padding-box;

    border-radius: 4px;

    .oi-chevron-right:before {
      font-family: "Material Icons Round";
      content: "\e5cc";
      font-size: 20px;
    }

    .oi-chevron-bottom:before {
      font-family: "Material Icons Round";
      content: "\e5cf";
      font-size: 20px;
    }

    tbody {
      tr {
        td {
          font-size: 14px;
          border: none;
          position: relative;

          &:first-child {
            padding: 0.5rem 0.75rem;

            .d-inline-block ~ .dx-g-bs4-table-tree-content {
              overflow: visible;
            }
          }

          &:first-child {
            border-right: 0.5px solid $clr_tableborder;
            color: $clr_lightgrey2;
          }

          &:nth-child(2) {
            padding: 13px 16px 11px;
            color: $clr_black2;
          }

          .dx-g-bs4-toggle-button {
            order: 2;
            position: absolute;
            margin-right: 0px !important;
            right: 18px;
            top: 15px;
            z-index: 4;
          }

          .dx-g-bs4-table-tree-content {
            order: 1;
            padding: 6px 40px 6px 6px;
            z-index: 3;
            position: relative;
            overflow: visible;
          }

          &.cell-expanded {
            .dx-g-bs4-table-tree-content {
              order: 1;
              padding: 6px 40px 6px 6px;
              // background-color: $clr_alice_blue2;
              background-color: #e4eef5;
              border-radius: 4px;
              color: $clr_darkblue5;
              z-index: 3;
              position: relative;
            }
          }

          .oi-chevron-bottom:before {
            color: $clr_darkblue5;
          }

          span {
            width: 0px;
            height: 10px;
            z-index: 2;

            &::before {
              position: absolute;
              content: "";
              width: 1px;
              height: 100%;
              background: $clr_tableborder;
              left: 32px;
              bottom: 0px;
              z-index: -1;
            }

            &:first-of-type {
              &::before {
                left: 20px;
              }
            }

            &:nth-of-type(2) {
              &::before {
                left: 44px;
              }
            }

            &:nth-of-type(3) {
              &::before {
                left: 68px;
              }
            }
          }
        }
      }
    }
  }

  .card-header {
    border: none !important;
    background: none !important;
    padding: 0 !important;
    min-height: auto;
    margin-bottom: 15px;
    position: absolute !important;
    top: 0px;
    right: 63px;

    input {
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid $clr_lightgrey_new;
      border-radius: 5px;
      padding: 6px 48px 6px 14px;
      height: 35px;
      font-size: 0.79rem;
      position: relative;
      z-index: 15;
      width: 266px !important;
    }

    .d-flex {
      display: none !important;
    }

    &::after {
      position: absolute;
      left: 224px;
      top: 0;
      margin: 4px 0px;
      height: 27px;
      line-height: 27px;
      font-family: "Material Icons";
      content: "\e8b6";
      color: $clr_lightblue2;
      padding-left: 8px;
      font-size: 21px;
      border-left: 1px solid $clr_border;
      z-index: 11;
      cursor: pointer;
    }
  }

  thead {
    tr {
      th {
        background-color: $clr_lightblue3;
        color: $clr_white;
        font-size: 14px;
        font-weight: 600;
        border: none;
        padding: 14px 16px;

        .dx-g-bs4-toggle-button-hidden {
          display: none;
        }

        .text-primary {
          color: $clr_white !important;
        }

        &:first-child {
          border-top-left-radius: 3.5px;
        }

        &:nth-child(2) {
          border-top-right-radius: 3.5px;
        }
      }

      .input-group {
        .form-control {
          font-size: $font-size-12;
        }
      }
    }

    tr:nth-child(2) {
      th:nth-child(2) {
        .input-group {
          display: none;
        }
      }
    }
  }

  .btn-outline-secondary {
    background-color: #1085c6;
    border: none;
    color: white;
  }

  .list-table {
    tr:nth-of-type(odd) {
      background-color: #f9f9f9;
    }
  }
}

.card-header {
  border: none !important;
  background: none !important;

  .form-control {
    font-size: $font-size-12;
  }

  .d-flex {
    display: none !important;
  }
}

thead {
  tr {
    th {
      color: #99a0aa;
      font-size: $font-size-12;
      font-weight: 700;
      border: none;

      .dx-g-bs4-toggle-button-hidden {
        display: none;
      }

      .text-primary {
        color: #99a0aa !important;
      }
    }

    .input-group {
      .form-control {
        font-size: $font-size-12;
      }
    }
  }

  tr:nth-child(2) {
    th:nth-child(2) {
      .input-group {
        display: none;
      }
    }
  }
}

tbody {
  td {
    border: none;
  }

  tr:nth-of-type(odd) {
    background: transparent;
  }
}

.btn-outline-secondary {
  background-color: #1085c6;
  border: none;
  color: white;
}

.dx-g-bs4-filter-selector-item.active {
  background-color: #1085c6;
}

.dx-g-bs4-filter-selector-item {
  .dx-g-bs4-filter-selector-icon {
    background-color: #1085c6;
    color: white;
  }
}

.blue-text {
  color: $tbr_color;
}

.red-text {
  color: red;
}

p {
  color: $clr_black;
}

.upload-buttons {
  margin-top: 10px;
  text-align: center;

  button {
    margin-left: 10px;
    font-size: $font-size-12;
  }
}

.content-save-btn {
  font-size: $font-size-12;
  float: right;
}

.modal-xl {
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 740px;
  }
  @media (min-width: 992px) {
    max-width: 940px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
}

.modal-w-header {
  .modal-content {
    border: none;
  }

  .modal-header {
    background-color: #1085c6;

    .modal-title {
      text-transform: uppercase;
      color: white;
    }
  }
}

.modal-confirm {
  color: #636363;
  // width: 400px;
  .modal-content {
    padding: 20px;
    border-radius: 5px;
    border: none;
    text-align: center;
    font-size: $font-size-14;
  }

  .modal-header {
    border-bottom: none;
    position: relative;
  }

  h5 {
    text-align: center;
    font-size: 26px;
    margin: 30px 0 -10px;
    width: 100%;
    color: black;
  }

  .close {
    position: absolute;
    top: -5px;
    right: -2px;
  }

  .modal-body {
    color: #000;
  }

  .modal-footer {
    border: none;
    text-align: center;
    border-radius: 5px;
    font-size: 13px;
    padding: 10px 15px 25px;
    display: block;

    a {
      color: #000;
    }
  }

  .icon-box {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    border-radius: 50%;
    z-index: 9;
    text-align: center;
    border: 3px solid #f15e5e;

    i {
      color: #f15e5e;
      font-size: 46px;
      display: inline-block;
      margin-top: 13px;
    }

    &.danger {
      border-color: #ee3535;

      i {
        color: #ee3535;
      }
    }

    &.warning {
      border-color: orange;

      i {
        color: orange;
      }
    }

    &.success {
      border-color: green;

      i {
        color: green;
      }
    }
  }

  .btn-info {
    background: #c1c1c1;

    &:hover {
      background: #a8a8a8;
    }

    &:focus {
      background: #a8a8a8;
    }
  }

  .btn-danger {
    background: #f15e5e;

    &:hover {
      background: #ee3535;
    }

    &:focus {
      background: #ee3535;
    }
  }
}

.danger-modal {
  .modal-right-btn {
    background: $clr_red2;
    border-color: $clr_red2;

    &:hover {
      background: $clr_red3;
      border-color: $clr_red3;
    }

    &:focus,
    &:not(:disabled):not(.disabled):active {
      background: $clr_red3;
      border-color: $clr_red3;
      box-shadow: 0 0 0 0.2rem rgba(255, 49, 43, 0.5);
    }
  }
}

.ssosaml-modal-size {
  max-width: 900px !important;
  max-height: 600px !important;
  margin-top: 50px !important;

  .modal-right-btn {
    background: $clr_blue;
    border-color: $clr_blue;
  }

  .ssosaml-modal-label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 600;
    font-size: 12px;

    p {
      font-family: "Source Sans Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12.64px;
      color: #555555;
    }
  }
}

.success-modal {
  .modal-right-btn {
    background: $clr_green;
    border-color: $clr_green;

    &:hover {
      background: $clr_darkgreen;
      border-color: $clr_darkgreen;
    }

    &:focus,
    &:not(:disabled):not(.disabled):active {
      background: $clr_darkgreen;
      border-color: $clr_darkgreen;
      box-shadow: 0 0 0 0.2rem rgba(38, 81, 40, 0.5);
    }
  }
}

.noaccess-modal {
  .modal-right-btn {
    background-color: $clr_grey3;
    border-color: $clr_grey3;
    color: $clr_white;

    &:hover {
      background-color: $clr_darkgrey;
      border-color: $clr_darkgrey;
    }

    &:focus,
    &:not(:disabled):not(.disabled):active {
      background-color: $clr_darkgrey;
      border-color: $clr_darkgrey;
      box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5);
    }
  }
}

.inactive-modal {
  .modal-right-btn {
    background: #87702f;
    border-color: #87702f;
    color: #ffffff;

    &:hover {
      background: #695828;
      border-color: #695828;
    }

    &:focus,
    &:not(:disabled):not(.disabled):active {
      background: #695828;
      border-color: #695828;
      box-shadow: 0 0 0 0.2rem rgba(105, 88, 40, 0.5);
    }
  }
}

.primary-modal {
  .modal-right-btn {
    background: $clr_lightblue2;
    border-color: $clr_lightblue2;
    color: $clr_white;

    &:hover {
      background: $clr_darkblue5;
      border-color: $clr_darkblue5;
      color: $clr_white;
    }

    &:focus {
      background: $clr_darkblue5;
      border-color: $clr_darkblue5;
      color: $clr_white;
    }
  }

  .modal-footer {
    justify-content: flex-end;
  }
}

.manage_content_action {
  cursor: pointer;
  .add-edit-btn {
    margin-top: 10px;
  }
}

.content_deleted {
  opacity: 0.8;
  pointer-events: none;
  text-decoration: line-through;

  p,
  div {
    opacity: 0.8;
  }
}

.company_details {
  table {
    td,
    th {
      color: unset;
      font-size: unset;
    }
  }

  .content_section {
    ul {
      padding-inline-start: 40px;
      list-style: initial;
    }

    h1 {
      color: unset;
      font-weight: initial;
    }

    h2 {
      font-size: 2em;
      color: black;
    }

    h3 {
      text-transform: none;
      font-size: 1.17em;
      color: black;
    }

    h4 {
      border: none;
      background: none;
      width: auto;
      color: black;
      padding: 0;
      font-size: unset;
      font-weight: bold;
      margin: inherit;
    }

    h5 {
      color: black;
      font-size: 1em;
      text-transform: none;
    }

    h6 {
      font-size: 0.83em;
      color: black;
    }

    overflow: auto;

    &.template-content-section {
      padding: 0px 5px;
    }

    .row {
      // margin: 0 !important;
      margin-right: -5px;
      margin-left: -5px;
      // padding: 0 !important;
    }

    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12,
    .col,
    .col-auto,
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm,
    .col-sm-auto,
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md,
    .col-md-auto,
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg,
    .col-lg-auto,
    .col-xl-1,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9,
    .col-xl-10,
    .col-xl-11,
    .col-xl-12,
    .col-xl,
    .col-xl-auto {
      // margin: 0 !important;
      // padding: 0 !important;
      padding-right: 5px;
      padding-left: 5px;
    }

    p {
      padding: 0 3px 0 3px;
    }
  }
}

.text-align-right {
  text-align: right;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.comment-widget {
  margin-top: 10px;

  .comment-wrapper {
    font-size: $font-size-12;

    .panel-info {
      border-color: #bce8f1;
    }

    .panel {
      margin-bottom: 16px;
      background-color: #fff;
      border: 1px solid transparent;
      border-radius: 4px;
      box-shadow: none;
      border-color: #bce8f1;
    }

    .panel-info > .panel-heading {
      color: #31708f;
      background-color: #d9edf7;
      border-color: #bce8f1;
    }

    .panel-heading {
      padding: 10px 15px;
      // border-bottom: 1px solid transparent;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      cursor: pointer;
    }

    .collapse {
      background-color: #ffffff;
      box-shadow: none;
      border: 0;
    }

    .panel-body {
      max-height: 650px;
      overflow: auto;
      padding: 8px;

      .media-list {
        .media {
          a {
            padding-right: 10px;
          }
        }
      }

      textarea {
        font-size: 14px;
        border: 0;
        background-color: #f3f3f3;
        font-style: italic;
        color: $clr_lightgrey2;
      }
    }

    .media-list .media img {
      width: 50px;
      height: 50px;
      border: 2px solid #e5e7e8;
    }

    .media-list .media {
      border-bottom: 1px dashed #efefef;
      margin-bottom: 10px;
      padding-bottom: 10px;
    }

    .img-circle {
      border-radius: 50%;
    }
  }
}

.transaction_details .comment-widget {
  margin-top: 0px;
}

.dynamicTableActionButton:nth-child(n + 2) {
  margin-left: 3px;
}

form.form-wrapper {
  margin: auto;

  label {
    color: $clr_lightgrey2;
    font-size: $font-size-14;

    .mandatory {
      color: #bd2130;
    }
  }

  .form-control {
    font-size: $font-size-12;
  }

  .valid-tooltip,
  .invalid-tooltip {
    font-size: $font-size-12;
    float: right;
    position: relative;
    top: unset;
  }

  // .btn {
  //   font-size: $font-size-12;
  // }
  .rw-widget {
    font-size: $font-size-12;

    .rw-widget-input,
    .rw-filter-input {
      box-shadow: none;
    }

    .rw-widget-container {
      border: 1px solid #ced4da !important;
    }
  }

  .card-header {
    padding: 0.45rem 1.25rem;
    font-size: $font-size-14;
  }

  .segment-tag {
    padding: 3px;
    margin: 3px;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 3px;
    display: inline-flex;

    i {
      margin-left: 5px;
      cursor: pointer;
      line-height: $font-size-18;
    }
  }
}

.uploaded_Status_table {
  max-width: 80vw !important;
}

.manage-client {
  @media only screen and (min-width: 768px) {
    h1 {
      padding-top: 15px;
    }
  }

  h1 {
    margin-bottom: 20px;
  }

  .selectError {
    border: 1px solid $clr_red4;

    &::after {
      color: $clr_red4;
    }
  }

  .table {
    margin-bottom: 0;
  }

  .table thead th {
    &:nth-child(1) {
      width: 25%;
    }

    &:nth-child(2) {
      width: 15%;
    }

    &:nth-child(3) {
      width: 55%;
    }

    &:nth-child(4) {
      width: 5%;
    }
  }

  .empty-segments {
    font-size: $font-size-12;
    color: #fff;
    background-color: rgba(220, 53, 69, 0.9);
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    float: right;
    position: relative;
  }
}

.alert {
  font-size: $font-size-12;
  padding: 0.55rem 1.15rem;
}

.datagrid_tooltip {
  i {
    color: #99a0aa !important;
    font-size: $font-size-14 !important;
    cursor: pointer;
  }

  .icon {
    color: #99a0aa !important;
    font-size: 16px !important;
    cursor: pointer;
    margin-left: 4px;
  }
}

// .mydraftooltip{
//   color: red;
// }

.clients_dropdown {
  font-size: $font-size-12;

  .rw-widget-picker,
  .rw-widget-input {
    background-color: #fff;
    // box-shadow: 0px 0px 12px #0b254014;
    border: 0 !important;
  }

  .rw-select-bordered {
    border: 0 !important;
    background-color: #fff;

    .rw-i-calendar {
      color: $clr_lightblue2;
    }
  }

  .rw-select-bordered:hover {
    background-color: #fff;
  }

  .rw-widget-picker {
    border: 1px solid #ced4da !important;
    border-radius: 5px !important;
  }

  .rw-calendar.rw-popup {
    width: 200px;
  }

  .rw-calendar-grid {
    height: 10.28571429em;
    width: 14em;
  }

  .custom_dates {
    width: 130px;
    display: inline-block;
    font-size: 14px;
    border: none;

    &:first-child {
      margin-right: 30px;
    }
  }
}

.project_details {
  .checkbox_list {
    .fa-plus-circle {
      color: #1286c6;
      margin-right: 10px;
      font-size: $font-size-14;
    }

    .fa-minus-circle {
      color: red;
      margin-right: 10px;
      font-size: $font-size-14;
    }

    .fa-pencil {
      margin-left: 10px;
      color: rgba(0, 0, 0, 0.418);
    }

    label {
      padding-left: 5px;
    }

    .fa-circle {
      margin-right: 10px;
      font-size: $font-size-12;
    }
  }

  .nav_sidebar2 {
    max-width: 250px;
  }

  .nav_sidebar2 + .main_content {
    padding-left: 450px;
  }

  @media only screen and (max-width: 1199px) {
    .nav_sidebar2 + .main_content {
      padding-left: 250px;
    }
  }

  .open_nav {
  }

  .add_category {
    // background: none;
    // color: #1085c6;
    // font-weight: bold;
    // border-width: 1px;
    // padding-top: 0.175rem;
    // padding-bottom: 0.175rem;
    // margin-bottom: 5px;
    // i.add {
    //   float: left;
    //   line-height: 18px;
    // }
  }

  span.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 140px;
    display: inline-block;
    // line-height: 15px;
    vertical-align: top;
  }

  .sub_category_heading {
    .ellipsis {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

// .template {
//   min-height: 30px;
//   border: 1px solid #ccc;
//   margin: 5px;
//   div {
//     // border: 1px solid #ccc;
//     // padding: 0;
//     // margin: 0;
//   }
//   span {
//     // height: 10px;
//   }
//   i {
//     font-size: 8px;
//   }
// }

.template-picker-modal {
  .content_section {
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 3px;

    h1 {
      color: unset;
      font-weight: initial;
    }

    h2 {
      font-size: 2em;
      color: black;
    }

    h3 {
      text-transform: none;
      font-size: 1.17em;
      color: black;
    }

    h4 {
      border: none;
      background: none;
      width: auto;
      color: black;
      padding: 0;
      font-size: unset;
      font-weight: bold;
      margin: inherit;
    }

    h5 {
      color: black;
      font-size: 0.83em;
      text-transform: none;
    }

    h6 {
      font-size: 0.67em;
      color: black;
    }

    overflow: auto;

    .row {
      margin: 0 !important;
      padding: 0 !important;
    }

    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12,
    .col,
    .col-auto,
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm,
    .col-sm-auto,
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md,
    .col-md-auto,
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg,
    .col-lg-auto,
    .col-xl-1,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9,
    .col-xl-10,
    .col-xl-11,
    .col-xl-12,
    .col-xl,
    .col-xl-auto {
      margin: 0 !important;
      padding: 0 !important;
    }

    p {
      padding: 0 3px 0 3px;
    }
  }
}

.template-picker-chooser-btn {
  border: 1px dashed #ccc;
  padding: 5px 0px;
  cursor: pointer;
  color: gray;
  text-align: center;
}

.rw-dropdown-list-input,
.rw-list-option {
  text-transform: capitalize;
  font-weight: normal;
}

.breadcrumb {
  background-color: #1085c6;
  color: white;
  padding: 0.5rem 0.5rem;

  li {
    &.breadcrumb-item {
      color: white;
      font-size: 0.75rem;

      a {
        color: white;

        &.nav-link {
          padding: 0;
          display: inline;
        }
      }

      // &::before {
      //   color: white;
      // }
      &.active {
        // font-size: 0.75rem;
      }
    }

    // &.active {
    //   font-weight: bold;
    // }
  }
}

.tbri-custom-breadcumb {
  box-shadow: none;

  .breadcrumb {
    background: none;
    color: white;
    padding: 0.5rem 0.5rem;

    li {
      &.breadcrumb-item {
        color: #052240;
        font-size: 0.75rem;

        a {
          color: #052240;
          text-transform: capitalize;

          &.nav-link {
            padding: 0;
            display: inline;
          }
        }

        &::before {
          color: #052240;
        }

        &.active {
          // font-size: 0.75rem;
        }
      }

      &.active {
        font-weight: bold;
        text-transform: capitalize;
      }
    }
  }
}

.nav-link {
  label {
    cursor: pointer;
  }
}

.Toastify {
  .Toastify__toast {
    font-family: $font-family;
    font-size: $font-size-12;
    font-weight: bold;
    min-height: 55px;
    position: absolute;
    bottom: 20px;
    width: 300px;
  }
}

.user-permissions {
  .user-permissions-module {
    h2,
    h3 {
      text-transform: capitalize;
      // color: #07407a;
      border-bottom: 1px solid #bed4eb;
      // font-weight: 500;
      padding: 5px;
      margin-top: 0;
      margin-bottom: 5px;
    }

    h2 {
      font-size: $font-size-h4;
    }

    h3 {
      font-size: 0.96rem;
    }

    .form-check-input {
      margin-left: 0;
    }

    .form-check-label {
      text-transform: capitalize;
      margin-left: 1.125rem;
    }
  }
}

.user-permissions-card {
  box-shadow: none;
  border: 0.5px solid #bed4eb;
  border-radius: 5px;
  // box-shadow: 0px 0px 15px #0b254024;
}

.__react_component_tooltip.show {
  z-index: 9;

  &.place-top,
  &.place-left,
  &.place-right {
    opacity: 1;
    // background-color: $clr_blue_tooltip;

    &::before {
      background-color: transparent;
      width: 0;
      height: 0;
    }

    &::after {
      background-color: transparent;
    }
  }

  &.place-left {
    &::before {
      right: -8px;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid #222222;
    }
  }

  &.place-right {
    &::before {
      left: -8px;
      margin-top: -10px;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid #222222;
    }
  }

  &.place-top {
    &::before {
      bottom: -8px;
      margin-left: -10px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #222222;
    }
  }
}

.scroll-to-top {
  position: fixed;
  bottom: 45px;
  right: 15px;
  animation: scroll-to-top-fadeIn 700ms ease-in-out 1s both;
  cursor: pointer;
  z-index: 106;

  &:hover {
    opacity: 1;
  }
}

@keyframes scroll-to-top-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.8;
  }
}

.remove-document {
  color: #dc3545;
  cursor: pointer;
  font-size: $font-size-12;
}

.document-name {
  font-size: $font-size-12;
}

.tooltip-custom-theme {
  padding: 5px !important;
  font-size: $font-size-12 !important;
  font-family: $font-family !important;
  line-height: 16px !important;
  max-width: 500px !important;
  white-space: pre-wrap !important;
  z-index: 11;

  .multi-line {
    padding: 0 !important;
  }
}

.comapany_table tbody tr td.position-inherit-important {
  position: inherit !important;
}

.error-msg {
  .empty-files {
    font-size: $font-size-12;
    color: #fff;
    background-color: rgba(220, 53, 69, 0.9);
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    float: right;
    position: relative;
  }
}

.list_metric_dialog {
  .error-msg {
    text-align: center;

    .empty-files {
      float: none;
      display: inline-block;
    }
  }
}

// .empty-files {
//   font-size: $font-size-12;
//   color: rgba(220, 53, 69, 0.9);
//   text-align: center;
// }

.file-restriction-message {
  font-size: $font-size-11;
  font-style: italic;
}

label.category-label {
  padding-right: 5px;

  .fa-pencil {
    visibility: hidden;
    float: right;
    line-height: 1rem;
    margin-left: 0 !important;
  }

  .fa-bars {
    float: right;
    line-height: 1rem;
    visibility: hidden;
    color: rgba(0, 0, 0, 0.418);
    margin-left: 10px;
  }

  &:hover {
    .fa-pencil {
      visibility: visible;
    }

    .fa-bars {
      visibility: visible;
    }
  }

  span.ellipsis {
    max-width: 130px;
    margin-right: 10px;
    cursor: auto;
  }
}

.drag-li {
  // box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.13);
  // border: 1px solid #1286c6;
  border-radius: 3px;
  padding: 2px 0px;
  margin-bottom: 4px;

  label {
    // padding: 0;
    margin: 0;
  }

  &.dragging-on {
    background-color: #1286c6;
    color: white;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.19);

    label {
      color: white;
    }
  }
}

.drag-ul {
  border-radius: 3px;
  padding: 3px 0px;

  label {
    // padding: 0;
    margin: 0;
  }

  &.dragging-on {
    background-color: #1286c6;
    color: white;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.19);

    label {
      color: white;
    }
  }
}

.company_details {
  // .rw-widget {
  //   font-size: $font-size-12;
  //   .rw-widget-input,
  //   .rw-filter-input {
  //     box-shadow: none;
  //     span {
  //       float: none;
  //       line-height: normal;
  //     }
  //   }
  //   .rw-widget-container {
  //     border: 1px solid #ced4da;
  //   }
  // }
}

.success-toggle {
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    // border-color: #dc3545;
    // background-color: #dc3545;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #28a745;
    background-color: #28a745;
  }

  // .custom-control-label::before {
  //   color: #fff;
  // 	border-color: #dc3545;
  // 	background-color: #dc3545;
  // }
  // .custom-control-label::after {
  //   background-color: white;
  // }
  .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(40, 167, 69, 0.5);
  }
}

.full-grid {
  thead,
  tbody {
    tr {
      td {
        border-right: 1px solid #dee2e8;
      }

      th {
        white-space: nowrap !important;
        position: relative !important;
        @media only screen and (min-width: 769px) {
          // left: unset !important;
          position: sticky !important;
          z-index: 9;
          top: 0 !important;
        }

        &.w80 {
          white-space: nowrap !important;
        }

        &:nth-child(1) {
          left: 0 !important;
          position: sticky !important;
          z-index: 11;
          top: 0 !important;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          // white-space: nowrap;
          // min-width: 150px;
          // max-width: 150px;
          // width: 150px;
          white-space: nowrap !important;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          @media only screen and (min-width: 769px) {
            // left: unset !important;
            // position: relative !important;
            // z-index: 0;
            // top: unset !important;
          }
        }

        &:last-child {
          position: sticky !important;
          top: 0;
          right: 0;
        }
      }

      td {
        white-space: nowrap !important;
        position: relative !important;

        &.w80 {
          white-space: nowrap !important;
        }

        &:nth-child(1) {
          left: 0 !important;
          position: sticky !important;
          z-index: 8;
          top: 0 !important;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          // white-space: nowrap;
          // min-width: 150px;
          // max-width: 150px;
          // width: 150px;
          white-space: nowrap !important;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          @media only screen and (min-width: 769px) {
            left: unset !important;
            position: relative !important;
            z-index: 0;
            top: unset !important;
          }
        }

        &:last-child {
          position: sticky !important;
          top: 0;
          right: 0;
        }
      }
    }
  }
}

.segments-list-dropdown-menu {
  padding: 0;
  transform: none !important;
  top: 100% !important;
  width: 100%;
  box-shadow: 0px 5px 12px #0b254026;
  border: 0;
  margin-top: 5px;

  .rw-list-empty,
  .rw-list-option,
  .rw-list-optgroup {
    padding: 8px 12px;
    border-bottom: 0.5px solid #bed4eb;
  }

  .segments-list {
    max-height: 250px;
    border: 0;
    box-shadow: 0px 5px 12px #0b254026;

    .rw-select-list-label {
      font-size: $font-size-12;
    }
  }
}

.comapnay_tbl .content-save-btn {
  &.approve {
    background-color: #28a745;
    border-color: #28a745;
    color: white;
  }

  &.reject {
    background-color: #dc3545;
    border-color: #dc3545;
    color: white;
  }
}

.access-denied {
  .access-denied-wrapper {
    margin-top: 100px;

    i {
      font-size: $font-size-20;
      vertical-align: middle;
    }

    span {
      vertical-align: middle;
      color: black;
      font-size: $font-size-18;
      font-weight: bold;
    }
  }
}

.pointer {
  cursor: pointer;
}

.manage-rules {
  .card {
    // cursor: pointer;
    .actions-toggler {
      cursor: pointer;
      width: 30px;
      text-align: center;
    }
  }

  .card.active {
    .actions_board {
      opacity: 1;
      visibility: visible;
      z-index: 1;
      display: block;
    }
  }

  .actions_board {
    width: 130px;
    background: $clr_white;
    position: absolute;
    right: 0;
    z-index: 1;
    padding: 10px 10px;
    box-shadow: 0 0 10px #ccc;
    opacity: 0;
    @include transition(all 0.3s ease-in-out);
    visibility: hidden;
    display: none;

    > a {
      display: block;
      width: 100%;
      margin-bottom: 5px;
      color: $clr_lightblue;
      font-size: $font-size-11;

      i {
        width: $font-size-11;
      }
    }

    > ul {
      color: $clr_lightblue;

      li {
        font-size: $font-size-11;
        margin-bottom: 5px;
        cursor: pointer;
      }
    }
  }

  .card {
    background-color: #2487bc;
    border-color: #2487bc;
    border-radius: 2px;

    &.shared-board {
      background-color: #76a0b6;
      border-color: #76a0b6;
    }

    box-shadow: 0 16px 20px 0 rgba(36, 135, 188, 0.3),
      0 6px 10px 0 rgba(36, 135, 188, 0.3) !important;
  }

  .card-body {
    padding: 0.5rem;
    min-height: 130px;
  }

  .card-header {
    padding: 0.5rem !important;
    color: $clr_white;

    h2,
    h5 {
      padding: 0;
      margin: 0;
      color: $clr_white;
      text-align: left;
    }

    h2 {
      font-weight: bold;
      font-size: $font-size-14;
    }

    h5 {
      font-size: $font-size-12;
    }
  }

  .card-title {
    color: white;
    text-transform: uppercase;
    font-weight: 600;
  }

  .share-info {
    margin-top: 5px;
    margin-bottom: 0;
  }

  .vcenter {
    .vcenterAlign {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }

  .operator-text {
    font-weight: bold;
    font-size: $font-size-18;
    color: $tbr_color;
  }

  .add-more {
    font-size: $font-size-18;
  }

  .row-details {
    p {
      line-height: 1rem;
      color: white;
      margin: 0.5rem;
      text-align: left;
      margin-left: 0;
    }
  }

  .fa-plus-circle {
    color: white;
    font-size: $font-size-18;
  }

  .material-icons-round {
    color: white;
  }

  &.form-field-wrapper {
    .card {
      .card-header {
        background-color: rgba(0, 0, 0, 0.03) !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
      }
    }
  }
}

.templateRecordRadio {
  position: relative;
  margin: 0;
}

.filter-dropdown {
  .dd__selectControl {
    padding: 12px 6px !important;
    font-size: 1rem;
    max-height: 26px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    div {
      font-size: 12px;
    }
  }

  .filter-options {
    z-index: 10;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    .dd__option {
      font-size: 12px;
      padding: 6px;
      padding: 1px 5px;
    }
  }
}

.threshold-grid {
  thead,
  tbody {
    tr {
      td {
        border-right: 1px solid #dee2e8;
      }

      th,
      td {
        white-space: nowrap !important;

        &:last-child {
          position: sticky !important;
          top: 0;
          right: 0;
        }
      }
    }
  }

  .collapse {
    margin-top: 0;
  }

  .collapse-panel {
    color: $clr_grey;
  }
}

.collapse:not(.show) {
  display: block !important;
}

.collapse {
  margin-top: 0;
}

.collapse-panel {
  color: $clr_grey;
}

.sidebar_list2 {
  > ul {
    margin-bottom: 15px;

    > li {
      padding: 8px 15px;
      // position: relative;
      // padding-left: 5px;
      color: #1286c6;
      font-size: 12px;
      text-transform: capitalize;
      cursor: pointer;
      margin-bottom: 0;
      line-height: 12px;
      // cursor: pointer;
      &:before {
        top: 0px;
        opacity: 1;
      }
    }

    > li.active {
      // background-color: #074a6e;
      font-weight: 900;
      // color: white;
      &:before {
        top: 0px;
        opacity: 1;
      }
    }
  }
}

.collapse-trigger[aria-expanded="true"] > div:nth-child(1):before {
  transform: rotate(133deg);
  left: 0;
}

.collapse-trigger:before {
  content: "";
  border: solid rgba(0, 0, 0, 0.4);
  border-width: 0 1px 1px 0;
  display: none;
  padding: 2.5px;
  margin-right: 12px;
  transform: rotate(-45deg);
  position: relative;
  top: -2px;
  left: -2px;
  transition: 0.3s;
}

.collapse-trigger {
  color: $clr_darkgrey;

  div {
    &:nth-child(1)::before {
      content: "";
      border: solid rgba(0, 0, 0, 0.4);
      border-width: 0 0 2px 2px;
      display: inline-block;
      padding: 3px;
      margin-right: 12px;
      transform: rotate(-45deg);
      position: relative;
      top: -1px;
      left: -2px;
      transition: 0.3s;
    }
  }
}

.collapse-trigger:before {
  content: "";
  border: solid rgba(0, 0, 0, 0.4);
  border-width: 0 1px 1px 0;
  display: none;
  padding: 2.5px;
  margin-right: 12px;
  transform: rotate(-45deg);
  position: relative;
  top: -2px;
  left: -2px;
  transition: 0.3s;
}

.collapse-trigger {
  color: $clr_darkgrey;

  div {
    &:nth-child(1)::before {
      content: "";
      border: solid rgba(0, 0, 0, 0.4);
      border-width: 0 0 2px 2px;
      display: inline-block;
      padding: 3px;
      margin-right: 12px;
      transform: rotate(-45deg);
      position: relative;
      top: -1px;
      left: -2px;
      transition: 0.3s;
    }
  }
}

.faqs .btn {
  padding: 0.3rem 0.75rem;
  height: 32px;
  line-height: 0.5;
  font-size: 0.75rem;
}

.faqCategories_action_icons {
  i {
    visibility: hidden;
  }

  label {
    visibility: hidden;
  }

  &:hover {
    i {
      visibility: visible;
    }

    label {
      visibility: visible;
    }
  }
}

.add_faq_category {
  background: none;

  color: #1085c6;

  font-weight: bold;

  border-width: 1px;

  padding-top: 0.175rem;

  padding-bottom: 0.175rem;

  margin-bottom: 5px;

  > .add {
    float: left;

    line-height: 18px;
  }

  &:hover {
    background: none;

    color: #1085c6;
  }
}

.capitalize {
  text-transform: capitalize;
}

.htmlEditor-validate {
  font-size: $font-size-12;
  float: right;
  position: relative;
  top: unset;
  left: 0;
  z-index: 5;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

div .tox-notifications-container {
  display: none !important;
  position: absolute !important;
  left: 144.667px !important;
  top: 36px !important;
  max-height: 259px !important;
}

.tox-tinymce {
  border: 1px solid #b91818;
  border-radius: 0;
  box-shadow: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  overflow: hidden;
  position: relative;
  visibility: inherit !important;
}

.modal-w-header .modal-header .modal-title {
  text-transform: capitalize;
}

.helpButton.btn {
  width: 32px;
  padding: 0;
}

.userName {
  text-transform: "capitalize";
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.helpDescription {
  font-size: "small";
  margin-bottom: 5px;

  .p {
    margin-bottom: 5px;
  }
}

.tox .tox-statusbar {
  display: none !important;
}

table.mce-item-table {
  outline: none !important;
}

.mce-content-body audio[data-mce-selected],
.mce-content-body embed[data-mce-selected],
.mce-content-body img[data-mce-selected],
.mce-content-body object[data-mce-selected],
.mce-content-body table[data-mce-selected],
.mce-content-body video[data-mce-selected] {
  outline: 0px !important;
}

.mce-item-table:not([border]),
.mce-item-table:not([border]) caption,
.mce-item-table:not([border]) td,
.mce-item-table:not([border]) th,
.mce-item-table[border="0"],
.mce-item-table[border="0"] caption,
.mce-item-table[border="0"] td,
.mce-item-table[border="0"] th,
table[style*="border-width: 0px"],
table[style*="border-width: 0px"] caption,
table[style*="border-width: 0px"] td,
table[style*="border-width: 0px"] th {
  border: 0px !important;
}

.emailPlaceholder.alert.alert-warning.fade.show {
  width: 100%;
  margin: 5px 14px;
}

.dd__list.filter-options.dd__openTobottom {
  z-index: 12;
}

.reports_drop_down {
  background: #ffffff 0% 0% no-repeat padding-box;
  // box-shadow: 0px 0px 12px #0b254014;
  border: 1px solid $clr_lightgrey_new;
  border-radius: 5px;
  outline: none;
  font-size: 14px;
}

.caps {
  text-transform: capitalize;
}

.bulk {
  > p {
    margin: 0;
    color: white;
    font-size: 1rem;

    > span {
      &:nth-child(1) {
        margin-right: 15px;
      }
    }
  }
}

.custom-control.custom-switch {
  // display: inline-flex;
  // align-items: center;
  min-height: 20px;
  padding-left: 30px;

  input:disabled {
    + .custom-control-label {
      opacity: 0.4;
      cursor: no-drop;
    }
  }

  .custom-control-label {
    line-height: 20px;
    cursor: pointer;

    &::before {
      left: -27px;
      width: 20px;
      height: 12px;
      pointer-events: all;
      border-radius: 0.5rem;
      top: 5px;
      border: 2px solid #84909e !important;
      box-shadow: none !important;
      background-color: transparent !important;
    }

    &::after {
      top: 8px;
      left: -23px;
      width: 6px;
      height: 6px;
      background-color: #84909e !important;
    }
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::before {
      border: 2px solid #046cd9 !important;
      background-color: transparent;
    }

    &::after {
      background-color: #046cd9 !important;
      left: -29px;
    }
  }

  &.success-toggle {
    .custom-control-input:checked ~ .custom-control-label {
      &::before {
        border: 2px solid $clr_green2 !important;
      }

      &::after {
        background-color: $clr_green2 !important;
      }
    }
  }

  &.custom-switch-lg {
    padding-left: 30px;

    .custom-control-label {
      &::before {
        left: -29px;
        width: 28px;
        height: 16px;
        top: 3px;
      }

      &::after {
        top: 7px;
        left: -25px;
        width: 8px;
        height: 8px;
      }
    }

    .custom-control-input:checked {
      ~ .custom-control-label::after {
        left: -26px;
      }
    }
  }
}

.justify-between {
  justify-content: space-between;
}

.custom-switch-checkbox {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 12px;
  margin-bottom: 0px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:disabled + .slider {
    opacity: 0.65;
    cursor: no-drop;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    border: 2px solid #84909e;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 6px;
    width: 6px;
    left: 2px;
    bottom: 1px;
    background-color: #84909e;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    border: 2px solid #046cd9;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(6px);
    -ms-transform: translateX(6px);
    transform: translateX(6px);
    background-color: #046cd9;
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  &.success-custom-switch-checkbox {
    input:checked + .slider {
      border: 2px solid $clr_green2;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(6px);
      -ms-transform: translateX(6px);
      transform: translateX(6px);
      background-color: $clr_green2;
    }
  }

  &.custom-switch-checkbox-2 {
    .slider {
      // background-color: $clr_lightblue2;
      border: 2px solid $clr_lightblue2;

      &:before {
        background-color: $clr_lightblue2;
      }
    }

    input:checked + .slider {
      border: 2px solid $clr_lightblue2;
      background: $clr_lightblue2;

      &:before {
        background-color: white;
      }
    }
  }
}

.modal-footer .modal-btn {
  margin: 0;
}

.modal-btn,
.modal-footer .modal-btn {
  min-width: 85px;
  max-height: 33px;
  min-height: 33px;
  line-height: 31px;
  text-align: center;
  padding: 0px 16px;
  font-size: $font-size-h6;

  + .modal-btn {
    margin-left: 18px;
  }
}

.modal-sm {
  &.modal-dialog {
    @media (min-width: 576px) {
      max-width: 383px;
      margin-top: 50px;
    }
  }
}

.modal-lg {
  &.modal-dialog {
    @media (min-width: 576px) {
      max-width: 576px;
      margin-top: 50px;
    }
  }
}

.modal-backdrop.show {
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 0.88;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}

.modal-dialog {
  background: #ffffff 0% 0% no-repeat padding-box;
  // box-shadow: 0px 0px 12px #0b254024;
  border: 1px solid $clr_lightgrey_new;
  border-radius: 4px;
  opacity: 1;
}

.custom-modal {
  &.noaccess-modal {
    &.modal-dialog {
      @media (min-width: 576px) {
        max-width: 450px;
        margin: 1.75rem auto;
      }
    }
  }

  .modal-content {
    border-radius: 3.5px;
    border-top: 17px solid $clr_lightblue3;
    border-bottom: 7px solid $clr_lightblue3;
    padding: 16px 25px 25px;
  }

  .modal-header {
    padding: 0px;
    position: relative;
    border: 0;
    display: flex;
    align-items: center;

    h5.modal-title {
      margin: 0px;
      text-align: left;

      h3 {
        margin: 0px;
      }
    }

    .close {
      padding: 2px;
      display: inline-flex;
      outline: none;
      position: static;
      margin: 0;
      width: 30px;
      height: 30px;

      span {
        visibility: hidden;
        width: 28px;
        height: 28px;
        display: inline-flex;

        &:before {
          font: 28px "Material Icons Round";
          content: "\e5cd";
          visibility: visible;
          color: $clr_darkblue6;
        }
      }
    }
  }

  .modal-body {
    padding: 15px 0px 30px;

    .remove-body,
    .activate-body,
    .noaccess-body,
    .inactive-body {
      text-align: center;
      padding: 26px 20px;
      border-radius: 8px;

      h2 {
        font-size: 20px !important;
        font-family: $font-family;
      }
    }

    .remove-body {
      background-color: #fff3f2;

      h2 {
        color: $clr_red2;
      }
    }

    .activate-body {
      background-color: $clr_lightgreen;

      h2 {
        color: $clr_green;
      }
    }

    .noaccess-body {
      background-color: $clr_lightgrey9;

      h2 {
        color: $clr_grey3;
      }
    }

    .inactive-body {
      background-color: #fdf5dd;

      h2 {
        color: #87702f;
      }
    }
  }

  .modal-footer {
    padding: 0;
    border: 0;
    font-size: $font-size-h6;
  }

  &.modal-w-header {
    .modal-header {
      background-color: transparent;
      border: 0;

      .modal-title {
        color: $clr_darkblue6;
        font-size: $font-size-h3 !important;
        font-weight: bold;
        line-height: 1.2;
      }
    }

    .modal-body {
      padding-top: 20px;

      .title-label {
        margin-bottom: 4px;
      }

      .row-item {
        margin-bottom: 16px;
      }
    }

    .modal-footer {
      border: 0;
    }
  }

  &.modal-dialog-scrollable {
    .modal-header {
      padding: 0px 35px 10px 0px;
    }
  }

  &.comments-modal {
    .modal-body {
      padding: 5px 0px 30px;
    }

    .comment-widget {
      li.list-group-item {
        padding-left: 15px;
        border-radius: 5px;
      }
    }
  }

  &.company-segments-modal {
    .company-segments-files-table-block {
      .responsive-table {
        max-height: 400px;
      }
    }
  }

  &.modal-without-header {
    .modal-body {
      padding-top: 0px;
    }
  }

  &.modal-wihout-footer {
    .modal-body {
      padding-bottom: 5px;

      .remove-body,
      .activate-body,
      .noaccess-body,
      .inactive-body {
        padding: 25px 20px;

        h2 {
          font-size: 18px !important;
        }
      }
    }
  }
}

.custom-calendar-modal {
  &.noaccess-modal {
    &.modal-dialog {
      @media (min-width: 576px) {
        max-width: 450px;
        margin: 1.75rem auto;
      }
    }
  }

  .modal-content {
    height: 100%;
    border-radius: 4px;
  }

  .modal-header {
    padding: 0;
    position: relative;
    border: 0;
    display: flex;
    align-items: center;

    h5.modal-title {
      margin: 0;
      text-align: left;

      h3 {
        margin: 0;
      }
    }

    .close {
      padding: 2px;
      display: inline-flex;
      outline: none;
      position: static;
      margin: 0;
      width: 30px;
      height: 30px;

      span {
        visibility: hidden;
        width: 28px;
        height: 28px;
        display: inline-flex;

        &:before {
          font: 28px "Material Icons Round";
          content: "\e5cd";
          visibility: visible;
          color: $clr_darkblue6;
        }
      }
    }
  }

  .modal-body {
    padding: 25px;

    .remove-body,
    .activate-body,
    .noaccess-body,
    .inactive-body {
      text-align: center;
      padding: 26px 30px;
      border-radius: 8px;

      h2 {
        font-size: 15px !important;
        font-family: $font-family;
      }
    }

    .remove-body {
      background-color: #fff3f2;

      h2 {
        color: $clr_red2;
      }
    }

    .info-body {
      background-color: #fff;

      h2,
      h3 {
        color: #000;
      }
    }

    .activate-body {
      background-color: $clr_lightgreen;

      h2 {
        color: $clr_green;
      }
    }

    .noaccess-body {
      background-color: $clr_lightgrey9;

      h2 {
        color: $clr_grey3;
      }
    }

    .inactive-body {
      background-color: #fdf5dd;

      h2 {
        color: #87702f;
      }
    }
  }

  .modal-footer {
    padding: 0;
    font-size: $font-size-h6;
  }

  &.modal-w-header {
    .modal-header {
      background-color: transparent;
      border: 0;

      .modal-title {
        color: $clr_darkblue6;
        font-size: $font-size-h3 !important;
        font-weight: bold;
        line-height: 1.2;
      }
    }

    .modal-body {
      padding-top: 20px;

      .title-label {
        margin-bottom: 4px;
      }

      .row-item {
        margin-bottom: 16px;
      }
    }

    .modal-footer {
      border: 0;
    }
  }

  &.modal-dialog-scrollable {
    .modal-header {
      padding: 0 35px 10px 0;
    }
  }

  &.comments-modal {
    .modal-body {
      padding: 5px 0 30px;
    }

    .comment-widget {
      li.list-group-item {
        padding-left: 15px;
        border-radius: 5px;
      }
    }
  }

  &.company-segments-modal {
    .company-segments-files-table-block {
      .responsive-table {
        max-height: 400px;
      }
    }
  }
}

.visibility-hidden {
  visibility: hidden;
}

.add-edit-form-card {
  .custom-combobox-list .rw-widget-picker,
  .custom-multiselect .rw-widget-picker {
    // height: 32px;
    border-radius: 2px;
    border-color: #ced4da !important;
  }

  .custom-datepicker {
    .rw-widget-picker {
      // height: 32px;
    }
  }

  .custom-multiselect .rw-multiselect {
    // min-height: 32px;
  }

  .custom-multiselect .rw-widget-picker .rw-multiselect-taglist {
    vertical-align: middle;
  }

  .custom-select-new {
    select {
      padding: 4px 45px 4px 12px;
      font-size: 13px;
    }

    &.custom-select-dropdown:after {
      line-height: 29.5px;
    }
  }

  form.form-wrapper {
    .card {
      .card-header {
        padding: 19px 17px;
        padding-bottom: 0px;
        background-color: transparent;
        border-bottom: none;
      }

      .card-body {
        padding: 12px 17px 27px;

        .card-body-header {
          background-color: $clr_alice_blue3;
          padding: 10px;
          color: $clr_darkgrey2;

          h5 {
            color: $clr_darkgrey2;
          }
        }

        .form-field-wrapper {
          margin-top: 29px;
          padding-bottom: 11px;
          // border-bottom: 1px solid $clr_tableborder;
          .form-control {
            flex-grow: 1;
            border: 1px solid #e1e1e1;
            border-radius: 2px;
          }

          .rw-widget-container.rw-state-focus,
          .rw-state-focus > .rw-widget-container,
          .rw-widget-container.rw-state-focus:hover,
          .rw-state-focus > .rw-widget-container:hover {
            border: 1px solid #e1e1e1;
            border-radius: 2px;
          }

          label:not(.form-check-label) {
            margin-right: 10px;
            font-size: 12px;
            text-align: left;
            justify-content: left;
            color: $clr_lightgrey;
          }

          .user-permissions .user-permissions-module {
            .custom-checkbox-new {
              margin-top: 0;
            }

            .form-check-label {
              text-transform: capitalize;
              padding-left: 6px;
              margin-left: 0;
              cursor: pointer;
            }
          }

          .form-group {
            margin-bottom: 23px;

            &.align-top {
              align-items: flex-start;

              label {
                margin-top: 2px;
              }
            }

            .invalid-tooltip {
              margin-left: 70px;
            }
          }
        }

        .form-buttons {
          margin-top: 24px;
        }

        .form-submit-buttons {
          border-top: 1px solid $clr_tableborder;
        }
      }
    }
  }

  .metric-rule-dropdown {
    select {
      height: 30px;
      line-height: 20px;
      font-size: 16px;
    }
  }
}

.valign-middle {
  vertical-align: middle;
}

.segments-list-dropdown-menu {
  input[type="checkbox"] {
    -webkit-appearance: none;
    min-width: 14px;
    width: 14px;
    height: 14px;
    border: 1px solid $clr_lightgrey2;
    vertical-align: sub;
    outline: none;
    border-radius: 3px;
    position: absolute;

    &:checked {
      background-color: $clr_blue;
      border-color: $clr_blue;

      &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmZmZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPmljb25fYnlfUG9zaGx5YWtvdjEwPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjZmZmZmZmIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNi4wMDAwMDAsIDI2LjAwMDAwMCkiPjxwYXRoIGQ9Ik0xNy45OTk5ODc4LDMyLjQgTDEwLjk5OTk4NzgsMjUuNCBDMTAuMjI2Nzg5MSwyNC42MjY4MDE0IDguOTczMTg2NDQsMjQuNjI2ODAxNCA4LjE5OTk4Nzc5LDI1LjQgTDguMTk5OTg3NzksMjUuNCBDNy40MjY3ODkxNCwyNi4xNzMxOTg2IDcuNDI2Nzg5MTQsMjcuNDI2ODAxNCA4LjE5OTk4Nzc5LDI4LjIgTDE2LjU4NTc3NDIsMzYuNTg1Nzg2NCBDMTcuMzY2ODIyOCwzNy4zNjY4MzUgMTguNjMzMTUyOCwzNy4zNjY4MzUgMTkuNDE0MjAxNCwzNi41ODU3ODY0IEw0MC41OTk5ODc4LDE1LjQgQzQxLjM3MzE4NjQsMTQuNjI2ODAxNCA0MS4zNzMxODY0LDEzLjM3MzE5ODYgNDAuNTk5OTg3OCwxMi42IEw0MC41OTk5ODc4LDEyLjYgQzM5LjgyNjc4OTEsMTEuODI2ODAxNCAzOC41NzMxODY0LDExLjgyNjgwMTQgMzcuNzk5OTg3OCwxMi42IEwxNy45OTk5ODc4LDMyLjQgWiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==);
        background-size: 28px;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}

.html-editor-block {
  .tox-tinymce {
    border: 1px solid #e1e1e1;
    border-radius: 2px;
  }
}

.help-categories-list {
  margin-bottom: 15px;
  margin-top: 10px;

  .help-item {
    padding: 7px 10px 7px 12px;
    color: $clr_lightgrey2;
    font-size: 13px;
    text-transform: capitalize;
    margin-bottom: 0;

    &.active {
      .help-item-text {
        font-weight: 600;
        color: $clr_lightblue2;
      }
    }

    .add-icon {
      color: $clr_lightblue2;
    }

    .remove-icon {
      color: $clr_red4;
    }

    .help-item-text {
      cursor: pointer;
    }
  }
}

.client-user-table-block {
  table {
    th:last-child,
    td:last-child,
    th:nth-last-child(2),
    td:nth-last-child(2) {
      position: sticky;
    }

    td:last-child,
    td:nth-last-child(2) {
      z-index: 2;
    }

    th:last-child,
    th:nth-last-child(2) {
      z-index: 3;
    }

    th:last-child,
    td:last-child {
      right: 0;
    }
  }
}

.help-right-content {
  background-color: #ffffff;
  // box-shadow: 0px 0px 12px #0b254024;
  border-radius: 3.5px;
  // padding: 4px 5px;
  .help-card {
    background: inherit;
    box-shadow: none;
    border-radius: 0;

    .card-body {
      padding-top: 0px;
      height: calc(100vh - 212px);
      min-height: 300px;
      overflow: auto;

      .title-block {
        position: sticky;
        top: 0px;
        z-index: 2;
        background-color: #ffffff;
        padding-top: 1.25rem;
        padding-bottom: 10px;
        margin-bottom: 8px;
      }
    }
  }
}

.client-user-sample-block {
  table {
    th:last-child,
    td:last-child {
      position: sticky;
      z-index: 2;
      right: 0;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.accountmanagers-table-block {
  .comapany_table tbody tr td:nth-child(2),
  .comapany_table tbody tr td:nth-child(3) {
    white-space: normal;
  }
}

.help-details-content-ul {
  li {
    margin-bottom: 12px;

    label {
      color: $clr_lightblue2;
      margin-bottom: 3px;
    }
  }
}

.faq-categories-list {
  margin-bottom: 15px;

  .faq-item {
    margin-bottom: 0;

    .icons-wrapper {
      visibility: hidden;

      .icon {
        color: $clr_lightgrey2;
      }
    }

    &.selected {
      .icons-wrapper {
        visibility: visible;
      }
    }

    &:hover {
      .icons-wrapper {
        visibility: visible;
      }
    }

    .faq-item-text {
      color: $clr_lightblue2;
      font-weight: bold;
      font-size: 13px;
      text-transform: capitalize;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .no-faq-topic-msg {
    padding: 7px 4px 7px 12px;
  }
}

.faq-page {
  &.main_content {
    padding-left: 0px;
    @media (min-width: 992px) {
      padding-left: 82px;
    }
    @media (min-width: 1200px) {
      padding-left: 88px;
    }
  }

  .inner-sidebar {
    padding: 0;
    max-width: 260px;
    top: 144px;
    height: calc(100% - 198px);
    background-color: transparent;
    box-shadow: none;
    left: 0px;
    @media (min-width: 992px) {
      left: 82px;
    }
    @media (min-width: 1200px) {
      // left: 88px;
      left: 120px;
    }
  }

  .faq-page-title-sec {
    padding-top: 7px;
    padding-bottom: 22px;

    .chat-icon {
      font-size: 40px;
      margin-right: 17.5px;
    }
  }

  .faq-inner-sidebar-content {
    padding: 10px 10px 10px 15px;

    .faq-categories-list {
      padding-bottom: 4px;

      .faq-item {
        padding: 11px 13px;
        // box-shadow: 0px 0px 30px #0b254017;
        border-radius: 3.5px;
        background-color: white;
        // border: 1px solid transparent;
        border: 1px solid $clr_lightgrey_new;
        cursor: pointer;

        &.selected {
          border: 1px solid $clr_lightblue2;
        }

        &.inactive {
          background-color: #f9f9f9;
          opacity: 0.75;

          &.selected {
            opacity: 1;
          }
        }

        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }
  }

  .faq-right-content {
    background: #ffffff 0% 0% no-repeat padding-box;
    // box-shadow: 0px 0px 12px #0b254024;
    // border: 1px solid $clr_lightgrey_new;
    border-radius: 3.5px;
    padding: 4px 5px;

    .faqs-card {
      background: inherit;
      box-shadow: none;
      border-radius: 0;

      .card-body {
        padding: 14px 24px 24px;
        height: calc(100vh - 230px);
        overflow: auto;
      }

      .see-more {
        .arrow-down {
          display: inline-block;
        }

        .arrow-up {
          display: none;
        }
      }

      .see-less {
        .arrow-down {
          display: none;
        }

        .arrow-up {
          display: inline-block;
        }
      }
    }

    .faq-title {
      font-family: $font-family;
    }

    .title-right-block {
      min-width: 180px;
    }
  }

  .faqs-collapse-wrapper {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .faq-right-block {
    margin-left: 25px;
    flex-grow: 1;
  }

  .faq-left-block {
    .top-block-wrapper {
      width: 255px;
    }

    .top-block {
      width: 225px;
      // margin-top: 11px;
      .faq-type-dropdown {
        width: 95px;

        select {
          padding: 6px 25px 6px 12px;
        }
      }
    }

    .faq-type-dropdown {
      margin-right: 20px;
    }
  }
}

.saml-config-error {
  color: red;
  font-size: 12px;
}

.is-invalidDropDown {
  ~ .invalid-tooltip {
    display: block;
  }
}

.show-more-less-btn {
  font-size: 15px;
  color: $clr_lightblue2 !important;
  box-shadow: none !important;
  padding: 0px;
}

.pt-15 {
  padding-top: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.add-edit-category-subcategory-popover {
  .popover {
    min-width: 255px;
    left: 198px !important;
    top: -4px !important;
  }

  .popover-body {
    padding: 10px 4px 14px;
  }

  .add-sub-category,
  .add-sub-category:not(:disabled):not(.disabled):active,
  .add-sub-category:not(:disabled):not(.disabled).active,
  .show > .add-sub-category.dropdown-toggle {
    background-color: unset;
    color: $clr_black2;
    border: none;
    margin: 6px 0px 6px 12px;

    &:focus {
      box-shadow: none;
    }

    .icon {
      margin-right: 6px;
    }
  }

  .title-block {
    color: $clr_black2;
    margin-bottom: 12px;
  }

  .title-block .title {
    color: $clr_darkblue6;
  }

  input.form-control {
    font-size: 12px;
    border-radius: 5px;
    color: $clr_blue;
  }

  .title-block,
  .cat-subcat-inputs-block {
    padding-left: 10px;
    padding-right: 10px;
  }

  .cat-subcat-inputs-block {
    max-height: 250px;
    overflow: auto;
    padding-top: 3px;
    @media (min-width: 1600px) {
      max-height: 300px;
    }
  }

  .sub-category-inputs {
    margin-left: 16px;

    .input-wrapper {
      padding-bottom: 14px;

      input.form-control {
        color: $clr_green3;
      }

      &:last-child {
        padding-bottom: 3px;
      }

      position: relative;

      &::before,
      &::after {
        content: "";
        left: -16px;
        position: absolute;
        right: auto;
        z-index: -1;
      }

      &::before {
        border-left: 1px solid #cfd4d9;
        bottom: 50px;
        height: 100%;
        top: -3px;
        width: 1px;
      }

      &:first-child::before {
        height: 100px;
        bottom: 0px;
        top: unset;
      }

      &:last-child::before {
        height: 20px;
      }

      &:only-child::before {
        height: 90px;
        top: -74px;
      }

      &::after {
        border-top: 1px solid #cfd4d9;
        height: 20px;
        top: 16px;
        width: 18px;
      }
    }
  }

  .save-btn-wrapper {
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.mw-130px {
  min-width: 130px;
}

// .manage-delete-button {
//   padding-right: 30px;
//   @media (min-width: 992px) {
//     margin-right: calc(100% / 12);
//   }
// }

.list-managers-table.custom-table-block.sort-table-block
  table
  tr
  th:nth-child(3) {
  .th-content {
    justify-content: center;
  }
}

.reports-page-cards {
  border-radius: 3.5px;
  margin-bottom: 16px;
}

.sidebar_section {
  border-radius: 9px;
  // transition: all .5s ease-in-out;
  // height: 648px;
  height: calc(100vh - 70px);
  // width: 70px;
  margin: 6px 0 6px 9px;
  z-index: 99999;
  position: relative;
  // color: black;
  // padding: 16px 0 16px 8px;
  white-space: nowrap;
  // overflow: hidden;
  padding-top: 50px;

  .togggle_circle {
    width: 26px;
    height: 26px;
    background-color: $clr_white;
    box-shadow: 0px 3px 6px #00000033;
    border-radius: 50%;
    position: absolute;
    right: -8px;
    top: 16px;
    transition: all 0.5s ease-in-out;
    padding: 3px;
    cursor: pointer;

    .blue_circle {
      background-color: #0079f5;
      border-radius: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .double_arrow {
        color: $clr_white;
        font-size: 16px;
      }
    }
  }

  &::before {
    background: transparent linear-gradient(180deg, #0b2540, #003770) 0 0
      no-repeat padding-box;
    height: 100%;
    width: 70px;
    content: "";
    display: block;
    border-radius: 9px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }

  .parent_ul {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    transition: all 0.5s ease-in-out;

    &::-webkit-scrollbar-thumb {
      visibility: hidden;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        visibility: visible;
      }
    }

    .material-icons-outlined {
      vertical-align: middle;
    }

    li.nav-item {
      .label_name {
        font-weight: 600;
        font-size: 14px;
      }

      // &.active {
      //   .sub_label_name {
      //     background: $clr_solitude;
      //     border-radius: 4px;
      //     color: $clr_lightblue3;
      //     font-weight: 600;
      //     width: 126px;
      //   }
      //   .material-icons-outlined {
      //     color: #1e81e8;
      //     background-color: #08396c;
      //     border-radius: 4px;
      //   }
      // }

      .not-dropdown {
        &:hover {
          .material-icons-outlined {
            transition: all 0.5s ease-in-out;
            color: #1e81e8;
            background-color: #08396c;
            border-radius: 4px;
          }
        }
      }
    }

    .child_ul {
      margin-left: 12px;
      transition: all 0.5s ease-in-out;

      .sidebar_section ul li.nav-item {
        padding: 6px;
      }

      .sub_label_name {
        margin-left: 46px;
        margin-top: 4px;
        padding: 6px;
        width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        color: #000;
      }

      .material-icons-outlined {
        font-size: 16px;
        vertical-align: middle;
        transition: all 0.5s ease-in-out;
      }

      li.nav-item {
        padding: 0 6px;

        &:hover {
          .sub_label_name {
            background: $clr_solitude;
            border-radius: 4px;
            color: $clr_lightblue3;
            // width: 126px;
            transition: all 0.5s ease-in-out;
          }

          .material-icons-outlined {
            transition: all 0.5s ease-in-out;
            color: #1e81e8;
            background-color: #08396c;
            border-radius: 4px;
          }
        }

        &.active {
          .sub_label_name {
            background: $clr_solitude;
            border-radius: 4px;
            color: $clr_lightblue3;
            font-weight: 600;
            // width: 126px;
          }

          .material-icons-outlined {
            color: #1e81e8;
            background-color: #08396c;
            border-radius: 4px;
          }
        }
      }

      .nav-link {
        padding-left: 0 !important;
        padding-bottom: 0 !important;
      }
    }

    input {
      display: none;
    }
  }

  ul li {
    cursor: pointer;
    text-align: left !important;

    &.nav-item {
      padding: 8px;

      .label_name {
        width: 140px;
        padding: 6px;
        display: inline-block;
        border-radius: 4px;
        margin-left: 30px;
        vertical-align: middle;
        color: #000;
      }

      .material-icons-outlined,
      .material-icons-round {
        color: $clr_lightgrey2;
      }

      .material-icons-outlined {
        padding: 6px;
      }

      &.active {
        a .label_name {
          background: $clr_solitude;
          border-radius: 4px;
          color: $clr_lightblue3;
          font-weight: 600;

          &:before {
            background: $clr_lightblue2;
            opacity: 0;
          }
        }
      }

      &:hover {
        a .label_name {
          transition: all 0.5s ease-in-out;
          background: $clr_solitude;

          &:before {
            background: $clr_solitude;
            opacity: 1;
          }
        }
      }
    }
  }
}

.rw-list-option.rw-state-selected,
.rw-list-option.rw-state-selected:hover {
  background-color: #337ab7 !important;
  border-color: #337ab7 !important;
  color: #fff !important;
}

.rw-list-option.rw-state-focus,
.rw-list-option.rw-state-focus:hover {
  border-color: #66afe9;
  background-color: #337ab7 !important;
  color: #fff;
}

// .tooltip_info {
//   position: absolute;
//   top: -87px;
//   left: 12px;
//   width: 400px;
//   height: 300px;
//   overflow: auto;
//   background-color: #f6f6f6;
//   padding: 20px;
//   border: solid 1px #ccc;
//   border-radius: 5px;
//   display: none;
// }

// .tooltip_icon:hover .tooltip_info {
//   display: block;
// }

.visualization-chevron_table {
  .responsive-table {
    overflow-x: unset;
    overflow: unset;
  }

  thead tr th:nth-child(2) {
    position: unset !important;
  }

  tbody tr td:nth-child(2) {
    position: unset !important;
  }
}

.tooltip1 {
  position: relative;
  display: inline-block;
}

.tooltip_info {
  position: absolute;
  left: 16px;
  bottom: 5px;
  width: 400px;
  max-height: 300px;
  overflow: auto;
  background-color: #fff;
  padding: 12px;
  border: solid 1px #ccc;
  border-radius: 4px;
  display: none;
  z-index: 99999999;
  white-space: pre-wrap;
}

.tooltip1:hover .tooltip_info {
  display: block;
}

.all_checkbox {
  height: 40px;
}

.terms-conditions {
  color: #99a0aa;
  bottom: 2%;
  left: 0%;
  right: 0%;
  position: absolute;

  .links {
    margin-right: 10px;
    font-size: 14px;
    margin-left: 10px;
  }

  .links:hover {
    color: white;
  }
}

.pencil-icon {
  width: 10px;
}

.textarea-definition {
  width: 100%;
  height: 120px;
}

.back-forth {
  width: 200px;
  height: 50px;
  // background-color: red;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.toggle-select {
  color: #046cd9;
}

.toggle-unselect {
  color: #86909d;
}

.switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 22px;
  // background-color: #0079F5;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1e81e8;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 2.8px;
  bottom: 2.5px;
  border-radius: 50%;
  background-color: #ffffff;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #8e98a8;
}

input:checked + .slider:before {
  transform: translateX(15px);
}

.ppt-upload-error {
  width: 100%;
  height: 35px;
  border-radius: 2px;
  color: white;
  font-size: 12.5px;
  background-color: #e74b3c;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preference-container {
  display: flex;
  margin-top: 60px;
}

.preference-sidebar {
  width: 300px;
  height: 100vh;
  background-color: #ffffff;
  position: fixed;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.preference-head {
  font-size: 19px;
  font-weight: 600;
  color: black;
  margin-left: 8px;
}

.pref-collapser-item {
  margin-top: 15px;
  margin-left: 30px;
}

.preference-collapse,
.preference-collapse-2 {
  width: 220px;
  height: 37px;
  border-radius: 6px;
  background-color: #5b87c4;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.preference-collapse-2 {
  background-color: #f1f7fe;
  border: 1px solid #5b87c4;
}

.pref-collapse-header,
.pref-collapse-header-2 {
  color: white;
  font-size: 14px;
  margin-top: 8px;
  margin-left: 10px;
}

.pref-collapse-header-2 {
  color: #555555;
}

.pref-collapse-list {
  // background-color: red;
  cursor: pointer;
}

.pref-list-item,
.pref-list-item-selected {
  margin-left: 20px;
  padding: 0px 18px;
  height: 50px;
  border-left: 1px solid #9aa0a9;
  display: flex;
  align-items: center;
}

.pref-list-name,
.pref-list-name-selected {
  font-size: 14px;
  color: #555555;
  margin-top: 15px;
  margin-left: 10px;
  font-weight: 600;
}

.pref-list-item-selected {
  border-left: 1px solid #2e6ad2;
}

.pref-list-name-selected {
  color: #5b87c4;
}

.preference-content {
  position: fixed;
  margin-top: 12px;
  border-radius: 8px;
  margin-left: 315px;
  height: 85%;
  width: calc(100% - 330px);
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px 30px;
  margin-bottom: 50px;
}

.pref-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border-bottom: 0.5px solid #a89999;
}

.pref-email-head {
  color: #2c3957;
  font-weight: 600;
  font-size: 18px;
}

.pref-email-para {
  color: #555555;
  margin-top: -7px;
  font-size: 14px;
}

.digest-btn,
.immediate-btn {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 162px;
  height: 34px;
  border: 1px solid #e9ebed;
  border-radius: 2px;
  margin-bottom: 4px;
  margin-top: -10px;
}

.immediate-btn {
  margin-bottom: 0;
  margin-top: 0;
}

.freq-text {
  font-size: 13px;
  color: #2e6ad2;
  font-weight: 600;
  border-bottom: 1px solid #2e6ad2;
  margin-bottom: 0px;
  cursor: pointer;
}

.pref-header-divider {
  width: 100%;
  height: 1px;
  background-color: #cdbfbf;
  margin-top: 2px;
}

.select-digest,
.select-immediate {
  color: #ffffff;
  font-weight: 600;
  background: #5b87c4;
  border-radius: 2px;
  padding: 2px 12px;
  cursor: pointer;
  // background-color: red;
}

.select-immediate {
  color: #2c3957;
  background: transparent;
  padding: 2px 10px;
}

.pref-content,
.pref-content-selected {
  margin-top: 20px;
  height: auto;
  border: 1px solid #5b87c4;
  border-radius: 2px;
  padding: 12px 25px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  scroll-margin: 10px;
}

.pref-content-selected {
  border: 0.5px solid #eeecec;
  background-color: #f9f9f9;
}

.content-box-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid #a89999;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.pref-h3 {
  font-weight: 600;
  font-size: 20px;
  color: #232d42;
}

.pref-select-all {
  color: #2e6ad2;
  font-size: 16px;
  cursor: pointer;
  font-weight: 600;
}

.pref-input-text {
  margin-top: 15px;
  margin-left: 10px;
  font-size: 16px;
  color: #555555;
}

input[type="checkbox"] {
  accent-color: #5b87c4;
  width: 15px;
  height: 15px;
  font-weight: 100;
}

.pref-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pref-modal-content h3 {
  color: #2c3957;
  font-size: 20px;
  font-weight: 600;
}

.pref-modal-content p {
  color: #9aa0a9;
  font-size: 13px;
  margin-top: -5px;
}

.pref-radio-text {
  font-size: 16px;
  margin-left: 10px;
  color: #2c3957;
  font-weight: 600;
}

.pref-scroller {
  display: block;
  width: 100%;
  height: 80%;
  overflow: scroll;
}

.pref-scroller::-webkit-scrollbar {
  display: none;
}

.notification-btn {
  background-color: #086cd9;
  padding: 7px;
  margin-right: 15px;
}

.model-notification {
  display: flex;
  align-items: center;
  margin-top: 15px;
  width: 100%;
}

.model-notification h3 {
  margin-left: 10px;
  font-size: 16px;
  margin-top: 8px;
}

// calendar styling
.webinar-left,
.webinar-left-published {
  width: 22%;
  height: 100%;
  padding: 40px 10px 0px 30px;
  // background-color: #F4F5F4;
  border-right: 0.5px solid #9aa0a9;
  margin-right: 10px;

  h2 {
    color: #2c3957;
    font-size: 18px;
    font-weight: 700;
  }
}

.webinar-left-published {
  width: 22%;
  padding: 40px 10px 0px 25px;
  overflow-y: auto;
}

.webinar-right {
  width: 80%;
  height: 100%;
  padding-top: 10px;
  padding-right: 10px;
  // background-color: red;
}

.webinar-collapse,
.published-collapse {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;

  h3 {
    font-size: 15px;
    color: #555555;
    font-weight: 600;
  }
}

.published-collapse {
  margin-top: 30px;
}

.webinar-checkbox-label,
.published-checkbox-label {
  font-weight: 600;
  font-size: 12.56px;
  color: #555555;
  margin-left: 20px;
  letter-spacing: 0.3px;
  margin-top: 2px;
}

.published-calendar-icon {
  width: 20px;
  margin-left: 15px;
  margin-top: 2px;
}

.published-checkbox-label {
  margin-left: 10px;
}

.upload-customer-study-file-error {
  border: 1px solid rgb(220, 53, 69) !important;
}

.upload-customer-study-file {
  width: 100%;
  font-family: "Source Sans Pro";
  height: 200px;
  border: 1px dashed #ccc;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;

  p {
    color: #606b77;
    font-size: 13px;
    text-align: center;
  }

  .upload-btn {
    border: none;
    outline: none;
    background-color: #046cd9;
    padding: 7px 18px;
    border-radius: 3px;
    color: white;
    cursor: pointer;
    text-align: center;
    font-size: 13px;
  }

  .upload-btn:hover {
    background-color: #0461c5;
  }

  .show-file-div {
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;
    padding-right: 10px;

    .uploaded-file {
      width: fit-content;
      padding: 5px 10px;
      border-radius: 25px;
      background-color: #046cd9;
      font-size: 12.5px;
      color: white;
      // margin-right: 10px;
    }
  }
}

.download-cs-box {
  width: 35px;
  height: 35px;
  background: #046cd9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.text-white-clr {
  color: white !important;
}

.content-loading {
  position: absolute;
  top: 50%;
  left: 50%;
}

.existing-user {
  margin-left: 10px;
}

.segments-tree {
  max-height: 60vh;
  min-height: 60vh;
  overflow-y: scroll;

  .rct-icons-fa4 .rct-icon-uncheck::before,
  .rct-icons-fa4 .rct-icon-check::before {
    content: none;
  }

  .react-checkbox-tree .rct-checkbox .rct-icon {
    -webkit-appearance: none;
    min-width: 14px;
    width: 14px;
    height: 14px;
    border: 1px solid #cfd4d9;
    vertical-align: sub;
    outline: none;
    border-radius: 3px;
    position: relative;
    cursor: pointer;

    &.rct-icon-check {
      background-color: #0461c2;
      border-color: #0461c2;

      &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmZmZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPmljb25fYnlfUG9zaGx5YWtvdjEwPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjZmZmZmZmIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNi4wMDAwMDAsIDI2LjAwMDAwMCkiPjxwYXRoIGQ9Ik0xNy45OTk5ODc4LDMyLjQgTDEwLjk5OTk4NzgsMjUuNCBDMTAuMjI2Nzg5MSwyNC42MjY4MDE0IDguOTczMTg2NDQsMjQuNjI2ODAxNCA4LjE5OTk4Nzc5LDI1LjQgTDguMTk5OTg3NzksMjUuNCBDNy40MjY3ODkxNCwyNi4xNzMxOTg2IDcuNDI2Nzg5MTQsMjcuNDI2ODAxNCA4LjE5OTk4Nzc5LDI4LjIgTDE2LjU4NTc3NDIsMzYuNTg1Nzg2NCBDMTcuMzY2ODIyOCwzNy4zNjY4MzUgMTguNjMzMTUyOCwzNy4zNjY4MzUgMTkuNDE0MjAxNCwzNi41ODU3ODY0IEw0MC41OTk5ODc4LDE1LjQgQzQxLjM3MzE4NjQsMTQuNjI2ODAxNCA0MS4zNzMxODY0LDEzLjM3MzE5ODYgNDAuNTk5OTg3OCwxMi42IEw0MC41OTk5ODc4LDEyLjYgQzM5LjgyNjc4OTEsMTEuODI2ODAxNCAzOC41NzMxODY0LDExLjgyNjgwMTQgMzcuNzk5OTg3OCwxMi42IEwxNy45OTk5ODc4LDMyLjQgWiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==);
        background-size: 28px;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }

  .rct-icons-fa4 .rct-icon-expand-close::before {
    font: 20px "Material Icons Round";
    content: "\e5cc";
    color: $clr_lightgrey2;
  }

  .rct-icons-fa4 .rct-icon-expand-open::before {
    font: 20px "Material Icons Round";
    content: "\e5cf";
    color: $clr_lightblue2;
  }

  .react-checkbox-tree label:hover {
    background-color: transparent;
  }

  .react-checkbox-tree > ol > li {
    &.rct-node-parent {
      &.rct-node-expanded {
        > .rct-text {
          background-color: $clr_alice_blue2;

          .rct-title {
            color: $clr_blue;
          }
        }
      }
    }
  }

  &.custom-checkbox-tree {
    padding: 5px 8px;

    .add-icon {
      opacity: 0;
      font-size: 18px;
      color: red;
      cursor: pointer;
      margin-left: auto;
      margin-right: 20px;
    }

    .custom-checkbox-new:checked {
      + .text {
        color: $clr_blue !important;
      }

      ~ .add-icon {
        opacity: 0.5;
      }
    }

    .checkbox-item {
      &.active {
        > .rct-text {
          .custom-checkbox-new:checked {
            ~ .add-icon {
              opacity: 1;
              margin-right: 0px;
            }
          }
        }
      }
    }

    .checkbox-list {
      .checkbox-item {
        padding: 2px 0px;

        .icon {
          color: $clr_lightgrey2;
          font-size: 22px;
          line-height: 10px;
        }

        &.expanded-true {
          > .rct-text > .icon {
            color: $clr_lightblue2;
          }
        }

        .rct-title {
          color: $clr_lightgrey2;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding-left: 6px;
          font-size: 12px;
          cursor: pointer;

          &.active {
            color: $clr_blue;
          }
        }

        .custom-checkbox-new:checked {
          ~ .rct-title {
            color: $clr_blue;
          }
        }

        .rct-text {
          padding: 4px 5px 3px;

          .icon {
            cursor: pointer;
          }

          .custom-checkbox-new {
            margin-left: 10px;
          }

          .icon ~ .custom-checkbox-new {
            margin-left: 0px;
          }
        }
      }
    }

    .visualisation {
      > .checkbox-list {
        > .checkbox-item {
          &.expanded-true {
            > .rct-text {
              background-color: $clr_alice_blue2;
              border-radius: 3px;
            }
          }
        }
      }
    }

    ol {
      margin: 0;
      padding-left: 0;
      list-style-type: none;
    }

    ol ol {
      padding-left: 24px;
    }

    li {
      list-style-type: none;
      margin: 0;
      position: relative;
    }

    li::before {
      border-left: 1px solid $clr_tableborder;
      bottom: 50px;
      height: 100%;
      top: -3px;
      width: 1px;
    }

    li::after {
      border-top: 1px solid $clr_tableborder;
      height: 20px;
      top: 16px;
      width: 18px;
    }

    li:last-child::before {
      height: 20px;
    }

    li::before,
    li::after {
      content: "";
      left: -8px;
      position: absolute;
      right: auto;
      z-index: 1;
    }

    .visualisation {
      > ol > li::after,
      > ol > li::before {
        border: 0;
      }
    }
  }
}

.rule-view-main-div > div:nth-child(4) {
  border-right: none !important;
}

.custom-padding {
  padding: 0px 0px 18px 0px;
}

.custom-link {
  display: flex;
  gap: 10px;
  align-items: center;

  .nav-link {
    margin: 0px !important;
  }
}

.custom-tooltip-status {
  background: #ffffff !important;
  padding: 0 !important;
  min-width: 150px;
  display: inline-flex !important;
  padding: 10px !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 10px !important;
  border-radius: 5px !important;
  border-top: 3px solid #046cd9 !important;
  border-bottom: 3px solid #046cd9 !important;
  background: var(--color-gray-10, #fff) !important;
  box-shadow: 3px 0px 50px 0px rgba(0, 0, 0, 0.1) !important;

  .main-div {
    width: 100%;
    display: flex;
    gap: 10px;
    flex-direction: column;
  }

  .map-div {
    display: flex;
    justify-content: space-between;
    color: #555;
    font-family: "Source Sans Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 150% */
  }
}

.custom-tooltip-status.show.place-right::before {
  border-right: transparent !important;
}

.custom-tooltip-status.place-right::after {
  border-right-color: transparent !important;
}

.tooltip-permission-legend {
  display: flex;
  padding: 2px 5px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 20px;
  color: var(--color-gray-10, #fff);
  font-family: "Source Sans Pro";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.to-verify-legend-bg-color {
  background: #c3bc05;
}

.accepted-legend-bg-color {
  background: #1dac74;
}

.rejected-legend-bg-color {
  background: #d63864;
}

.custom-download-loader {
  width: 16px;
  height: 16px;
  border: 3px solid $clr_lightblue2;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin-left: 24px;
}

.input-error {
  font-size: 10px;
  color: red;
}

.item_details_scroll {
  height: 65vh !important;
  overflow-y: scroll;
  min-height: 50vh !important;
  max-height: 100vh !important;
}

.table-max-height-65vh {
  max-height: 65vh !important;
}

.active-historical-file-icon {
  color: #1e81e8;
  background-color: #08396c;
  border-radius: 4px;
}

.historical-file-icon:hover {
  color: #1e81e8;
  background-color: #08396c;
  border-radius: 4px;
}

.set-pwd-form {
  li {
    span {
      padding-top: 0;
    }
  }
}

.copy-icon {
  font-size: 14px;
  background-color: transparent;
  vertical-align: bottom;
  border: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
  color: #9aa0a9;
}
.search-icon {
  padding-left: 5px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ced4da;
  border-radius: 0.25rem 0 0 0.25rem;
  color: #606b77;
}

.sso-saml-toggle-switch {
  width: 30px;
  max-height: 15px !important;
}

.copy-icon {
  font-size: 14px;
  background-color: transparent;
  vertical-align: bottom;
  border: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
  color: #9aa0a9;
}
.search-icon {
  padding-left: 5px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ced4da;
  border-radius: 0.25rem 0 0 0.25rem;
  color: #606b77;
}

.sso-saml-toggle-switch {
  width: 30px;
  max-height: 15px !important;
}

.sso-back-link {
  cursor: pointer;
  text-decoration: none;
}
.sso-back-link:hover {
  text-decoration: underline;
}

.custom-svg {
  vertical-align: baseline;
  fill: currentColor; /* This makes the SVG inherit text color */
  transition: fill 0.3s ease; /* Smooth transition effect */
}

.custom-svg:hover {
  color: #1e81e8; /* Change color on hover */
  fill: #1e81e8; /* Ensure the fill color also changes */
}

.exp-switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
}

.exp-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.exp-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1e81e8;
  transition: 0.3s;
  border-radius: 20px;
}

.exp-slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.3s;
  border-radius: 50%;
}

input:checked + .exp-slider {
  background-color: #1e81e8;
}

input:disabled + .exp-slider {
  background-color: #dde0e1;
}

input:checked + .exp-slider:before {
  transform: translateX(16px);
}

.saml-configuration-player {
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
}

@media (min-width: 992px) {
  .saml-configuration-player {
    max-width: 1085px;
    height: 500px;
  }
}
